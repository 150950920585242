import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { appendData, getData, getChildren } from './actions';
import { IInitRimCalcState } from './rim.types';
import { RimExecutionTableState } from '../../../../pages/projects-calculation/index-method-execution';

const defaultFilters: IInitRimCalcState['filters'] = {
  chapter: [],
  code: [],
  header: [],
  lsr: [],
  title: [],
  unit: []
};

const initialState: IInitRimCalcState = {
  list: [],
  limitedRows: [],
  total: [],
  loader: false,
  endFetch: false,
  page: 0,
  hiddenRows: new Set(),
  reset: 1,
  collapseMode: 1,
  filters: defaultFilters,
  executionCollapseRowsIds: new Set(),
  executionTableState: new RimExecutionTableState(),
  executionExpandedHeadersIds: new Set(),
  executionColumnsWidth: {}
};

export const rimSliceCalculation = createSlice({
  name: 'rimSliceCalculation',
  initialState,
  reducers: {
    toggleCollapseMod(state, action: PayloadAction<number>) {
      state.collapseMode = action.payload;
    },
    reset(state) {
      state.reset = state.reset + 1;
      state.page = 0;
      state.loader = false;
      state.endFetch = false;
      state.list = [];
      state.limitedRows = [];
    },
    setHiddenRows(state, action: PayloadAction<Set<number | string>>) {
      state.hiddenRows = action.payload;
    },
    setExecutionCollapseRowsIds(state, action: PayloadAction<Set<number>>) {
      state.executionCollapseRowsIds = action.payload;
    },
    setExecutionRimTableState(
      state,
      action: PayloadAction<RimExecutionTableState>
    ) {
      state.executionTableState = action.payload;
    },
    updateExecutionExpandedHeadersIds(
      state,
      action: PayloadAction<IInitRimCalcState['executionExpandedHeadersIds']>
    ) {
      state.executionExpandedHeadersIds = action.payload;
    },
    updateExecutionColumnsWidth(
      state,
      action: PayloadAction<IInitRimCalcState['executionColumnsWidth']>
    ) {
      state.executionColumnsWidth = action.payload;
    },
    resetRimExecution(state) {
      state.executionTableState = new RimExecutionTableState();
      state.executionCollapseRowsIds = new Set();
      state.executionExpandedHeadersIds = new Set();
      state.executionColumnsWidth = {};
    },
    initList(
      state,
      action: PayloadAction<{
        list: IInitRimCalcState['list'];
        total: IInitRimCalcState['total'];
      }>
    ) {
      state.list = action.payload.list;
      state.page = 0;
      state.total = action.payload.total;
      state.endFetch = false;
    },
    togglePage(state, action: PayloadAction<number | undefined>) {
      state.page =
        action.payload !== undefined ? action.payload : state.page + 1;
    },
    appendRows(state, action: PayloadAction<IInitRimCalcState['list']>) {
      state.list = state.list.concat(action.payload);
    },
    toggleLoader(state, action: PayloadAction<boolean | undefined>) {
      if (action.payload !== undefined) {
        state.loader = action.payload;
      } else {
        state.loader = !state.loader;
      }
    },
    clear(state) {
      state.reset = state.reset + 1;
      state.page = 0;
      state.loader = false;
      state.endFetch = false;
      state.list = [];
      state.filters = defaultFilters;
      localStorage.removeItem('bodyParamsIndex');
    },
    toggleEndFetch(state, action: PayloadAction<boolean | undefined>) {
      state.endFetch =
        action.payload !== undefined ? action.payload : !state.endFetch;
    },
    filterByID(state, action: PayloadAction<Set<number>>) {
      state.list = state.list.filter(
        (el) => !action.payload.has(Number(el.parentID!))
      );
    },
    changeFilters(
      state,
      action: PayloadAction<{
        key: keyof IInitRimCalcState['filters'];
        target: string | string[];
      }>
    ) {
      const { key, target } = action.payload;
      if (typeof target === 'string') {
        state.filters[key] = state.filters[key].includes(target)
          ? state.filters[key].filter((el: string) => el !== target)
          : state.filters[key].concat(target);
      } else if (Array.isArray(target)) {
        state.filters[key] = target;
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.loader = false;
      if (action.payload === null) return;
      state.list = action.payload.data;
      state.total = [action.payload.total];
      state.limitedRows = action.payload.limitedCostsAsRows;
    });
    builder.addCase(getData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getData.rejected, (state) => {
      state.loader = true;
    });
    builder.addCase(appendData.fulfilled, (state, action) => {
      if (action.payload === null) return;
      state.list = state.list.concat(action.payload.data);
    });
    builder.addCase(getChildren.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getChildren.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(getChildren.fulfilled, (state, action) => {
      state.loader = false;
      if (action.payload === null) return;
      const { children, parentID, isDelete, fromIndex } = action.payload;
      state.loader = false;
      const copyList = [...state.list];
      if (isDelete) {
        copyList.splice(fromIndex, children.length);
      } else {
        copyList.splice(fromIndex, 0, ...children);
      }
      state.list = copyList;
      // state.total = [action.payload.total];
    });
  }
});
export const getRimCalcState = (state: RootState) =>
  state.calculations.rim.calc;
export const {
  reset,
  togglePage,
  clear,
  toggleLoader,
  toggleEndFetch,
  setHiddenRows,
  filterByID,
  initList,
  changeFilters,
  setExecutionCollapseRowsIds,
  setExecutionRimTableState,
  updateExecutionExpandedHeadersIds,
  resetRimExecution,
  updateExecutionColumnsWidth
} = rimSliceCalculation.actions;
export default rimSliceCalculation.reducer;
