/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import {
  ColDef,
  ColGroupDef,
  IRowNode,
  NewValueParams
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ProjectLabel } from 'components/ProjectLabel';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { useProjectId } from 'hooks/useProjectId';
import { enqueueSnackbar } from 'notistack';
import React, {
  createContext,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useParams } from 'react-router-dom';
import {
  useEditQuantityMutation,
  useGetExecutionCalculationBimChildrenMutation,
  useGetExecutionCalculationBimMutation
} from '../../../../api/calculations';
import { CalculationLabel } from '../../../../components/CalculationLabel';
import Progress from '../../../../components/Progress';
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers';
import {
  ActList,
  ExecutionCalculationData,
  GetExecutionCalculationData
} from '../../../../types';
import { ActListContext, BimStepper } from '../CalculationСomplicated';
import { PageStyled, WrapperAgGrid } from './Accomplishment.styles';
import { IPricesSwitch } from './Accomplishment.types';
import ActDialog from './components/ActDialog';
import CaptionTable from './components/CaptionTable';
import ExportDialog from './components/ExportDialog';
import { getRowClass, useTable } from './helper';
import { useStepperContext } from '../../../../hooks/useStepper';
import {
  ENUMLocalStorage,
  useLocalStorage
} from '../../../../hooks/use-local-storage';
import { useAppDispatch, useTypedSelector } from '../../../../store/store';
import {
  getAgGridRef,
  setGridRef
} from '../../../../store/slices/ag-grid/ag-grid-slice';
import {
  updateExecutionTableState,
  updateExecutionHiddenRowsIds,
  updateExecutionExpandedHeadersIds
} from '../../../../store/slices/calculations/bim/bim.slice';
import { ExecutionState } from '../CalculationСomplicated/CalculationСomplicated.types';
import { detectLastRowInView } from '../../../../utils/agGrid';
import CircularProgress from '@mui/material/CircularProgress';
import { getBimCalcState } from '../../../../store/slices/calculations/bim/bim.slice';
import { LIMIT } from '../CalculationСomplicated/CalculationСomplicated.constants';

const defaultColDef = { resizable: true };
const priceSwitches: IPricesSwitch[] = [
  { name: 'Базовые', value: 'base' },
  { name: 'Текущие', value: 'curr' }
];

interface TableContext {
  collapse?: (id: number, rowIndex?: number) => void;
  hiddenRowsIds?: number[];
  filteredData?: ExecutionCalculationData[];
  prices?: 'curr' | 'base';
  current?: ActList | null;
  updateData?: UpdateParams;
  total?: ExecutionCalculationData | null;
  calcID?: string;
  emptyCurr?: boolean;
}

type UpdateParams = (
  params: NewValueParams<ExecutionCalculationData, unknown>,
  act: ActList | null,
  calcID?: number | string
) => Promise<void>;

export const AgContext = createContext<TableContext>({});

const Accomplishment = () => {
  const projectID = useProjectId();
  const dispatch = useAppDispatch();
  const gridRef = useTypedSelector(getAgGridRef);
  const { executionExpandedHeadersIds } = useTypedSelector(getBimCalcState);

  const {
    calculation,
    current,
    table,
    isFetching,
    getTable,
    refetchActs,
    data: actResponseData
  } = useContext(ActListContext);

  const d = table
    ? { tree: table.data.tree, total: table.data.total }
    : undefined;

  const { depth, setMaxDepth, maxDepth } = useStepperContext<BimStepper>();
  const firstLoad = useRef(false);
  const [data, setData] = useState<GetExecutionCalculationData | undefined>(
    undefined
  );

  const checkedDepth = useMemo(() => {
    if (depth.executed < 1) return 1;

    return depth.executed > 3 ? 3 : depth.executed;
  }, [depth]);

  const [getChildren, { isLoading: isChildrenLoading }] =
    useGetExecutionCalculationBimChildrenMutation();
  const [getBimExecution] = useGetExecutionCalculationBimMutation();

  const [prices, setPrices] = useState<'curr' | 'base'>('curr');
  const [updateLoader, setUpdateLoader] = useState(false);
  const { calcID } = useParams();
  const numberCalcId = Number(calcID);

  const { executionHiddenRowsIds } = useTypedSelector(getBimCalcState);

  const { setValue } = useLocalStorage(
    ENUMLocalStorage.levelsBaseMethodExecute,
    []
  );

  const Ref = useRef<AgGridReact<ExecutionCalculationData> | null>(null);
  const emptyCurr = useMemo(() => {
    let flag = true;
    /**
     * total.curr не пустой - есть текущие цены
     * parts[любая партия].curr - есть текущие цены
     */

    if (data?.total && data.total?.[0]?.parts) {
      flag = data.total[0].parts.some((part) => {
        let foundTotalKey: keyof typeof part.curr;
        for (foundTotalKey in part.curr) {
          if (
            !Array.isArray(part.curr[foundTotalKey]) &&
            foundTotalKey !== 'hasIndex' &&
            foundTotalKey !== 'hasError'
          ) {
            if (part.curr[foundTotalKey] !== null) {
              return true;
            }
          }
        }
        return false;
      });
      if (!flag) {
        setPrices('base');
      }
    }
    return !flag;
  }, [data?.total, isFetching]);

  const columnsRef: MutableRefObject<
    | (
        | ColDef<ExecutionCalculationData, any>
        | ColGroupDef<ExecutionCalculationData>
      )[]
    | undefined
  > = useTable(data, Ref, emptyCurr, isFetching, undefined, current, [
    executionExpandedHeadersIds
  ]);

  const [createModal, setCreateModal] = useState(false);
  const [exportModal, setExportModal] = useState('');

  const [filteredData, setFilteredData] = useState<
    ExecutionCalculationData[] | undefined
  >(undefined);

  const changePrices = (price: IPricesSwitch) => {
    setPrices(price.value as typeof prices);
  };

  const [update, updateResponse] = useEditQuantityMutation();

  const [editingCell, setEditingCell] = useState(false);

  const onCellEditingStarted = useCallback(() => {
    setEditingCell(true);
  }, []);

  const updateData: UpdateParams = async (params, act, calcID) => {
    setUpdateLoader(true);
    params.api.showLoadingOverlay();
    gridRef?.api.showLoadingOverlay();

    try {
      if (act?.id && calcID) {
        const editedData = {
          actID: act.id,
          calcID: Number(calcID),
          body: {
            rowID: params.data.id,
            quantity:
              params.newValue === undefined ||
              params.newValue === null ||
              params.newValue === '0'
                ? null
                : Number(params.newValue)
          }
        };
        await update(editedData).then(() => {
          refetchActs?.();
        });
        const parentId = params.data.parent_id;
        const rowId = params.data.id;
        if (!parentId) {
          gridRef?.api.hideOverlay();
          return;
        }

        const firstLevelTreeIndex =
          table?.data.tree
            .filter((item) => !item.parent_id)
            ?.findIndex((item) => item.id === parentId) ?? -1;
        const page =
          firstLevelTreeIndex !== -1
            ? Math.floor(firstLevelTreeIndex / LIMIT)
            : 0;

        // get updated row
        const [updatedChildren, updatedTableData] = await Promise.all([
          getChildren({
            calcID: numberCalcId,
            rowID: parentId
          }),
          getBimExecution({
            calcID: numberCalcId,
            limit: LIMIT,
            page,
            depth: 1
          })
        ]);
        setEditingCell(false);

        if (!('data' in updatedChildren)) {
          gridRef?.api.hideOverlay();
          return;
        }
        const updatedRow = updatedChildren.data.children.find(
          (item) => item.id === rowId
        );
        let updatedParentRow = null;
        if ('data' in updatedTableData) {
          updatedParentRow = updatedTableData.data.tree.find(
            (item) => item.id === parentId
          );
        }

        const itemIndex = table?.data.tree.findIndex(
          (item) => item.id === params.data.id
        );
        const parentIndex = table?.data.tree.findIndex(
          (item) => item.id === parentId
        );

        if (table && typeof itemIndex === 'number' && itemIndex !== -1) {
          const updatedTree = [...table.data.tree];
          if (updatedRow)
            updatedTree[itemIndex] = { ...updatedRow, parent_id: parentId };
          if (
            updatedParentRow &&
            typeof parentIndex === 'number' &&
            parentIndex !== -1
          )
            updatedTree[parentIndex] = updatedParentRow;

          dispatch(
            updateExecutionTableState({
              ...table,
              data: {
                ...table.data,
                tree: updatedTree,
                ...('data' in updatedTableData && {
                  total: updatedTableData.data.total
                })
              }
            })
          );
        }
      }
    } catch (error) {
      setEditingCell(false);
      // Обработка ошибки
      console.error('Error:', error);
    }
    return;
  };
  useEffect(() => {
    if (isFetching && d === undefined) columnsRef.current = undefined;
    if (!isFetching) {
      setData(d);
    }
  }, [d?.tree, d?.total, isFetching]);

  const collapse = useCallback(
    async (id: number) => {
      const newSet = new Set(executionHiddenRowsIds);
      const isHidden = executionHiddenRowsIds.includes(id);

      if (!numberCalcId) return;

      if (isHidden) {
        newSet.delete(id);
        const result = await getChildren({ calcID: numberCalcId, rowID: id });
        if (!('data' in result)) return;
        const children = result.data.children.map((child) => {
          newSet.add(child.id);
          return {
            ...child,
            parent_id: id
          };
        });

        // Optimized implementation for large datasets
        let newTree: ExecutionCalculationData[] = [];
        if (table?.data.tree) {
          newTree = new Array(table.data.tree.length + (children?.length || 0));
          let index = 0;

          for (let i = 0; i < table.data.tree.length; i++) {
            const current = table.data.tree[i];
            newTree[index++] = current;

            // Insert children after the parent node
            if (current.id === id && children?.length) {
              for (let j = 0; j < children.length; j++) {
                newTree[index++] = children[j];
              }
            }
          }

          if (index < newTree.length) {
            newTree.length = index;
          }
        }

        if (table)
          dispatch(
            updateExecutionTableState({
              ...table,
              data: { ...table.data, tree: newTree }
            })
          );
      } else {
        newSet.add(id);
        const newTree: ExecutionCalculationData[] = [];
        if (table?.data.tree) {
          const treeLength = table.data.tree.length;
          // Optimized implementation for large datasets
          for (let i = 0; i < treeLength; i++) {
            const item = table.data.tree[i];
            if (item.parent_id !== id && !newSet.has(item.parent_id || 0)) {
              newTree.push(item);
            } else {
              newSet.add(item.id);
            }
          }
        }

        if (table)
          dispatch(
            updateExecutionTableState({
              ...table,
              data: { ...table.data, tree: newTree }
            })
          );
      }
      dispatch(updateExecutionHiddenRowsIds(Array.from(newSet)));
      gridRef?.api.refreshCells({ force: true });
    },
    [data, table, gridRef?.api, numberCalcId]
  );

  useLayoutEffect(() => {
    if (data?.tree) {
      setMaxDepth('executed', 3);
    }
  }, [data?.tree]);

  const handleExecutionExpandedHeadersIds = useCallback(
    (groupId: string) => {
      const newSet = new Set(executionExpandedHeadersIds);
      newSet.has(groupId) ? newSet.delete(groupId) : newSet.add(groupId);
      dispatch(updateExecutionExpandedHeadersIds(newSet));
    },
    [executionExpandedHeadersIds]
  );

  useMutationHandlers(
    updateResponse,
    () => {
      enqueueSnackbar('Данные успешно обновлены', {
        variant: 'success'
      });
    },
    (error) => {
      console.log('<<< ERROR >>>', error);
      enqueueSnackbar('Есть проблемы', {
        variant: 'error'
      });
    }
  );

  useEffect(() => {
    const shouldLoad =
      !table?.isFirstDataFetched || table?.depth !== checkedDepth;
    if (calcID && shouldLoad) {
      dispatch(
        updateExecutionTableState({
          ...new ExecutionState(),
          isFirstDataFetched: !!table?.isFirstDataFetched
        })
      );
      getTable?.(Number(calcID), checkedDepth, 0);
    }
  }, [calcID, depth.executed, actResponseData]);

  useEffect(() => {
    if ((isFetching && gridRef) || updateLoader) {
      gridRef?.api.showLoadingOverlay();
      // setFilteredData([]);
      // oneLoadData.current = false;
    }
  }, [isFetching, gridRef, updateLoader]);

  const contextTable = {
    calcID,
    emptyCurr,
    collapse,
    current,
    updateData,
    filteredData,
    prices,
    total: data?.total?.[0],
    executionHiddenRowsIds,
    executionExpandedHeadersIds,
    handleExecutionExpandedHeadersIds
  };

  useEffect(() => {
    Ref.current?.api?.refreshCells({ force: true });
    Ref.current?.api?.refreshHeader();
  }, [/*contextTable,*/ Ref.current, prices]);

  useEffect(() => {
    if (isFetching) {
      if (!updateLoader) {
        setFilteredData(undefined);
        setData(undefined);
        columnsRef.current = undefined;
      }
    }
  }, [isFetching, updateLoader]);

  useBreadcrumbs(
    [
      { title: <ProjectLabel /> },
      {
        title: 'Расчеты',
        url: `/projects/${projectID}/calculations`
      },
      {
        title: calculation?.title ? (
          <CalculationLabel
            title={calculation?.title}
            type={calculation?.type}
          />
        ) : (
          'Предпросмотр'
        ),
        url: `projects/${projectID}/calculation/${calculation?.id}/edit`
      }
    ],
    [calculation?.title]
  );
  const doesExternalFilterPass = (
    params: IRowNode<ExecutionCalculationData>
  ) => {
    try {
      Ref.current?.api.setIsExternalFilterPresent(() => false);
      return params.data?.parent_id
        ? !executionHiddenRowsIds.includes(params.data.parent_id)
        : true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    if (!firstLoad.current) {
      setValue((prevState) => {
        const isFind = prevState?.find((level) => String(level.id) === calcID);
        if (isFind) {
          return prevState?.map((level) => {
            if (String(level.id) === calcID) {
              return {
                id: Number(calcID),
                levels: executionHiddenRowsIds
              };
            }
            return level;
          });
        } else {
          return [
            ...(prevState ?? []),
            {
              id: Number(calcID),
              levels: executionHiddenRowsIds
            }
          ];
        }
      });
    }
  }, [executionHiddenRowsIds]);

  useEffect(() => {
    Ref.current?.api?.setIsExternalFilterPresent(() => true);
    Ref.current?.api?.onFilterChanged();
  }, [
    doesExternalFilterPass,
    Ref.current,
    location.pathname,
    executionHiddenRowsIds
  ]);

  const updateVisibleRows = useCallback(() => {
    if (gridRef?.api && !table?.endFetched) {
      const isLastRowFullyVisible = detectLastRowInView(gridRef);

      if (isLastRowFullyVisible && table?.data?.tree?.length) {
        const currentPage = table?.page ?? 0;
        dispatch(
          updateExecutionTableState({
            ...table,
            page: table.page + 1,
            isUpdating: true
          })
        );

        setTimeout(() => {
          getTable?.(Number(calcID), depth.executed, currentPage + 1);
        }, 300);
      }
    }
  }, [gridRef, table, table?.endFetched, calcID, getTable]);

  return (
    <PageStyled>
      <AgContext.Provider value={contextTable}>
        <CaptionTable
          disableCurr={emptyCurr}
          act={() => setCreateModal(true)}
          exportKS={(str: string) => setExportModal(str)}
          prices={prices}
          changePrices={changePrices}
          priceSwitches={priceSwitches}
        />

        {!!table?.data?.tree.length &&
          (table?.isUpdating || (isChildrenLoading && !editingCell)) && (
            <CircularProgress
              size={50}
              sx={{
                zIndex: 100,
                position: 'absolute',
                top: '50%',
                left: '50%',
                translate: '-50% -50%'
              }}
            />
          )}
        <WrapperAgGrid className="ag-theme-material reference-prices">
          <AgGridReact
            ref={Ref}
            onGridReady={(e) => {
              dispatch(setGridRef(e));
            }}
            onViewportChanged={updateVisibleRows}
            onBodyScroll={updateVisibleRows}
            context={contextTable}
            enableCellTextSelection={true}
            ensureDomOrder={true}
            maintainColumnOrder={true}
            columnDefs={columnsRef.current}
            defaultColDef={defaultColDef}
            groupHeaderHeight={25}
            singleClickEdit
            gridOptions={{
              onCellEditingStarted,
              // suppressDragLeaveHidesColumns: true,
              navigateToNextHeader: () => null,
              tabToNextHeader: () => null
            }}
            pinnedTopRowData={data?.total}
            rowData={data?.tree}
            suppressCellFocus={true}
            onFirstDataRendered={(event) => {
              event.api.sizeColumnsToFit();
            }}
            // onGridSizeChanged={(event: GridSizeChangedEvent) => {
            //   event.api.sizeColumnsToFit();
            // }}
            // onViewportChanged={(event) => {
            //   event.api.sizeColumnsToFit();
            // }}
            getRowId={(params) => {
              return params.data.id.toString();
            }}
            getRowClass={getRowClass}
            getRowHeight={(params) => {
              if (params.node.rowPinned === 'top') {
                return 50;
              }
              return 55;
            }}
            rowStyle={{
              padding: '0 !important'
            }}
            rowHeight={55}
            headerHeight={36}
            doesExternalFilterPass={doesExternalFilterPass}
            loadingOverlayComponent={Progress}
            noRowsOverlayComponent={Progress}></AgGridReact>
        </WrapperAgGrid>
        <ActDialog
          data={actResponseData}
          update={(f) => {
            columnsRef.current = undefined;
            //gridRef?.api.showLoadingOverlay();
            //setUpdateLoader(f);
            if (f) {
              dispatch(updateExecutionHiddenRowsIds([]));
              dispatch(
                updateExecutionTableState({
                  ...new ExecutionState(),
                  isFirstDataFetched: !!table?.isFirstDataFetched
                })
              );
              firstLoad.current = true;
            }
            // setFilteredData(null);
          }}
          close={() => setCreateModal(false)}
          open={createModal}
        />
        <ExportDialog close={() => setExportModal('')} open={exportModal} />
      </AgContext.Provider>
    </PageStyled>
  );
};

export default Accomplishment;
