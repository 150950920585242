import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box } from '@mui/material';
import { ClearableTextField } from 'components/ClearableTextField';
import styled from 'styled-components';
import { BoxProps } from '@mui/material/Box/Box';

export const StyledTabComponent = styled('div')`
  height: 100%;
`;

export const StyledItem = styled(Box)<BoxProps>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 115px 115px 115px 115px 115px 115px 30px;
  gap: 15px 0;
  align-items: center;
  justify-content: space-around;
  padding-top: 15px;
`;

export const LengthArr = styled.span`
  padding: 0 15px 0 0;
  position: absolute;
  left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledIconArrow = styled(KeyboardArrowRightIcon)`
  position: absolute;
  left: 0;
  transition: all 0.2s;
  cursor: pointer;
`;

export const StyledClearableTextField = styled(ClearableTextField)`
  margin-left: 15px;
  div > input::placeholder {
    text-align: center;
  }
`;
