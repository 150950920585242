/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import styled from 'styled-components';

export const PageStyled = styled.div<{ $withMargin?: boolean }>`
  width: 100%;
  margin-top: ${({ $withMargin = true }) => ($withMargin ? '10' : '0')}px;
  background-color: #fff;
  border-radius: 20px;
  overflow-y: auto;
  overflow-x: auto;
  display: grid;
  height: 100%;
  grid-template-rows: min-content 1fr;
`;

export const WrapperAgGrid = styled.div`
  //--ag-selected-row-background-color: rgba(144, 243, 144, 0.66);
  --ag-cell-horizontal-border: 1px solid rgb(210, 220, 255);
  --ag-row-hover-color: #d1d8fa !important;
  --ag-header-column-separator-color: black !important;
  --ag-input-focus-border-color: #e2e2e2 !important;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .ag-pinned-left-header,
  .ag-pinned-left-floating-top,
  .ag-pinned-left-cols-container {
    border-right: 3px solid rgb(153, 153, 159) !important;

    .ag-cell:last-child {
      border: none;
    }
  }
  .loader-row {
    overflow: hidden;
    &:before {
      content: '';
      z-index: 10;
      position: absolute;
      left: 0;
      right: 0;
      height: 10px;
      top: -10px;
      box-shadow: 0 0 20px 10px #f3f2f2;
      background-color: #f3f2f2;
      animation-delay: 1s;
      animation-timing-function: ease-in;
      animation-duration: 1s;
      animation-name: loader-row-animation;
      animation-iteration-count: infinite;
    }
    &:after {
      content: '';
      position: absolute;
      background-color: white;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
  @keyframes loader-row-animation {
    from {
      top: -100px;
    }
    to {
      top: 100px;
    }
  }
  //border-top: 4px solid rgba(25, 118, 210, 0.08);

  .inactive-cell {
    opacity: 0.5 !important;
  }

  .deleted-cell {
    opacity: 0.5 !important;
  }

  .dont_belong-cell {
    color: #ff0101 !important;
  }

  .ag-sticky-label {
    position: sticky;
    left: 10px;
    right: 10px;
  }

  .ag-cell.cell-hover:hover {
    border-bottom: 1px solid #0044b4 !important;
  }

  .cell-hover:hover {
    border-bottom: 1px solid #0044b4 !important;
    cursor: pointer;
  }

  .level-1 {
    background: #87b4ff;
  }

  .level-2 {
    background: rgba(135, 180, 255, 0.93);
  }

  .level-3 {
    background: rgba(135, 180, 255, 0.86);
  }

  .level-4 {
    background: rgba(135, 180, 255, 0.79);
  }

  .level-5 {
    background: rgba(135, 180, 255, 0.72);
  }

  .level-6 {
    background: rgba(135, 180, 255, 0.65);
  }

  .level-7 {
    background: rgba(135, 180, 255, 0.58);
  }

  .level-8 {
    background: rgba(135, 180, 255, 0.51);
  }

  .level-9 {
    background: rgba(135, 180, 255, 0.44);
  }

  .level-10 {
    background: rgba(135, 180, 255, 0.37);
  }

  .level-11 {
    background: rgba(135, 180, 255, 0.3);
  }

  .level-12 {
    background: rgba(135, 180, 255, 0.23);
  }

  .level-13 {
    background: rgba(135, 180, 255, 0.16);
  }

  .level-14 {
    background: rgba(135, 180, 255, 0.09);
  }

  //.level-10 {
  //    background: rgba(135, 180, 255, 0);
  //}
  //
  //.level-11 {
  //    background: rgba(135, 180, 255, 0);
  //}

  .rate-position {
    background: #fff;
  }

  .rate {
    background: rgba(77, 102, 134, 0.1) !important;
  }

  .ag-header-cell-text {
    text-wrap: auto;
  }

  .ag-header-cell,
  .ag-header-group-text {
    font-weight: 500 !important;
  }

  .ag-cell:has(.hiddenRightBorder) {
    border-right: transparent;

    svg {
      fill: #668fd2;
    }
  }

  .ag-cell-wrapper {
    .MuiBox-root {
      height: 100% !important;
    }
  }

  .ag-cell-inline-editing {
    padding: 0;
  }

  .ag-cell.ag-cell-normal-height.ag-cell-inline-editing {
    background: transparent;
    border: none;
    width: 100%;
    height: 100%;
    box-shadow: none;
    //padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .ag-cell-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .remove-padding {
    padding: 0;

    .ag-cell-value {
      height: 100% !important;
    }
  }

  .ag-cell-value {
    display: flex;
    height: 100%;
    line-height: 100%;
    align-items: center;
    justify-content: stretch;
    //align-self: stretch;
  }

  .ag-cell .border-left {
    border-left-color: black !important;
  }

  .ag-cell.ag-cell-normal-height.ag-cell-inline-editing {
    box-shadow: none !important;
  }

  .ag-cell.ag-cell-inline-editing:has(.ag-has-focus) {
    background: black !important;
  }

  .border-left-price-ref {
    border-left: 1px solid #e2e2e2 !important;
  }

  .border-right-price-ref {
    border-right: 1px solid #e2e2e2 !important;
  }

  .grouped-unit-resource {
    padding: 0 !important;

    span {
      height: 100%;
    }
  }

  .mute {
    opacity: 0.4;
  }

  //.uuid {
  //  outline-width: 2px !important;
  //  outline-color: transparent !important;
  //  outline-offset: -2px !important;
  //  outline-style: solid !important;
  //  transition: outline-color linear 3s;
  //}
  //.ag-row.uuid-on {
  //  outline-color: rgba(79, 227, 79, 0.6) !important;
  //}
  //.ag-row.uuid-off {
  //  outline-color: transparent !important;
  //}
`;
export const BadgeAct = styled.div<{ color: string }>`
  line-height: 21px;
  padding: 1.5px 6px;
  color: white;
  border-radius: 4px;
  background: ${({ color }) => color};
`;
