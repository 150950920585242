import { ParametersDialogExecution } from './execution';
import { ParametersDialog } from './calculation';
import { TParametersDialogProps } from './calculation/parameters-dialog.types';
import React from 'react';
import { ActList, ActResponse, CalcData } from '../../../../../types';

export const CombineComponent: React.FC<
  TParametersDialogProps & {
    isExecution: boolean;
    actList?: ActResponse;
    currentAct: ActList | null;
    changeCurrentAct: (act: ActList | null) => void;
    refetchActs: () => void;
    calculation?: CalcData | null;
    updateFragment: () => void;
  }
> = ({ isExecution, ...props }) => {
  const {
    changeCurrentAct,
    currentAct,
    actList,
    refetchActs,
    calculation,
    ...rest
  } = props;
  if (isExecution) {
    return <ParametersDialogExecution {...props} />;
  }
  return <ParametersDialog {...rest} />;
};
