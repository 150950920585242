import { Box, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { FC, useMemo, useRef } from 'react';
import { CalculationFormData } from '../../CalculationDrawerForm.types';
import { StyledButton, StyledFileItem } from './FileList.styles';
import { FileListProps } from './FileList.types';
import { VirtualizedList } from '../../../../../../components/VirtualizedList';
import { DEFAULT_ITEM_HEIGHT, FILES_MAX_HEIGHT } from './FileList.constants';

export const FileList: FC<FileListProps> = ({ data, field }): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<CalculationFormData>();
  const filesBlockRef = useRef<HTMLDivElement>(null);

  const listData = useMemo(
    () => data.map((file) => ({ field, file })),
    [data, field]
  );

  const filesBlockHeight = filesBlockRef.current?.clientHeight || 0;
  const filesBlockMinHeight = useMemo(() => {
    const length = listData.length;
    if (!length) return 0;
    if (length > 2) return FILES_MAX_HEIGHT;

    return DEFAULT_ITEM_HEIGHT;
  }, [listData.length]);

  const setAll = () => {
    setFieldValue(
      field,
      data.map((file) => file.id.toString())
    );
  };

  const clearAllClickHandler = () => {
    setFieldValue(field, []);
  };

  return (
    <Box height="100%" display="grid" gridTemplateRows="repeat(2, auto) 1fr">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
        px={0.5}>
        <Typography variant="body2">Выберите файлы</Typography>
        <Typography variant="body2">
          Выбрано ({values?.[field]?.length})
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={1}>
        <StyledButton variant="text" size="small" onClick={setAll}>
          Выбрать все
        </StyledButton>
        <StyledButton
          variant="text"
          size="small"
          onClick={clearAllClickHandler}>
          Очистить
        </StyledButton>
      </Stack>
      <Box
        minHeight={`${filesBlockMinHeight}px`}
        height="100%"
        ref={filesBlockRef}>
        <VirtualizedList items={listData} Component={StyledFileItem} />
      </Box>
    </Box>
  );
};
