/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { IHeaderGroupParams } from 'ag-grid-community';
import { useCallback, useEffect, useState } from 'react';
import { Title, Wrapper } from './CustomTableHeader.style';

const CustomTableHeader = (props: IHeaderGroupParams) => {
  // const expandState = useRef<'expanded' | 'collapsed'>(
  //   props.columnGroup.getProvidedColumnGroup().isExpanded()
  //     ? 'expanded'
  //     : 'collapsed'
  // );
  const [expandState, setExpandState] = useState<'expanded' | 'collapsed'>(
    'collapsed'
  );
  const groupId = props.columnGroup.getGroupId();
  const { executionExpandedHeadersIds, handleExecutionExpandedHeadersIds } =
    props.context;
  const isOpen = executionExpandedHeadersIds
    ? executionExpandedHeadersIds.has(groupId)
    : expandState === 'expanded';

  const expandOrCollapse = useCallback(() => {
    if (!executionExpandedHeadersIds) {
      const currentState = props.columnGroup
        .getProvidedColumnGroup()
        .isExpanded();
      props.setExpanded(!currentState);
      return;
    }

    handleExecutionExpandedHeadersIds(groupId);
  }, [props, groupId, executionExpandedHeadersIds]);

  const syncExpandButtons = useCallback(() => {
    // expandState.current = props.columnGroup
    //   .getProvidedColumnGroup()
    //   .isExpanded()
    //   ? 'expanded'
    //   : 'collapsed';
    if (!executionExpandedHeadersIds) {
      setExpandState(
        props.columnGroup.getProvidedColumnGroup().isExpanded()
          ? 'expanded'
          : 'collapsed'
      );
      return;
    }

    props.setExpanded(executionExpandedHeadersIds?.has(groupId));
  }, [groupId, props, executionExpandedHeadersIds]);

  useEffect(() => {
    props.columnGroup
      .getProvidedColumnGroup()
      .addEventListener('expandedChanged', syncExpandButtons);
    syncExpandButtons();
    return () => {
      props.columnGroup
        .getProvidedColumnGroup()
        .removeEventListener('expandedChanged', syncExpandButtons);
    };
  }, [props.columnGroup, syncExpandButtons, executionExpandedHeadersIds]);
  return (
    <Wrapper onClick={expandOrCollapse} isOpen={isOpen}>
      <Title isOpen={isOpen}>{props.displayName}</Title>
      <ExpandCircleDownIcon />
    </Wrapper>
  );
};

export default CustomTableHeader;
