import React from 'react';
import { TParametersTabs } from '../parameters-dialog.execution.types';
import { ActTab, Table } from './components';
import { ActList } from '../../../../../../../types';

export const Content: React.FC<{
  tab: TParametersTabs;
  setCurrentAct: (act: ActList | null) => void;
}> = ({ tab, setCurrentAct }) => {
  switch (tab) {
    case 'acts':
      return <ActTab setCurrentAct={setCurrentAct} />;
    case 'limited_costs':
      // return <Table estimates={} />;
      return <Table />;
    default:
      return null;
  }
};
