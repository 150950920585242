/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import { Box, Dialog } from '@mui/material';
import { useCreateActMutation } from 'api/calculations';
import { useCalcId } from 'hooks/useCalcId';
import { enqueueSnackbar } from 'notistack';
import {
  CreateActForm,
  inputs
} from 'pages/Calculations/components/Accomplishment/components/ActDialog/ActDialog.types';
import CreateActInput from 'pages/Calculations/components/Accomplishment/components/ActDialog/CreateActInput';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ActList, ActResponse } from 'types';
import { toLocalString } from 'utils/formatDate';
import Button from '../../../../../../components/Button';
import { isEmpty } from '../../../../../../utils/isEmpty';
import { ActListContext } from '../../../CalculationСomplicated';
import {
  BtnWrapper,
  Close,
  HeadAct,
  WarningText,
  WrapperDialog
} from './ActDialog.style';
import { TDisableDate, validateDates } from './utils';

const ActDialog: React.FC<{
  data: ActResponse | undefined;
  open: boolean;
  close: () => void;
  update: (flag: boolean) => void;
  setCurrentOuter?: (act: ActList | null) => void;
}> = ({ open, update, close, data,setCurrentOuter }) => {
  const { setCurrent } = useContext(ActListContext);
  const calcID = useCalcId();

  const [validateFields, setValidateFields] = useState({
    startDate: '',
    endDate: '',
    onDate: ''
  });

  const [create, { isLoading }] = useCreateActMutation();

  const { ...methods } = useForm<CreateActForm>({
    mode: 'all'
  });

  /**
   * Хранит массив дат которые нельзя будет выбрать в селекторе
   */
  const disabledDates: TDisableDate[] = useMemo(() => {
    if (!data) return [];
    // return [];
    return (data?.actList || []).reduce((disabled, dateRange) => {
      const { startDate, endDate } = dateRange;
      disabled.push({
        start: new Date(startDate || ''),
        end: new Date(endDate || '')
      });
      return disabled;
    }, [] as TDisableDate[]);
  }, [data]);

  const { handleSubmit, formState } = methods;
  const submit: SubmitHandler<CreateActForm> = useCallback(
    (data) => {
      const body = {
        startDate: toLocalString(data.startDate!),
        endDate: toLocalString(data.endDate!),
        onDate: toLocalString(data.onDate!)
      };
      // console.log(disabledDates);
      const errorText = validateDates(data, disabledDates);

      if (isEmpty(errorText, ['start', 'end', 'on', 'split'], false)) {
        enqueueSnackbar(
          <Box gap={1} display={'flex'} flexDirection={'column'}>
            {Object.values(errorText)}
          </Box>,
          {
            variant: 'error',
            autoHideDuration: 3000
          }
        );
        return;
      }
      create({ body, calcID }).then((resp) => {
        methods.reset({
          onDate: null,
          startDate: null,
          endDate: null
        });
        setValidateFields({
          onDate: '',
          startDate: '',
          endDate: ''
        });
        update(true);
        if ('data' in resp && resp.data) {
          setCurrent?.(resp.data);
          setCurrentOuter?.(resp.data)
        }
        close();
      });
    },
    [calcID, disabledDates]
  );
  const validate = useMemo(() => {
    let flag = true;
    let validateFieldsKey: keyof typeof validateFields;
    // console.log(validateFields);
    for (validateFieldsKey in validateFields) {
      // console.log(validateFieldsKey);
      if (
        validateFieldsKey in validateFields &&
        !/^\d{2}\.\d{2}\.\d{4}$/.test(validateFields[validateFieldsKey])
      ) {
        flag = false;
        break;
      }
    }
    return !flag || !formState.isValid;
  }, [validateFields, formState.isValid]);

  useEffect(() => {
    methods.reset({
      onDate: null,
      startDate: null,
      endDate: null
    });
    setValidateFields({
      onDate: '',
      startDate: '',
      endDate: ''
    });
  }, [open]);

  return (
    <Dialog open={open}>
      <WrapperDialog onSubmit={handleSubmit(submit)}>
        <FormProvider {...methods}>
          <HeadAct>
            Сформировать новый акт
            <Close onClick={close} />
          </HeadAct>

          <WarningText>
            Внимание!
            <br />
            Период не может совпадать с существующим периодом или затрагивать
            диапазон дат уже существующих периодов
          </WarningText>
          {inputs.map(({ id, ...props }) => (
            <CreateActInput
              disabledDates={disabledDates}
              setValidate={(key: keyof typeof validateFields, str: string) => {
                setValidateFields((prevState) => ({
                  ...prevState,
                  [key]: str
                }));
              }}
              key={id}
              {...props}
            />
          ))}

          <BtnWrapper>
            <Button
              disabled={validate || isLoading}
              color={'success'}
              type={'submit'}>
              Сохранить
            </Button>
            <Button disabled={isLoading} color={'primary'} onClick={close}>
              Отменить
            </Button>
          </BtnWrapper>
        </FormProvider>
      </WrapperDialog>
    </Dialog>
  );
};

export default ActDialog;
