import { Checkbox, Chip, Stack } from '@mui/material';
import { FileTypeChip } from 'pages/Calculations/components/FileTypeChip';
import styled from 'styled-components';

export const AddCalculationListItem = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  padding: '8px 12px',
  border: `1px solid ${theme.palette.bg.gray}`,
  borderRadius: '8px',

  '& .MuiIconButton-root': {
    marginLeft: 'auto',
    marginRight: '-4px'
  }
}));

export const StyledListItemChip = styled(FileTypeChip)(({ theme }) => ({
  color: 'white',
  lineHeight: '24px',
  fontSize: '12px',
  fontWeight: 500,
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.spacing(0.5),
  textTransform: 'uppercase',
  cursor: 'unset'
}));

export const StyledListItemCounter = styled(Chip)(({ theme }) => ({
  width: '24px',
  marginLeft: 'auto',
  color: theme.palette.primary.main,
  lineHeight: '24px',
  fontSize: '12px',
  fontWeight: 500,
  backgroundColor: '#1976D214',
  borderRadius: '50%',
  textAlign: 'center',
  cursor: 'unset'
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  color: '#2B36481F'
}));
