import { Buffer } from 'buffer';

export function downloadBase64File(data: string, filename: string) {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(new Blob([Buffer.from(data, 'base64')]));
  a.download = filename;

  a.click();
  a.remove();
}
