/**
 * @author Mr_FazbiozZz[mr.fabiozzz@gmail.com]
 */
import { ReactComponent as RowIcon } from '../../../../../../assets/icons/LeveLicon/RowIcon.svg';
import { RowType } from '../../../../../../api/references/estimates/estimates.types';
import { BadgeLevel } from '../Level/Level';
import React from 'react';
import {
  SvgExecutedRate,
  SvgExecutedRatePosition
} from '../../../../../../assets/SVG/SVG';

export const LevelIcon: React.FC<{
  levelName: number | null;
  type?: RowType;
}> = ({ levelName, type }) => {
  if (type === 'RATE')
    return (
      <SvgExecutedRate style={{ fill: '#5291DA', width: 17, height: 18 }} />
    );

  switch (levelName) {
    case 1:
      // return <FirstLevelicon style={{ width: 19, height: 15 }} />;
      return <BadgeLevel>1</BadgeLevel>;
    case 2:
      // return <SecondLevelicon style={{ width: 19, height: 15 }} />;
      return <BadgeLevel>2</BadgeLevel>;
    case 3:
      // return <ThirdLevelicon style={{ width: 19, height: 15 }} />;
      return <BadgeLevel>3</BadgeLevel>;
    case 4:
      return (
        <SvgExecutedRate style={{ fill: '#5291DA', width: 17, height: 18 }} />
      );
    default:
      return (
        <SvgExecutedRatePosition
          style={{
            width: 16,
            height: 17,
            fill: '#B78BAC'
          }}
        />
      );
  }
};

export default LevelIcon;
