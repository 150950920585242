import Item from './components/Item/Item';
import { StyledTabComponent } from './TabComponent.styles';
import { TabComponentProps } from './TabComponent.types';
import { VirtualizedList } from 'components/VirtualizedList';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ItemProps } from './components/Item/Item.types';

/**
 * @param fields Массив объектов вида ToTotals или Indexes.
 * @param itemType
 */

const TabComponent: React.FC<TabComponentProps> = ({ fields, itemType }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [openItemsIds, setOpenItemsIds] = useState<Set<string>>(new Set());

  const handleItemCollapse = useCallback(
    (id: string) => {
      const newSet = new Set(openItemsIds);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      setOpenItemsIds(newSet);
    },
    [openItemsIds]
  );

  const listData: ItemProps[] = useMemo(
    () =>
      fields.map((field, index) => {
        const id = `${index} - ${field.fileID}`;

        return {
          field,
          parentIndex: index,
          itemType: itemType,
          open: openItemsIds.has(id),
          onCollapse: () => handleItemCollapse(id)
        };
      }),
    [fields, itemType, openItemsIds]
  );

  return (
    <StyledTabComponent ref={wrapperRef}>
      {/*{fields.map((field, index) => (
        <Item
          key={index}
          field={field}
          parentIndex={index}
          itemType={itemType}
        />
      ))}*/}

      <VirtualizedList items={listData} Component={Item} />
    </StyledTabComponent>
  );
};

export default TabComponent;
