import {
  Pagination,
  PaginationProps,
  PaginationRenderItemParams
} from '@mui/material';
import PaginationItem from '@mui/material/PaginationItem';
import React from 'react';
import Tooltip from '../Tooltip';

const buttonsKeys: Record<PaginationRenderItemParams['type'], string> = {
  first: 'Свернуть все уровни',
  last: 'Развернуть все уровни',
  next: 'Развернуть один уровень',
  previous: 'Свернуть один уровень',
  page: '',
  'end-ellipsis': '',
  'start-ellipsis': ''
};

const Stepper: React.FC<PaginationProps & { name?: string, totalBlock?:boolean }> = (props) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  const { page: propsPage, sx, name,totalBlock, ...restProps } = props;

  const page = React.useMemo(() => {
    if (propsPage! < 1) return 1;
    if (propsPage! > props.count!) return props.count!;
    return propsPage;
  }, [props]);

  return (
    <Pagination
      ref={ref}
      count={10}
      siblingCount={0}
      boundaryCount={0}
      showFirstButton
      showLastButton
      hidePrevButton={false}
      hideNextButton={false}
      renderItem={(item) => {
        const tooltipTitle = buttonsKeys[item.type];
        const { disabled: itemDisabled, ...rest } = item;
        const disabled = totalBlock?true:
          item.type === 'previous' || item.type === 'next'
            ? itemDisabled
            : false;
        return (
          <Tooltip title={tooltipTitle}>
            <PaginationItem
              {...rest}
              disabled={disabled}
              page={name ?? rest.page}
            />
          </Tooltip>
        );
      }}
      sx={{
        '& .MuiPaginationItem-ellipsis': {
          display: 'none'
        },
        '& .MuiPaginationItem-page:not(.Mui-selected)': {
          display: 'none'
        },
        '& .Mui-selected': {
          background: 'transparent !important'
        },
        ...(sx || {})
      }}
      {...restProps}
      page={page}
    />
  );
};

export default Stepper;
