import { ICellEditorParams } from 'ag-grid-community';
import { PRStyledBaseTextField } from 'components/BaseTextField/BaseTextField.styles';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import { Autocomplete } from '@mui/material';
import { LimitedLocal } from 'types';
import { PopperComponent } from 'pages/Administration/AdminReferences/Prices/useTableData';
import { NumericFormat } from 'react-number-format';
import { Option, RenderOptions, replaceNum } from './table.helper';
import { compensationIndexes, translator, typeLimit } from './table.constants';
import { DialogForm } from '../parameters-dialog.types';

const ListboxProps = {};

const sx = {
  '.MuiAutocomplete-inputRoot': {
    paddingRight: '28px !important'
  },
  '.MuiAutocomplete-endAdornment': {}
};
// const slotProps = {
//   paper: {
//     sx: {
//       width,
//         '.MuiAutocomplete-listbox': {
//         paddingTop: '0 !important'
//       }
//     }
//   }
// }
function CustomListboxComponent(props: any) {
  const ref = React.useRef<any>();

  // Отключаем scrollIntoView для всех элементов списка
  React.useEffect(() => {
    if (ref.current) {
      const listbox = ref.current;
      const observer = new MutationObserver(() => {
        if (listbox) {
          const items = listbox.querySelectorAll('li');
          items.forEach((item: any) => {
            item.scrollIntoView = () => {}; // Переопределяем поведение
          });
        }
      });
      observer.observe(listbox, { childList: true, subtree: true });

      return () => observer.disconnect();
    }
  }, []);

  return <ul {...props} ref={ref} />;
}

export const TableCellEditor = React.memo(
  React.forwardRef<any, ICellEditorParams<LimitedLocal>>((props, ref) => {
    const listboxRef = React.useRef<HTMLUListElement | null>(null);
    const [inputError, setInputError] = React.useState('');
    const [selected, setSelected] = React.useState<any[]>(
      Array.isArray(props.value) && props.colDef?.field !== 'type'
        ? props.value
        : []
    );
    const refPopper = React.useRef<HTMLDivElement | null>(null);

    const { setFieldValue, getFieldProps, errors } =
      useFormikContext<DialogForm>();

    const [inputProps] = useField(
      `rows.${props.node.rowIndex || 0}.${props.colDef.field}`
    );

    React.useEffect(() => {
      if (props.data?.position === 'position') {
        const target = getFieldProps<LimitedLocal>(
          `rows.${props.node.rowIndex || 0}`
        )?.value;
        if (target && target.chapter && props.node.rowIndex) {
          const calculateIndex =
            props.node.rowIndex - compensationIndexes[target.chapter];
          if (calculateIndex >= 0 && errors.rows?.[calculateIndex]) {
            const errorState = errors.rows?.[calculateIndex] as any;
            switch (props.colDef?.field) {
              case 'values.building':
                setInputError(errorState?.values?.building || '');
                break;
              case 'values.mounting':
                setInputError(errorState?.values?.mounting || '');
                break;
              case 'values.other':
                setInputError(errorState?.values?.other || '');
                break;
              case 'values.equipment':
                setInputError(errorState?.values?.equipment || '');
                break;
              case 'values.total':
                setInputError(errorState?.values?.total || '');
                break;
              default:
                setInputError(errorState[props.colDef?.field!] || '');
                break;
            }
          } else {
            setInputError('');
          }
        }
      } else {
        setInputError('');
      }
    }, [
      inputProps,
      props.data?.position,
      props.node?.rowIndex,
      props.data?.type,
      getFieldProps,
      errors,
      props.colDef?.field
    ]);

    const filesList: Option[] = React.useMemo(() => {
      if (props.context?.estimates) {
        return [{ id: -100, label: 'Все сметы' }, ...props.context.estimates];
      }
      return [];
    }, [props.context?.estimates]);

    const limitList: Option[] = React.useMemo(() => {
      const list: any[] = [];
      let flag = 'empty';
      props.api.forEachNode((el) => {
        if (el.data?.uuid === props.data.uuid) {
          flag = 'stop';
        }
        if (
          (Number(el.data?.id) > 0 || el.data?.id === undefined) &&
          flag === 'empty' &&
          el.data?.title
        ) {
          list.push({ id: el.data?.uuid, label: el.data?.title });
        }
      });
      if (list.length) {
        return [{ id: -100, label: 'Выбрать все' }, ...list];
      }
      return list;
    }, [props.api, props.data.uuid]);

    const options: Option[] = React.useMemo(() => {
      switch (props.colDef.field) {
        case 'dependencies':
          return limitList;
        case 'estimates':
          return filesList;
        case 'type':
          return typeLimit as Option[];
        default:
          return [];
      }
    }, [filesList, limitList, props.colDef.field]);

    const handleToggleOption = React.useCallback(
      (option: { id: number | string; label: string }) => {
        // (async () => {
        const filteredOptions = (options || []).filter((el) => el.id !== -100);
        if (option.id === -100) {
          if (selected.length === filteredOptions.length) {
            setSelected([]);
            setFieldValue(
              `rows.${props.node.rowIndex || 0}.${props.colDef.field}`,
              [],
              true
            );
            return;
          } else {
            setSelected(filteredOptions);
            setFieldValue(
              `rows.${props.node.rowIndex || 0}.${props.colDef.field}`,
              filteredOptions,
              true
            );
            return;
          }
        }
        const currentValue = selected || [];
        const isSelected = currentValue.some(
          (item: any) => item.id === option.id
        );

        const newValue = isSelected
          ? currentValue.filter((item: any) => item.id !== option.id)
          : [...currentValue, option];

        setSelected(newValue);
        // await setFieldValue(
        //   `rows.${props.node.rowIndex || 0}.${props.colDef.field}`,
        //   newValue
        // );
        // })();
      },
      [options, selected]
    );

    React.useImperativeHandle(
      ref,
      () => ({
        getValue: () => {
          return inputProps.value;
        },
        isCancelAfterEnd() {
          if (props.colDef?.field?.startsWith('values')) {
            const condition = Number(inputProps.value?.replace(',', '.')) === 0;
            const value = replaceNum(inputProps.value);
            if (condition && !value) {
              setFieldValue(
                `rows.${props.node.rowIndex || 0}.${props.colDef.field}`,
                ''
              );
            } else {
              setFieldValue(
                `rows.${props.node.rowIndex || 0}.${props.colDef.field}`,
                value
              );
            }
          }
          if (Array.isArray(props.value) && props.colDef?.field !== 'type') {
            setFieldValue(
              `rows.${props.node.rowIndex || 0}.${props.colDef.field}`,
              selected
            );
          }
          return true;
        }
      }),
      [
        inputProps,
        props.colDef.field,
        props.node.rowIndex,
        setFieldValue,
        selected
      ]
    );
    // const render = React.useCallback(renderOption, []);
    React.useEffect(() => {
      props?.eGridCell?.getElementsByTagName('input')[0]?.click();
      props?.eGridCell?.getElementsByTagName('input')[0]?.focus();
      if (
        props.colDef?.field?.startsWith('values') ||
        props.colDef?.field === 'title'
      ) {
        props?.eGridCell?.getElementsByTagName('input')[0]?.select();
      }
    }, [props.colDef.field, props?.eGridCell]);

    switch (props.colDef.field) {
      case 'estimates':
      case 'type':
      case 'dependencies': {
        const { onChange, ...restProps } = inputProps;
        const selectedLabels =
          props.colDef?.field === 'type'
            ? translator?.[inputProps.value as keyof typeof translator]?.ru ||
              ''
            : (selected || []).map((item: any) => item.label).join('; ');
        const width =
          props.colDef?.field === 'dependencies'
            ? '355px'
            : props.colDef?.field === 'estimates'
              ? '453px'
              : 'inherit';
        return (
          <Autocomplete
            {...restProps}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id && option.label === value.label;
            }}
            options={options!}
            multiple={props.colDef?.field !== 'type'}
            filterOptions={(x) => x}
            filterSelectedOptions={false}
            inputValue={selectedLabels}
            clearIcon={null}
            disableClearable
            onBlur={() => {
              props.stopEditing(true);
            }}
            sx={sx}
            slotProps={{
              paper: {
                sx: {
                  width,
                  '.MuiAutocomplete-listbox': {
                    paddingTop: '0 !important'
                  }
                }
              }
            }}
            value={selected}
            renderInput={(params) => {
              return (
                <PRStyledBaseTextField
                  sx={{ 'caret-color': 'transparent' }}
                  {...params}
                  placeholder="&mdash;"
                  value={selectedLabels}
                />
              );
            }}
            freeSolo={false}
            renderTags={() => ''}
            onClose={() => {
              props.stopEditing(true);
            }}
            ListboxComponent={CustomListboxComponent}
            ListboxProps={{
              sx: {
                maxHeight: '452px'
              }
            }}
            disableCloseOnSelect={!(props.colDef?.field === 'type')}
            renderOption={(optionProps, option, state, ownerState) => {
              return (
                <RenderOptions
                  option={option}
                  ownerState={ownerState}
                  options={options!}
                  optionProps={optionProps}
                  props={props}
                  state={state}
                  toggle={handleToggleOption}
                />
              );
            }}
            autoFocus
            selectOnFocus={false}
            openOnFocus
            inputMode="none"
            fullWidth
          />
        );
      }
      case 'title': {
        return (
          <PRStyledBaseTextField
            {...inputProps}
            autoComplete="false"
            onBlur={(ev) => {
              inputProps.onBlur(ev);
              props.stopEditing(true);
            }}
          />
        );
      }
      default: {
        return (
          <React.Fragment>
            <NumericFormat
              {...inputProps}
              autoComplete="false"
              $textAlign="right"
              getInputRef={(ref: any) => {
                refPopper.current = ref;
              }}
              aria-autocomplete={'none'}
              customInput={PRStyledBaseTextField}
              decimalSeparator=","
              thousandSeparator=" "
              onBlur={(ev) => {
                inputProps.onBlur(ev);
                props.stopEditing(true);
              }}
            />
            {inputError && inputError !== 'Обязательное поле' && (
              <PopperComponent
                message={inputError}
                refItem={refPopper.current}
              />
            )}
          </React.Fragment>
        );
      }
    }
  })
);
TableCellEditor.displayName = 'TableCellEditor';
