import { List, ListItemButton, listItemButtonClasses } from '@mui/material';
import styled from 'styled-components';

export const StyledForm = styled.form`
  display: flex;
  height: 100%;
  overflow: auto;
`;

export const StyledMenuList = styled(List)(({ theme }) => ({
  flexShrink: 0,
  paddingBlock: theme.spacing(3.75),
  borderRight: `1px solid ${theme.palette.legends.divider}`,
  position: 'sticky',
  top: 0
}));

export const StyledMenuItem = styled(ListItemButton)(({ theme }) => ({
  padding: `${theme.spacing(1.5)} ${theme.spacing(2.5)}`,

  [`&.${listItemButtonClasses.selected}`]: {
    color: theme.palette.primary.main
  }
}));

export const StyledContentWrapper = styled.div`
  height: 100%;
`;
