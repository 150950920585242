import styled from 'styled-components';
import { Box, Checkbox } from '@mui/material';

export const StyledTableContainer = styled(Box)`
  border-radius: 8px;
  display: flex;
  height: 100%;
  overflow: hidden;
  background: #fff;
`;

interface StyledTableHeadProps {
  showCodeLSR?: boolean;
  showHeader?: boolean;
  showBaseEquipmentPrice?: boolean;
  showCurrEquipmentPrice?: boolean;
  showCurrPriceTable?: boolean;
  countBaseDynamicsColumns?: number;
  countCurrDynamicsColumns?: number;
  areasBaseDynamicsColumns?: string[];
  areasCurrDynamicsColumns?: string[];
}

export const StyledTotal = styled(Box)<StyledTableHeadProps>`
  display: grid;
  overflow: hidden;
  grid-auto-flow: column;
  grid-auto-rows: 52px;
  grid-template-rows: 52px;
  gap: 0px 0px;

  grid-template-columns:
    40px 60px 52px
    ${(props) => props.showCodeLSR && '116px'}
    minmax(164px, 1fr)
    ${(props) => props.showHeader && '112px'} 234px minmax(400px, 1fr) 112px 105px
    ${(props) => props.showBaseEquipmentPrice && '206px'}
    206px 243px 177px 231px 125px 334px 104px 218px 104px 189px
    ${(props) => (props.countBaseDynamicsColumns! > 0 ? '189px' : '')}
    ${(props) =>
      props.countBaseDynamicsColumns === 0
        ? ''
        : `repeat(${props.countBaseDynamicsColumns}, 189px)`} 193px
    ${(props) =>
      props.showCurrPriceTable &&
      `196px ${props.showCurrEquipmentPrice ? '218px' : ''}
          260px 190px 248px 125px 334px 187px 203px
          ${props.countCurrDynamicsColumns! > 0 ? '189px' : ''}
          ${props.countCurrDynamicsColumns === 0 ? '' : `repeat(${props.countCurrDynamicsColumns}, 189px)`}
          215px `}
    13px;

  grid-template-areas: 'icon icon title title
    ${(props) => props.showHeader && 'title'} ${(props) =>
    props.showCodeLSR && 'title'} title title unit count
    ${(props) => props.showBaseEquipmentPrice && 'basePrice'}
    ${(props) =>
    props.countBaseDynamicsColumns! > 0 ? 'basePrice' : ''} basePrice
   basePrice basePrice basePrice basePrice basePrice basePrice basePrice basePrice basePrice basePrice
   ${(props) => props.areasBaseDynamicsColumns?.map((item) => item + ' ')}
   ${(props) =>
    props.showCurrPriceTable &&
    `сurrentPrice
    ${props.showCurrEquipmentPrice ? 'сurrentPrice' : ''}
    сurrentPrice сurrentPrice сurrentPrice сurrentPrice
    сurrentPrice сurrentPrice сurrentPrice сurrentPrice
    ${props.countCurrDynamicsColumns! > 0 ? 'сurrentPrice' : ''}`}
    ${(props) => props.areasCurrDynamicsColumns?.map((item) => item + ' ')}
   scroll';
  width: 100%;
  height: 100%;
  max-height: 52px;

  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #5c6e8c;
  line-height: 20px;
  border-bottom: 2px solid #7aa7ed;
`;

export const StyledTotalTd = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 8px;
  background-color: #fff;
  box-shadow: inset -1px 0 0 rgba(0, 36, 95, 0.1);
  &:hover {
    z-index: 1;
  }
`;

export const StyledTableHead = styled(Box)<StyledTableHeadProps>`
  display: grid;
  overflow: hidden;
  grid-auto-flow: column;
  grid-auto-rows: 105px;
  grid-template-rows: 105px;
  gap: 0px 0px;

  grid-template-columns:
    40px 60px 52px
    ${(props) => props.showCodeLSR && '116px'}
    minmax(164px, 1fr)
    ${(props) => props.showHeader && '112px'} 234px minmax(400px, 1fr) 112px 105px
    ${(props) => props.showBaseEquipmentPrice && '206px'}
    206px 243px 177px 231px 125px 334px 104px 218px 104px 189px
    ${(props) => (props.countBaseDynamicsColumns! > 0 ? '189px' : '')}
    ${(props) =>
      props.countBaseDynamicsColumns === 0
        ? ''
        : `repeat(${props.countBaseDynamicsColumns}, 189px)`} 193px
    ${(props) =>
      props.showCurrPriceTable &&
      `196px ${props.showCurrEquipmentPrice ? '218px' : ''}
        260px 190px 248px 125px 334px 187px 203px
        ${props.countCurrDynamicsColumns! > 0 ? '189px' : ''}
        ${props.countCurrDynamicsColumns === 0 ? '' : `repeat(${props.countCurrDynamicsColumns}, 189px)`}
        215px`} 13px;

  grid-template-areas: 'icon icon title title
   ${(props) => props.showHeader && 'title'} ${(props) =>
    props.showCodeLSR && 'title'} title title title title
   ${(props) => props.showBaseEquipmentPrice && 'basePrice'}
   ${(props) =>
    props.countBaseDynamicsColumns! > 0 ? 'basePrice' : ''} basePrice
  basePrice basePrice basePrice basePrice basePrice basePrice basePrice basePrice basePrice basePrice
  ${(props) => props.areasBaseDynamicsColumns?.map((item) => item + ' ')}
  ${(props) =>
    props.showCurrPriceTable &&
    `сurrentPrice
    ${props.showCurrEquipmentPrice ? 'сurrentPrice' : ''}
    сurrentPrice сurrentPrice сurrentPrice сurrentPrice
    сurrentPrice сurrentPrice сurrentPrice сurrentPrice
    ${props.countCurrDynamicsColumns! > 0 ? 'сurrentPrice' : ''}`}
    ${(props) => props.areasCurrDynamicsColumns?.map((item) => item + ' ')}
  scroll';
  width: 100%;
  height: 100%;
  max-height: 105px;
  background-color: #7890b2;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 0.15px;
  color: #ffffff;
`;

export const StyledHeadTd = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 8px;
  box-shadow: inset -1px 0 0 rgba(0, 36, 95, 0.1);
`;

export const StyledHeadFixedTd = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 8px;
  background-color: #7890b2;
  box-shadow: inset -1px 0 0 rgba(0, 36, 95, 0.1);
`;

export const StyledTableBody = styled(Box)`
  display: grid;
  overflow: auto;
  height: 100%;
  grid-template-rows: 64px;

  &::-webkit-scrollbar {
    box-shadow: inset 1px 0px 0px rgba(0, 36, 95, 0.1);
  }
`;
export const StyledCheckbox = styled(Checkbox)`
  &:not(.Mui-checked) .MuiSvgIcon-root {
    color: rgba(154, 162, 176, 0.5);
  }
`;
