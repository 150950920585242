import { Popper } from '@mui/material';
import styled from 'styled-components';

export const StyledPopper = styled(Popper)`
  color: white;
  background: #f46b6b;
  padding: 16px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
`;
