import { ReadyState } from 'react-use-websocket';
import { CalcData, CalcDataType, LSRFileStatus } from 'types';

export interface ProviderWSProps {
  children: React.ReactNode;
}

export interface WSContextProps {
  /**
   * @description Статус сокета - реальный
   */
  readyState: ReadyState;
  /**
   * @description Состояние авторизации сокета
   */
  isAuth: boolean;
  /**
   * @description Состояние открЫтИЯ сокета
   */
  isOpen: boolean;
  /**
   * @description Статус сокета - понятный
   */
  connectionStatus: ConnectionStatusType;
  /**
   * @description Пришел ли положительный ответ на загрузку по типу
   */
  getIsSuccess: (wsType: WSManeType) => boolean | undefined;
  /**
   * @description Пришел ли отрицательный ответ на загрузку по типу
   */
  getIsError: (wsType: WSManeType) => boolean | undefined;
  /**
   * @description Происходит ли сейчас загрузка по типу
   */
  getIsProgress: (wsType: WSManeType) => boolean | undefined;
  /**
   * @description Возвращает текущий процент загрузки по типу
   */
  getPercent: (wsType: WSManeType) => number | undefined;
  sendSubscribeAuth: (token: string) => void;
}

export interface WSContextLSRProps {
  uploadFileLsr: (props: WSRequestBodyLSRUpload) => void;
  uploadFileLsrProgress: WSResponseBodyLSRUpload<WSTaskStatus.IN_PROGRESS> | null;
  uploadFileLsrStatus: WSResponseBodyLSRUpload<WSTaskStatus.OK> | null;
  uploadFileLsrError: WSResponseBodyLSRUpload<WSTaskStatus.ERROR>;
  uploadFileLsrClick: boolean;
  uploadFileLsrCanceled: boolean;
  clearUploadFileLsrProgress: () => void;
  clearUploadFileLsrStatus: () => void;
  clearUploadFileLsrCanceled: () => void;
  clearUploadFileLsrError: () => void;
  clearAll: () => void;
}

export interface WSContextHandbookProps {
  uploadFileHandbook: (props: WSRequestBodyHandbkUpload) => void;
  uploadFileHandbookProgress: WSResponseBodyHandbkUpload<WSTaskStatus.IN_PROGRESS> | null;
  uploadFileHandbookStatus: WSResponseBodyHandbkUpload<WSTaskStatus.OK> | null;
  uploadFileHandbookError: WSResponseBodyHandbkUpload<WSTaskStatus.ERROR>;
  uploadFileHandbookCanceled: boolean;
  uploadFileHandbookClick: boolean;
  clearUploadFileHandbookProgress: () => void;
  clearUploadFileHandbookStatus: () => void;
  clearUploadFileHandbookCanceled: () => void;
  clearUploadFileHandbookError: () => void;
  clearAll: () => void;
}

export interface WSContextCalcProps {
  createCalc: (props: WSRequestBodyLSRCreateCalc) => void;
  createCalcProgress: WSResponseBodyLSRCreateCalc<WSTaskStatus.IN_PROGRESS> | null;
  createCalcStatus: WSResponseBodyLSRCreateCalc<WSTaskStatus.OK> | null;
  createCalcError: boolean;
  createCalcClick: boolean;
  createCalcCanceled: boolean;
  clearCalcProgress: () => void;
  clearCalcStatus: () => void;
  clearCalcCanceled: () => void;
  clearCalcError: () => void;
  clearAll: () => void;
}

export interface WSHandbookCopyProps {
  copyCalc: (props: WSRequestBodyHandbookCopy) => void;
  copyCalcProgress: WSResponseBodyHandbookCopyUpload<WSTaskStatus.IN_PROGRESS> | null;
  copyCalcStatus: WSResponseBodyHandbookCopyUpload<WSTaskStatus.OK> | null;
  copyCalcError: WSResponseBodyHandbookCopyUpload<WSTaskStatus.ERROR>;
  copyCalcClick: boolean;
  copyCalcCanceled: boolean;
  clearCalcProgress: () => void;
  clearCalcStatus: () => void;
  clearCalcCanceled: () => void;
  clearCalcError: () => void;
  clearAll: () => void;
}

export type WSManeType = 'lsr' | 'handbook' | 'calc' | 'handbookCopy';

export enum WSEventType {
  auth = 'AUTH',

  lsr_upload = 'LSR_UPLOAD',
  lsr_create_calc = 'LSR_CREATE_CALC',
  handbk_upload = 'HANDBK_UPLOAD',

  copy_calc = 'COPY_CALC',

  cancel_upload = 'CANCEL_UPLOAD',
  uknown = 'UNKNOWN'
}

export type ConnectionStatusType =
  | 'Connecting'
  | 'Open'
  | 'Closing'
  | 'Closed'
  | 'Uninstantiated';

export interface WSResponse<E extends WSEventType, S extends WSTaskStatus> {
  event: E;
  status: S;
  body: WSResponseBody<E, S>;
}

export type WSResponseBody<
  E extends WSEventType,
  S extends WSTaskStatus
> = E extends WSEventType.auth
  ? WSResponseBodyAuth<S>
  : E extends WSEventType.cancel_upload
    ? WSResponseBodyCancelUpload<S>
    : E extends WSEventType.handbk_upload
      ? WSResponseBodyHandbkUpload<S>
      : E extends WSEventType.lsr_create_calc
        ? WSResponseBodyLSRCreateCalc<S>
        : E extends WSEventType.lsr_upload
          ? WSResponseBodyLSRUpload<S>
          : never;

export type WSResponseBodyAuth<S extends WSTaskStatus> =
  S extends WSTaskStatus.OK
    ? null
    : S extends WSTaskStatus.IN_PROGRESS
      ? null
      : S extends WSTaskStatus.CANCELING
        ? null
        : S extends WSTaskStatus.CANCELED
          ? null
          : S extends WSTaskStatus.ERROR
            ? null
            : null;

export type WSResponseBodyCancelUpload<S extends WSTaskStatus> =
  S extends WSTaskStatus.OK
    ? null
    : S extends WSTaskStatus.IN_PROGRESS
      ? null
      : S extends WSTaskStatus.CANCELING
        ? null
        : S extends WSTaskStatus.CANCELED
          ? null
          : S extends WSTaskStatus.ERROR
            ? null
            : null;

export type WSResponseBodyHandbkUpload<S extends WSTaskStatus> =
  S extends WSTaskStatus.OK
    ? WSMessageHandbkResult
    : S extends WSTaskStatus.IN_PROGRESS
      ? WSMessageTaskProgress
      : S extends WSTaskStatus.CANCELING
        ? null
        : S extends WSTaskStatus.CANCELED
          ? null
          : S extends WSTaskStatus.ERROR
            ? null | WSMessageHandbkResult
            : null;

export type WSResponseBodyLSRCreateCalc<S extends WSTaskStatus> =
  S extends WSTaskStatus.OK
    ? CalcData
    : S extends WSTaskStatus.IN_PROGRESS
      ? WSMessageTaskProgress
      : S extends WSTaskStatus.CANCELING
        ? null
        : S extends WSTaskStatus.CANCELED
          ? null
          : S extends WSTaskStatus.ERROR
            ? null
            : null;

export type WSResponseBodyLSRUpload<S extends WSTaskStatus> =
  S extends WSTaskStatus.OK
    ? WSMessageLSRUploadResult
    : S extends WSTaskStatus.IN_PROGRESS
      ? WSMessageTaskProgress
      : S extends WSTaskStatus.CANCELING
        ? null
        : S extends WSTaskStatus.CANCELED
          ? null
          : S extends WSTaskStatus.ERROR
            ? null | WSMessageLSRUploadResult
            : null;
export type WSResponseBodyHandbookCopyUpload<S extends WSTaskStatus> =
  S extends WSTaskStatus.OK
    ? WSMessageHandbookCopyResult
    : S extends WSTaskStatus.IN_PROGRESS
      ? WSMessageTaskProgress
      : S extends WSTaskStatus.CANCELING
        ? null
        : S extends WSTaskStatus.CANCELED
          ? null
          : S extends WSTaskStatus.ERROR
            ? null
            : null;

export enum WSTaskStatus {
  OK = 'OK',
  IN_PROGRESS = 'IN_PROGRESS',
  CANCELING = 'CANCELING',
  CANCELED = 'CANCELED',
  ERROR = 'ERROR'
}

export interface WSMessageTaskProgress {
  remaining: string;
  percent: number;
  completed: number;
  total: number;
}

export interface WSMessageHandbkResult {
  success: boolean;
  errors: { address: string; msg: string }[];
  errorRows: any;
}

export interface WSMessageLSRUploadResult {
  totalUploaded: number | null;
  withErrors: {
    name: string;
    success: boolean;
    msg: null | string;
  }[];
  uploaded: {
    name: string;
    success: boolean;
    msg: null | string;
  }[];
}

export interface WSMessageHandbookCopyResult {
  copied: number | null;
  failed: { projectID: number; msg: string }[];
}

export interface WSRequest<E extends WSEventType> {
  event: E;
  body: WSRequestBody<E>;
}

export type WSRequestBody<E extends WSEventType> = E extends WSEventType.auth
  ? WSRequestBodyAuth
  : E extends WSEventType.cancel_upload
    ? WSRequestBodyCancelUpload
    : E extends WSEventType.handbk_upload
      ? WSRequestBodyHandbkUpload
      : E extends WSEventType.lsr_create_calc
        ? WSRequestBodyLSRCreateCalc
        : E extends WSEventType.lsr_upload
          ? WSRequestBodyLSRUpload
          : E extends WSEventType.copy_calc
            ? WSRequestBodyHandbookCopy
            : never;

export type WSRequestBodyAuth = { token: string };

export type WSRequestBodyCancelUpload = {
  projectID: number;
  taskType: WSEventType;
};

export type WSRequestBodyHandbkUpload = {
  file: { b64Content: string; name: string };
};

export type WSRequestBodyHandbookCopy = {
  calcID: number;
  title: string;
  description: string;
  projectIDs: number[];
};
export type WSRequestBodyLSRCreateCalc = {
  projectID: number;
  body: {
    title: string;
    description: string;
    type: CalcDataType;
    lsrIds: number[];
  };
};

export type WSRequestBodyLSRUpload = {
  projectID: number;
  files: WSFile[];
  status?: LSRFileStatus;
};

export type WSFile = {
  b64Content: string;
  name: string;
};
