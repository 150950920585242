import styled from 'styled-components';
import { Box, FormControlLabel, Modal, Stack, Typography } from '@mui/material';

export const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  padding: 30px;
`;

export const StyledContainer = styled(Box)`
  //min-width: 1700px;
  max-width: 1860px;
  width: 100%;
  min-width: 1030px;

  // min-height: 710px;
  // max-height: 910px;
  //height: 100%;
  overflow: hidden;
  overflow: auto;
  background: #ffffff;
  box-shadow: -8px 0px 8px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  &:focus-visible {
    outline: none;
  }
`;

export const StyledHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 20px;
  border-bottom: 1px solid #d1d8fa;
  //width: 1860px;
  //min-width: 1700px;
  max-width: 1860px;
  //*/
  //width: 100%;
`;

export const StyledTableContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 2px solid #d2dcff;
  border-radius: 10px;
  overflow: auto;
`;

export const StyledTableHead = styled(Box)<{ $isHandbook: boolean }>`
  display: grid;
  align-items: center;
  align-content: center;
  grid-auto-flow: column;
  grid-auto-rows: 30px;
  grid-template-rows: 30px;
  gap: 0px 0px;

  grid-template-columns: ${({ $isHandbook }) => {
    return $isHandbook
      ? '60px 80px 150px 150px 150px 300px minmax(158px, 1fr) 100px 100px 110px 100px 132px 40px'
      : '60px 80px 150px 150px 150px 100px 200px minmax(158px, 1fr) 100px 100px 110px 100px 132px 40px';
  }};
  //grid-template-columns:
  //  60px 80px 150px 150px 150px 100px 200px minmax(158px, 1fr)
  //  100px 100px 110px 100px 132px 40px;

  width: 100%;
  min-width: fit-content;
  height: 100%;
  max-height: 30px;

  backgroundr: #ffffff;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  letter-spacing: 0.15px;
  color: #00358c;

  //background: #f0f3fc;

  // border-bottom: 1px solid #d2dcff;
  box-shadow:
    -3px 1px 4px rgba(109, 154, 220, 0.5),
    0px 5px 6px -3px rgba(74, 104, 176, 0.08),
    0px 9px 12px 1px rgba(109, 154, 220, 0.08),
    0px 3px 16px 2px rgba(109, 154, 220, 0.08);
`;

export const StyledHeadTd = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 8px;
  line-height: 30px;

  border-right: 0.5px solid #d2dcff;
  border-bottom: 1px solid #d2dcff;

  &:last-child {
    //border-right: none;
    // border-bottom: none;
  }
`;

export const StyledTableBody = styled(Box)`
  //display: grid;
  height: 548px;
  width: 100%;
  min-width: fit-content;
  &::-webkit-scrollbar {
    box-shadow: inset 1px 0px 0px rgba(0, 36, 95, 0.1);
  }
`;

export const StyledTitle = styled(Box)`
  display: flex;
  justify-content: space-between;
  //width: 100%;
  background: rgba(210, 220, 255, 0.1);
  border-bottom: 2px solid #d2dcff;
  padding: 20px 50px 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #7890b2;
`;

export const StyledLevel = styled(Stack)<{ $isHandbook: boolean }>`
  position: relative;
  align-items: center;
  background: #ffffff;
  height: 44px;
  width: 100%;
  max-width: 100%;

  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1fr;
  grid-template-rows: 44px;
  gap: 0px 0px;

  grid-template-columns: ${({ $isHandbook }) => {
    return $isHandbook
      ? '60px 80px 150px 150px 150px 300px minmax(158px, 345.78px) 100px 100px 110px 100px 132px 40px'
      : '60px 80px 150px 150px 150px 100px 200px minmax(158px, 345.78px) 100px 100px 110px 100px 132px 40px';
  }};
  //grid-template-columns:
  //  60px 80px 150px 150px 150px 100px 200px minmax(158px, 1fr)
  //  100px 100px 110px 100px 132px 40px;

  //border-right: 0.5px solid #d2dcff;
`;
export const StyledLevelTd = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0px 8px;
  border-bottom: 0.5px solid #d2dcff;
  //box-shadow: inset -1px -1px 0px rgba(0, 36, 95, 0.1);
  //border-right: 1px solid black;
`;

export const StyledTypography = styled(Typography)`
  line-height: 18px;
  text-align: center;
  color: ${(props) => props.theme.palette.text.dark};

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  hyphens: auto;
  word-wrap: break-word;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  border: 1px solid rgba(25, 118, 210, 0.04);
  border-radius: 8px;
  margin: 0;
  padding: 10px 8px 10px 13px;

  border: 1px solid
    ${(props) =>
      props.checked === true ? props.theme.palette.primary.light : '#EDEFF3'};
  background: ${(props) =>
    props.checked === true ? 'rgba(25, 118, 210, 0.04)' : '#FFFFFF'};

  & .MuiTypography-root {
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: #2b3648;
  }

  & .MuiCheckbox-root,
  .MuiRadio-root {
    padding: 0;
    margin-right: 13px;
  }
`;
