import { CircularProgress, Modal, Stack, Typography } from '@mui/material';

import React, { FC } from 'react';
import { StyledPopper } from './DeleteProcessModal.styles';

interface DeleteProcessModalProps {
  open: boolean;
  onClose?: () => void;
}

export const DeleteProcessModal: FC<DeleteProcessModalProps> = ({ open }) => {
  return (
    <Modal open={open}>
      <StyledPopper>
        <Stack gap={'12px'}>
          <Typography variant="subtitle1" align="center" lineHeight={'150%'}>
            Идет удаление расценок
          </Typography>
          <Typography
            mb={0.8}
            align="center"
            fontSize={'14px'}
            lineHeight={'140%'}>
            Ожидайте
          </Typography>
          <Stack direction="row" gap={1} alignItems="center">
            <CircularProgress sx={{ margin: '0 auto' }} />
          </Stack>
        </Stack>
      </StyledPopper>
    </Modal>
  );
};
