import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { api } from '../api/api';
import authReducer from '../store/slices/auth';
import agGridReducer from './slices/ag-grid/ag-grid-slice';
import calculationDictionaryReducer from './slices/calculationDictionary/calculationDictionary';
import bimSliceCalculationReducer from './slices/calculations/bim/bim.slice';
import rimSliceCalculationReducer from './slices/calculations/rim/rim.slice';
import pricesReducer from './slices/references/prices/prices';
import vorReducer from './slices/vor/vor';

const references = combineReducers({
  prices: pricesReducer
});
const bim = combineReducers({
  calc: bimSliceCalculationReducer
});
const rim = combineReducers({
  calc: rimSliceCalculationReducer
});
const calculations = combineReducers({
  bim,
  rim
});

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    vor: vorReducer,
    references,
    calculations,
    calcDictionary: calculationDictionaryReducer,
    AgGrid: agGridReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
  devTools: {
    maxAge: 50
  }
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
