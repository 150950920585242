import { NewValueParams } from 'ag-grid-community';
import {
  ExecutionCalculationData,
  GetExecutionCalculationRimData
} from '../../../types';
import { GetExecutionCalculationParams } from '../../../api/calculations/types';

export type UpdateParams = (
  params: NewValueParams<
    ExecutionCalculationData & { parentID: number | null },
    unknown
  >,
  act: number | null,
  calcID?: number | string
) => Promise<void>;

export interface Stats {
  title?: string;
  color?: string;
}

export class RimExecutionTableState {
  data: GetExecutionCalculationRimData = {
    total: [],
    data: [],
    dynamicRows: []
  };
  page = 0;
  endFetched = false;
  isFirstDataFetched = false;
  isUpdating = false;
  depth = 1;
  originalArgs?: GetExecutionCalculationParams;

  constructor(
    prev?: RimExecutionTableState,
    newData?: GetExecutionCalculationRimData,
    originalArgs?: GetExecutionCalculationParams
  ) {
    if (!prev && !newData) return;

    this.page = prev?.page || 0;
    this.depth = prev?.depth || 1;
    const total = prev?.data?.total?.length
      ? prev?.data.total
      : newData?.total || [];

    const dynamicRows = prev?.data?.dynamicRows?.length
      ? prev?.data.dynamicRows
      : newData?.dynamicRows || [];

    const data = prev?.data?.data?.length
      ? [...(prev?.data?.data || []), ...(newData?.data || [])]
      : newData?.data || [];
    this.data = {
      total,
      data,
      dynamicRows
    };

    this.endFetched = !newData?.data?.length;

    if (newData) this.isFirstDataFetched = true;
    this.originalArgs = originalArgs;
  }
}
