import { createSlice } from '@reduxjs/toolkit';
import { AgGridReact } from 'ag-grid-react';
import { RootState } from 'store/store';

interface IInitialState {
  gridRef: AgGridReact<any> | null;
}
const initialState: IInitialState = {
  gridRef: null
};
export const agGridSlice = createSlice({
  name: 'agGridSlice',
  initialState,
  reducers: {
    setGridRef(state, action) {
      state.gridRef = action.payload;
    }
  }
});
export const getAgGridRef = (state: RootState) => state.AgGrid.gridRef;
export const { setGridRef } = agGridSlice.actions;
export default agGridSlice.reducer;
