import React, { memo } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import {
  AddCalculationListItem,
  StyledCheckbox,
  StyledListItemChip,
  StyledListItemCounter
} from './CalculationListItem.styles';
import Tooltip from 'components/Tooltip';
import { CalculationListItemProps } from './CalculationListItem.types';
import { fileTypeNames } from 'pages/Calculations/components/FileTypeChip/FileTypeChip.service';
import { FileTypeChip } from 'pages/Calculations/components/FileTypeChip';

export const CalculationListItem: React.FC<CalculationListItemProps> = memo(
  ({
    checkbox,
    id,
    label,
    text,
    type,
    index,
    checked,
    onCheck,
    count,
    onDelete,
    isExisted,
    isDisabled
  }) => {
    const theme = useTheme();

    const handleSelect = () => {
      if (!checkbox) return;

      onCheck && onCheck(id);
    };

    return (
      <AddCalculationListItem
        onClick={handleSelect}
        sx={!isExisted ? { cursor: 'pointer' } : {}}
        bgcolor={isExisted ? 'white' : '#F6F7FB'}>
        {checkbox && (
          <StyledCheckbox tabIndex={-1} checked={checked} size={'small'} />
        )}
        {type && <FileTypeChip type={type} />}
        <Box sx={{ minWidth: 0 }}>
          <Tooltip title={label}>
            <Typography
              variant="body2"
              color={theme.palette.text.dark}
              lineHeight="120%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={'nowrap'}
              mb="4px">
              {label}
            </Typography>
          </Tooltip>
          <Typography variant="body2" lineHeight="120%">
            {text}
          </Typography>
        </Box>
        {count && <StyledListItemCounter label={count} size="small" />}
        {onDelete && (
          <IconButton
            size="small"
            onClick={() => onDelete(index)}
            disabled={isDisabled || false}>
            <DeleteIcon
              fontSize="small"
              sx={{ color: theme.palette.secondary.gray }}
            />
          </IconButton>
        )}
      </AddCalculationListItem>
    );
  }
);

CalculationListItem.displayName = 'CalculationListItem';
