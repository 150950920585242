import { VirtualizedListProps } from './VirtulizedList.types';
import { LogLevel, Virtuoso } from 'react-virtuoso';

export const VirtualizedList = <T extends object>({
  items,
  Component,
  height
}: VirtualizedListProps<T>) => {
  return (
    <Virtuoso
      style={{ height: height || '100%', width: '100%' }}
      totalCount={items.length}
      data={items}
      itemContent={(_index, data) => {
        return <Component {...data} key={_index} />;
      }}
    />
  );
};
