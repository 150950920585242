/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 900px;
  padding: 30px;
  background: #fff;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 40px 43px 40px minmax(0, 1fr) 40px;
  gap: 12px;
  min-height: 600px;
  height: 600px;
  max-width: calc(100vw - 64px);
  max-height: calc(100vh - 64px);
  min-width: 300px;
  //max-height: 100%;
  overflow: hidden;
  resize: both;
`;
export const Title = styled.div`
  color: #0044b4;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  //line-height: 18px;
  padding-left: 14px;
  gap: 5px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const ListItem = styled.label`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.17000000178813934px;
  text-align: left;
  cursor: pointer;
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: start;
  grid-template-rows: min-content;
`;
export const Text = styled.div`
  padding-top: 9px;
  display: flex;
  flex-direction: column;
`;
export const SmallText = styled(Text)`
  display: inline;
  padding: 0;
  font-size: 10px;
  color: ${({ theme }) => theme.palette.grey['500']};
`;
export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  /* max-height: 320px; */
  overflow-y: auto;
  overflow-x: hidden;
`;

export const EmptyText = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.17000000178813934px;
  text-align: center;
`;
