import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, Menu, MenuItem } from '@mui/material';
import {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  RowClassParams
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ProjectLabel } from 'components/ProjectLabel';
import { useSnackbar } from 'notistack';
import React, {
  createContext,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDeleteCalculationMutation } from '../../../../api/calculations';
import { useGetTanglTableQuery } from '../../../../api/tangl';
import { ITangleTableResponse } from '../../../../api/tangl/tangl.types';
import Button from '../../../../components/Button';
import { CalculationLabel } from '../../../../components/CalculationLabel';
import Progress from '../../../../components/Progress';
import Stepper from '../../../../components/Stepper';
import useBreadcrumbs from '../../../../hooks/useBreadcrumbs';
import { useCalcId } from '../../../../hooks/useCalcId';
import useConfirmDialog from '../../../../hooks/useConfirmDialog';
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers';
import { useProjectId } from '../../../../hooks/useProjectId';
import { CalcData, EPragmaModules } from '../../../../types';
import { deleteData, openDB } from '../../../../utils/indexDB';
import { NoRows } from '../../../Administration/AdminReferences/Prices/useTableData';
import {
  BadgeAct,
  PageStyled,
  WrapperAgGrid
} from '../Accomplishment/Accomplishment.styles';
import Cell from '../Accomplishment/components/Cell';
import Level from './components/Level';

/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
interface ITanglTable {
  calculation?: CalcData;
  openEdit: () => void;
  copyDrawer: boolean;
  toggleOpenCopy: (f?: boolean) => void;
}

interface ITangleTableContext {
  collapse?: (id: number) => void;
  hiddenRowsIds?: number[];
}

export const TangleTableContext = createContext<ITangleTableContext>({});

const TanglTable: React.FC<ITanglTable> = ({
  calculation,
  openEdit,
  copyDrawer,
  toggleOpenCopy
}) => {
  const maxDepth = useRef(1);

  const [currentDepth, setCurrentDepth] = useState(1);

  const firstLoad = useRef(true);

  const changeDepth = useCallback((depth: number) => {
    setCurrentDepth(depth);
  }, []);

  const projectID = useProjectId();
  const calcId = useCalcId();
  const { data, isFetching } = useGetTanglTableQuery(calcId, { skip: !calcId });
  const Ref = useRef<any>(null);
  const { t } = useTranslation('user');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [deleteCalculation, deleteCalculationResponse] =
    useDeleteCalculationMutation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [hiddenRowsIds, setHiddenRowsIds] = useState<number[]>([]);
  const [filteredData, setFilteredData] = useState<
    ITangleTableResponse[] | undefined
  >(undefined);

  const handleBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const handleConfirmDelete = useCallback((confirm: boolean) => {
    if (confirm) {
      deleteCalculation({ projectID, calcID: calculation?.id || 0 }).then(
        () => {
          openDB().then((db) => deleteData(db, calculation!.id));
        }
      );
    }
  }, []);
  const handleDeleteCalculationClick = useCallback(
    (actionName: string) => {
      openConfirmDelete(actionName);
    },
    [calculation?.id]
  );

  const {
    ConfirmDialog: ConfirmDiaologDelete,
    openConfirm: openConfirmDelete
  } = useConfirmDialog({
    title: 'Вы уверены?',
    body: 'Расчёт будет удалён и восстановить его будет невозможно',
    handleConfirm: handleConfirmDelete
  });

  const MenuOpen = Boolean(anchorEl);

  const columnsDef: (
    | ColDef<ITangleTableResponse, any>
    | ColGroupDef<ITangleTableResponse>
  )[] = useMemo(() => {
    return [
      {
        field: 'lvl',
        headerName: 'Уровень',
        width: 120,
        cellRenderer: (
          params: ICellRendererParams<ITangleTableResponse, any, any>
        ) => {
          return <Level {...params} />;
        }
      },
      {
        field: 'catalog',
        headerName: 'Справочник',
        width: 140,
        cellRenderer: (params: any) => {
          return (
            <Cell
              // color={
              //   params.data.lvl === 1 || params.data.lvl === 0
              //     ? '#0044B4'
              //     : undefined
              // }
              // weight={params.data.lvl === 1 || params.data.lvl === 0}
              float={'center'}>
              {params.value}
            </Cell>
          );
        }
      },
      {
        field: 'code',
        headerName: 'Номер позиции',
        width: 140,

        cellRenderer: (params: any) => {
          return (
            <Cell
              // color={
              //   params.data.lvl === 1 || params.data.lvl === 0
              //     ? '#0044B4'
              //     : undefined
              // }
              // weight={params.data.lvl === 1 || params.data.lvl === 0}
              float={'center'}>
              {params.value}
            </Cell>
          );
        }
      },
      {
        field: 'name',
        headerName: 'Наименование',
        width: 452,

        cellRenderer: (params: any) => {
          return (
            <Cell
            // color={
            //   params.data.lvl === 1 || params.data.lvl === 0
            //     ? '#0044B4'
            //     : undefined
            // }
            // weight={params.data.lvl === 1 || params.data.lvl === 0}
            >
              {params.value}
            </Cell>
          );
        }
      },
      {
        field: 'unit',
        headerName: 'Ед. изм.',
        width: 203,
        cellRenderer: (params: any) => {
          return (
            <Cell
              // color={
              //   params.data.lvl === 1 || params.data.lvl === 0
              //     ? '#0044B4'
              //     : undefined
              // }
              // weight={params.data.lvl === 1 || params.data.lvl === 0}
              float={'center'}>
              {params.value}
            </Cell>
          );
        }
      },
      {
        field: 'qty',
        headerName: 'Количество',
        width: 203,
        cellRenderer: (params: any) => {
          return (
            <Cell
              // color={
              //   params.data.lvl === 1 || params.data.lvl === 0
              //     ? '#0044B4'
              //     : undefined
              // }
              // weight={params.data.lvl === 1 || params.data.lvl === 0}
              isHide={!params.value}
              float={'center'}>
              {params.value?.toLocaleString('ru-RU', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </Cell>
          );
        }
      },
      {
        field: 'price',
        headerName: 'Цена за единицу',
        width: 203,
        cellRenderer: (params: any) => {
          return (
            <Cell
              // color={
              //   params.data.lvl === 1 || params.data.lvl === 0
              //     ? '#0044B4'
              //     : undefined
              // }
              // weight={params.data.lvl === 1 || params.data.lvl === 0}
              isHide={!params.value}
              float={'right'}>
              {params.value?.toLocaleString('ru-RU', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </Cell>
          );
        }
      },
      {
        field: 'cost',
        headerName: 'Стоимость',
        width: 203,
        cellRenderer: (params: any) => {
          return (
            <Cell
              // color={
              //   params.data.lvl === 1 || params.data.lvl === 0
              //     ? '#0044B4'
              //     : undefined
              // }
              // weight={params.data.lvl === 1 || params.data.lvl === 0}
              isHide={!params.value}
              float={'right'}>
              {params.value?.toLocaleString('ru-RU', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </Cell>
          );
        }
      },
      {
        field: 'total',
        headerName: 'Полная стоимость',

        width: 203,
        cellRenderer: (params: any) => {
          return (
            <Cell
              // color={
              //   params.data.lvl === 1 || params.data.lvl === 0
              //     ? '#0044B4'
              //     : undefined
              // }
              // weight={params.data.lvl === 1 || params.data.lvl === 0}
              isHide={!params.value}
              float={'right'}>
              {params.value?.toLocaleString('ru-RU', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </Cell>
          );
        }
      }
    ];
  }, [data]);

  const rowTopPinned: ITangleTableResponse[] = useMemo(() => {
    return [
      {
        lvl: 0,
        catalog: '',
        code: null,
        cost: null,
        children: null,
        type: 'total',
        name: null,
        id: -1,
        qty: null,
        parentID: null,
        price: null,
        unit: null,
        total: data?.total ?? 0
      }
    ];
  }, [data?.total]);

  const collapse = (id: number) => {
    // if (setHiddenRowsIds) {
    setHiddenRowsIds((d) => {
      const copy = [...d];
      const addIndex = copy.findIndex((hrId) => hrId === id);
      if (addIndex >= 0) {
        copy.splice(addIndex, 1);
      } else {
        copy.push(id);
      }
      return copy;
    });
    // }
  };
  useMutationHandlers(deleteCalculationResponse, () => {
    navigate(`/projects/${projectID}/calculations`);
    enqueueSnackbar(t('success.calculationDelete'), {
      variant: 'success'
    });
  });

  useBreadcrumbs(
    [
      { title: <ProjectLabel /> },
      {
        title: 'Расчеты',
        url: `/projects/${projectID}/calculations`
      },
      {
        title: (
          <CalculationLabel
            title={
              (calculation?.title || '') +
              (calculation?.tanglModel ? ` (${calculation.tanglModel})` : '')
            }
            type={calculation?.type}
          />
        ),
        url: `projects/${projectID}/calculation/${calculation?.id}/edit`
      }
    ],
    [calculation?.title]
  );

  useEffect(() => {
    if (!isFetching) {
      setFilteredData(() => {
        if (data?.data) {
          const indexes: number[] = [];
          const filledArr = data?.data.reduce(
            (acc: ITangleTableResponse[], curr) => {
              const newItem: ITangleTableResponse = Object.assign({}, curr);
              let idx = false;
              if (newItem && newItem.parentID && newItem.id) {
                idx =
                  hiddenRowsIds.includes(newItem.parentID) ||
                  indexes.includes(newItem.id);
                if (idx && newItem.children?.length) {
                  indexes.push(...newItem.children.map((item) => item.id));
                }
              }
              return !idx ? [...acc, newItem] : acc;
            },
            []
          );

          Ref.current?.api.hideOverlay();

          // setUpdateLoader(false);
          return filledArr?.length ? filledArr : data.data;
        } else {
          Ref.current?.api.hideOverlay();
          // setUpdateLoader(false);
          return [];
        }
      });
    }
  }, [hiddenRowsIds, data?.data, isFetching, Ref.current]);

  useEffect(() => {
    if (filteredData?.length && Ref.current) {
      firstLoad.current = false;
      // gridRef?.api?.applyTransaction({ update: filteredData });
      Ref.current?.api?.refreshCells({ force: true });
    }
  }, [filteredData, Ref.current]);

  useLayoutEffect(() => {
    Ref.current?.api?.showLoadingOverlay();
    if (data?.data) {
      let maxLevel = 1;
      const hideRows = data.data
        .filter((item) => item.type === 'folder' || item.type === 'work')
        .map((file) => {
          if (file.type === 'folder' && file.lvl && file.lvl >= maxLevel) {
            maxLevel = file.lvl;
            if (file.children?.length) {
              maxLevel = file.lvl + 1;
            }
          }
          return file.id!;
        });
      maxDepth.current = maxLevel;
      setHiddenRowsIds(hideRows);
    }
  }, [data?.data]);

  useEffect(() => {
    if (!firstLoad.current) {
      const hideRows =
        data?.data
          .filter((el) => {
            if (el.lvl && el.lvl >= currentDepth) {
              return true;
            }
            return false;
          })
          ?.map((_) => _.id!) ?? [];
      setHiddenRowsIds(hideRows);
    }
  }, [currentDepth]);

  return (
    <TangleTableContext.Provider value={{ collapse, hiddenRowsIds }}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        pt={'10px'}
        gap={'12px'}>
        <Stepper
          count={maxDepth.current}
          page={currentDepth}
          onChange={(ev, page) => changeDepth(page)}
        />
        {calculation?.integrationInfo !== null && (
          <BadgeAct color={'#BA68C8'}>
            Передан в {EPragmaModules[calculation!.integrationInfo.module]}
          </BadgeAct>
        )}
        <Button
          onClick={handleBtnClick}
          style={{
            padding: '8px',
            minWidth: '40px'
          }}>
          <MoreHorizIcon />
        </Button>
      </Box>

      <PageStyled>
        <span />
        <WrapperAgGrid className="ag-theme-material reference-prices">
          {/*{isLoading && <Progress />}*/}
          <AgGridReact
            ref={Ref}
            enableCellTextSelection={true}
            ensureDomOrder={true}
            maintainColumnOrder={true}
            suppressScrollOnNewData={true}
            defaultColDef={{
              resizable: false
            }}
            columnDefs={columnsDef}
            gridOptions={{
              // suppressDragLeaveHidesColumns: true,
              navigateToNextHeader: () => null,
              tabToNextHeader: () => null
            }}
            pinnedTopRowData={rowTopPinned}
            rowData={filteredData}
            suppressCellFocus={true}
            // onFirstDataRendered={(event) => {
            //   event.api.sizeColumnsToFit();
            // }}
            // onGridSizeChanged={(event: GridSizeChangedEvent) => {
            //   event.api.sizeColumnsToFit();
            // }}
            // onViewportChanged={(event) => {
            //   event.api.sizeColumnsToFit();
            // }}
            getRowId={(params) => {
              return params.data.id.toString();
            }}
            // getRowHeight={(params) => {
            //   if (params.node.rowPinned === 'top') {
            //     return 50;
            //   }
            //   return 55;
            // }}
            getRowClass={(
              params: RowClassParams<ITangleTableResponse, any>
            ) => {
              return params.data?.type === 'folder'
                ? `level-${params.data?.lvl}`
                : params.data?.type === 'work'
                  ? 'rate'
                  : 'rate-position';
            }}
            rowStyle={{
              padding: '0 !important'
            }}
            // getRowStyle={(params: RowClassParams<any>) => {
            //   if (params.data.lvl === 0) {
            //     return { background: '#1976D214' };
            //   }
            //   return { background: 'inherit' };
            // }}
            rowHeight={55}
            headerHeight={55}
            loadingOverlayComponent={Progress}
            noRowsOverlayComponent={NoRows}></AgGridReact>
        </WrapperAgGrid>
      </PageStyled>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={MenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem
          onClick={() => {
            toggleOpenCopy(true);
            setAnchorEl(null);
          }}>
          Создать копию расчета
        </MenuItem>
        <MenuItem
          onClick={() => handleDeleteCalculationClick('Delete')}
          disabled={deleteCalculationResponse.isLoading}>
          Удалить расчет
        </MenuItem>
        <MenuItem
          onClick={() => {
            openEdit();
            setAnchorEl(null);
          }}>
          Редактировать
        </MenuItem>
      </Menu>
      <ConfirmDiaologDelete />
    </TangleTableContext.Provider>
  );
};

export default TanglTable;
