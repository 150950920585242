import FolderIcon from '@mui/icons-material/Folder';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  useLazyAutomaticTreeProtectionQuery,
  useLazyDownloadDataTreeExcelQuery,
  useLazyGetGroupPositionQuery
} from '../../../api/calculations';
import { useMutationHandlers } from '../../../hooks/useMutationHandlers';
import { downloadBase64File } from '../../../utils/downloadCalculation';
import { ButtonAutoStyled, TopbarStyled } from './Topbar.styles';
import { ImportButton } from '../../Administration/AdminReferences/Prices/components/PricesReferences.style';
import DownloadIcon from '@mui/icons-material/Download';

const Topbar: React.FC<{ isHandbook: boolean }> = ({ isHandbook }) => {
  const { calcID } = useParams<{ calcID: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const [getTreeProtection, getTreeProtectionResponse] =
    useLazyAutomaticTreeProtectionQuery();
  const [getPosition] = useLazyGetGroupPositionQuery();
  const [downloadDataTree, {}] = useLazyDownloadDataTreeExcelQuery();

  useMutationHandlers(
    getTreeProtectionResponse,
    () => {
      enqueueSnackbar('Уровни успешно сформированы автоматически', {
        variant: 'success'
      });
    },
    () => {
      enqueueSnackbar('Не удалось сформировать уровни автоматически', {
        variant: 'error'
      });
    }
  );

  useEffect(() => {
    if (
      !getTreeProtectionResponse.isFetching &&
      getTreeProtectionResponse.isSuccess
    )
      getPosition({
        calcID: Number(calcID),
        isHandbook
      });
  }, [getTreeProtectionResponse.isFetching]);

  const treeProtectionClickHandler = () => {
    if (calcID) {
      getTreeProtection({ calcID: +calcID, isHandbook });
    }
  };

  const downloadClickHandler = () => {
    if (calcID) {
      downloadDataTree({ calcID: +calcID, isHandbook }).then(({ data }) => {
        if (data) {
          downloadBase64File(
            data,
            `${'Ведомость укрупненных расценок "Наименование расчета".xlsx'}`
          );
        }
      });
    }
  };

  return (
    <TopbarStyled>
      <Box
        position={'sticky'}
        left={14}
        sx={{ display: 'flex', alignItems: 'center' }}>
        <ButtonAutoStyled
          sx={{ float: 'left' }}
          variant="text"
          startIcon={<FolderIcon />}
          disableElevation
          onClick={treeProtectionClickHandler}>
          Сформировать уровни автоматически
        </ButtonAutoStyled>
      </Box>
      <ButtonAutoStyled
        onClick={downloadClickHandler}
        variant={'text'}
        startIcon={<DownloadIcon />}
        sx={{
          position: 'sticky',
          right: 14
        }}>
        Экспорт .xlsx
      </ButtonAutoStyled>
      {/*<Box position={'sticky'} right={14} display="flex" alignItems="center">*/}
      {/*  <IconButton >*/}
      {/*    <UploadFileIcon sx={{ fill: '#7890B2' }} />*/}
      {/*  </IconButton>*/}
      {/*</Box>*/}
    </TopbarStyled>
  );
};

export default Topbar;
