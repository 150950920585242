import { GridApi } from '@ag-grid-community/core';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { BodyScrollEndEvent } from 'ag-grid-community';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getAgGridRef } from 'store/slices/ag-grid/ag-grid-slice';
import { useAppDispatch, useTypedSelector } from 'store/store';

interface IInitialParams {
  limit: number;
  page: number;
  calcID: number | string;
}

interface IConfigPagination<T> {
  sendFn: (params: T & IInitialParams) => AsyncThunkAction<any, any, any>;
  initialParams: T & IInitialParams;
  thenFn?: () => void;
  catchFn?: () => void;
  requiredDeps?: any[];
  resetToInitialDeps?: any[];
  isAllDataFetched?: boolean;
}
export function usePagination<T>({
  sendFn,
  thenFn = () => {},
  catchFn = () => {},
  resetToInitialDeps = [],
  initialParams,
  requiredDeps = [],
  isAllDataFetched
  // isAllDataFetched
}: IConfigPagination<T>) {
  const promise = useRef<any>();

  /* локальный стейт для перехвата параметров запроса снаружи, далее управляется в хуке */
  const [localParams, setLocalParams] = useState(initialParams);
  const { inView: isLastRowInView, reset: resetInView } =
    useAgGridLastRowInView();

  // useEffect(() => {
  //   if (initialParams && !saveUserParams.current) {
  //     saveUserParams.current = initialParams;
  //   }
  // }, [initialParams]);

  const dispatch = useAppDispatch();

  /* локальный стейт для запуска запроса */
  const [isFetch, setIsFetch] = useState(false);

  /* локальный стейт для определения идет запрос в данный момент или нет */
  const [isFetching, setIsFetching] = useState(false);

  /**
   * функция, которая возвращается из хука,
   * прослушивает событие скролла внутри таблицы AgGrid,
   * определяет положения конца таблицы и устанавливает новые параметры запроса,
   * чем тригерит запрос к БД
   */
  const fetchMoreData = useCallback(() => {
    // console.log('fetchMoreData');
    setLocalParams((prevState) => {
      // console.log(prevState);
      // console.log({
      //   ...prevState,
      //   limit: prevState.limit,
      //   page: prevState.page + 1
      // });
      return {
        ...prevState,
        limit: prevState.limit,
        page: prevState.page + 1
      };
    });
  }, []);

  /**
   * функция запроса к БД, если индикатор загрузки не активен
   * переключает его в активное состояние и делает загрузку,
   * если вдруг случайно произойдет тригер вызова этой функции, запрос не повторится
   * после завершения запроса выключает индикатор загрузки
   * и готов к новому запросу
   */
  const send = useCallback(
    async (indicator: boolean) => {
      console.log('indicator>>>', indicator);
      if (!indicator) {
        setIsFetching(true);
        setIsFetch(false);
        try {
          promise.current = dispatch(sendFn(localParams));
          // console.log('promise before', promise);
          const resultPromise = await promise.current;
          if (resultPromise.payload) {
            thenFn?.();
            resetInView();
            setIsFetching(false);
          }
          // setIsFetch(true);
        } catch (e) {
          catchFn?.();
          setTimeout(() => {
            send(false);
          });
        } finally {
        }

        // .then((response) => {
        // })
        // .catch()
        // .finally(() => );
      }
    },
    [thenFn, catchFn, sendFn, localParams]
  );

  /**
   * Эффект следит за fetch, fetching и requiredDeps
   * сам вызов делается благодаря переключению fetch в активное состояние,
   * далее смотрится массив обязательных для вызова зависимостей,
   * если там все трушное делается запрос и внутрь передается текущее состояние загрузки (описано выше),
   * так как массив не обязателен, идет проверка на его длину, если ее нет, просто вызывается запрос
   */
  useEffect(() => {
    if (isFetch && !isAllDataFetched) {
      if (requiredDeps?.length) {
        const flag = requiredDeps.every((_) => !!_);
        if (flag) {
          send(isFetching);
        }
      } else {
        send(isFetching);
      }
    }
  }, [isFetch, isFetching, isAllDataFetched, ...requiredDeps]);

  /**
   * Эффект следит за массивом зависимостей для сброса параметров запроса, если вдруг что изменится
   * локальные параметры сбросятся до тех что передавались от родителя
   */
  useEffect(() => {
    setLocalParams(initialParams);
    return () => {
      promise.current?.abort();
      setIsFetching(false);
    };
  }, [...resetToInitialDeps]);

  /**
   * Эффект следит за локальными параметрами, когда они поменяются, триггерится вызов
   */
  useEffect(() => {
    setIsFetch(true);
  }, [localParams]);

  const shouldFetchMoreData = !isAllDataFetched && isLastRowInView;

  useEffect(() => {
    if (!shouldFetchMoreData) return;
    fetchMoreData();
  }, [shouldFetchMoreData]);

  return {
    isFetching
  };
}

const intersectionObserverOptions = {
  root: document.querySelector('.ag-body-viewport'),
  rootMargin: '0px 0px 120px 0px',
  threshold: 0
};

const getAgGridLastRowElement = (api: GridApi) => {
  const gridBody = document.querySelector('.ag-body-viewport') as HTMLElement;

  const rowCount = api.getDisplayedRowCount();
  if (rowCount === 0) return null;

  const lastRowIndex = rowCount - 1;
  const lastRowNode = api.getDisplayedRowAtIndex(lastRowIndex);

  return gridBody?.querySelectorAll(
    `[row-index="${lastRowNode!.rowIndex}"]`
  )[0];
};

const useAgGridLastRowInView = () => {
  const grid = useTypedSelector(getAgGridRef);
  const gridApi = grid?.api;
  const [inView, setInView] = useState<boolean>(false);

  const lastRowElementRef = useRef<Element | null>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  // eslint-disable-next-line no-undef
  const intersectionObserverCallback: IntersectionObserverCallback = (
    [entry],
    observe
  ) => {
    if (!entry.isIntersecting) return;

    setInView(true);
    observe.unobserve(lastRowElementRef.current!);
    observerRef.current = null;
    lastRowElementRef.current = null;
  };

  useEffect(() => {
    if (!grid || !gridApi) return;

    const handleBodyScrollEnd = (event: BodyScrollEndEvent) => {
      const lastRowElement = getAgGridLastRowElement(event.api as any);

      if (!lastRowElement) return;
      if (lastRowElementRef.current === lastRowElement) return;

      if (observerRef.current && lastRowElementRef.current) {
        observerRef.current.unobserve(lastRowElementRef.current);
      }

      lastRowElementRef.current = lastRowElement;

      const observer = new IntersectionObserver(
        intersectionObserverCallback,
        intersectionObserverOptions
      );
      observer.observe(lastRowElementRef.current);
      observerRef.current = observer;
    };

    gridApi.addEventListener('bodyScrollEnd', handleBodyScrollEnd);
    gridApi.addEventListener('scrollVisibilityChanged', handleBodyScrollEnd);
    gridApi.addEventListener('displayedRowsChanged', handleBodyScrollEnd);
    gridApi.addEventListener('viewportChanged', handleBodyScrollEnd);
    // gridApi.addGlobalListener((event: any) => console.log('event>>>', event));
    return () => {
      gridApi.removeEventListener('bodyScrollEnd', handleBodyScrollEnd);
      gridApi.removeEventListener(
        'scrollVisibilityChanged',
        handleBodyScrollEnd
      );
      gridApi.removeEventListener('displayedRowsChanged', handleBodyScrollEnd);
      gridApi.removeEventListener('viewportChanged', handleBodyScrollEnd);
    };
  }, [grid, gridApi]);

  const reset = () => setInView(false);

  return {
    inView,
    reset
  };
};
