import { RowClassParams } from 'ag-grid-community';
import { IIndexMethodTable } from 'types/index-method/index-method';
import { ExecutionCalculationRimData } from '../../../types';

export function getRowClassIndexMethodCalculation(
  params: RowClassParams<IIndexMethodTable>
) {
  if (params.data?.type === 'limit' && typeof params.data.id === 'string') {
    return 'limit-row';
  }
  return params.data?.type === 'file'
    ? `level-${params.data?.lvl}`
    : params.data?.type === 'level'
      ? 'rate'
      : 'rate-position';
}

export const getNestedChildrenIdsIndexMethodCalculation = (
  id: number,
  defaultData: (IIndexMethodTable | ExecutionCalculationRimData)[]
): number[] => {
  const children = defaultData.filter((e) => e?.parentID === id);

  return children.reduce<number[]>(
    (acc, child) =>
      [
        ...acc,
        child.id as number,
        ...getNestedChildrenIdsIndexMethodCalculation(
          child.id as number,
          defaultData
        )
      ].filter((e) => e !== undefined),
    []
  );
};
