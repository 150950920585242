import { GetCoefficientsResponse } from 'api/params/params.types';
import { useFormContext, useFieldArray } from 'react-hook-form';
import TabComponent from '../../../../../TabComponent/TabComponent';
import { StyledWrapper } from './Body.styles';

const Body: React.FC = () => {
  const { control } = useFormContext<GetCoefficientsResponse>();

  const { fields } = useFieldArray({
    name: 'toTotals',
    control
  });

  return (
    <StyledWrapper>
      <TabComponent fields={fields} itemType={'toTotals'} />
    </StyledWrapper>
  );
};

export default Body;
