import AddIcon from '@mui/icons-material/Add';
import { Grid, Stack, useMediaQuery } from '@mui/material';
import { useGetProjectsQuery } from 'api/projects';
import { ButtonData, EmptyPage, EmptyPageData } from 'components/EmptyPage';
import Progress from 'components/Progress';
import { TabData } from 'components/Tabs/Tabs.types';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useSearch from 'hooks/useSearch';
import { useAppContext } from 'layouts/Layout/context';
import React, { useCallback, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Project,
  ProjectTabLabel,
  projectStatusByTabLabel,
  projectTabLabels
} from 'types';
import { XXL_FOR_LAYOUT } from 'utils/constants';
import { filterByFieldNames } from 'utils/filterByFieldNames';
import AdminLegend from '../components/AdminLegend';
import { ProjectCard } from '../components/ProjectCard';
import { CardsFullWrapper } from './AdminProjects.styles';

export const getEmptyPageData = (
  text: React.ReactNode,
  buttons?: ButtonData[]
): EmptyPageData => ({
  text,
  buttons:
    buttons?.map((button) => ({
      text: button.text,
      icon: button.icon,
      onClick: button.onClick
    })) || []
});

export const AdminProjects: React.FC = () => {
  const { profile } = useAppContext();
  const xxl = useMediaQuery(`(min-width: ${XXL_FOR_LAYOUT})`);
  const { searchValue } = useSearch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isAdministration = pathname.includes('administration');

  const getProjectLink = (projectId: number) => {
    return isAdministration
      ? `${pathname}/edit/${projectId}`
      : `/dashboard/${projectId}`;
  };

  const { firstName } = profile;

  const [currentTab, setCurrentTab] = useState<ProjectTabLabel>('Все проекты');

  const onTabChange = useCallback(
    (e: React.SyntheticEvent, tabValue: ProjectTabLabel) => {
      setCurrentTab(tabValue);
    },
    []
  );

  const onAddProjectClick = useCallback(() => {
    navigate(isAdministration ? 'add' : '/administration/projects/add');
  }, []);

  const { data, isLoading, isFetching } = useGetProjectsQuery();

  const breadcrumbs = useMemo(() => {
    if (pathname === '/projects') return [{ title: 'Управление проектами' }];
    return [{ title: 'Редактирование проектов' }, { title: currentTab }];
  }, [pathname, currentTab]);

  useBreadcrumbs(breadcrumbs, [breadcrumbs]);

  const filteredBySearch = useMemo(() => {
    return searchValue && data?.projects.length
      ? filterByFieldNames<Project>(
          data?.projects,
          ['fullName', 'shortName'],
          searchValue
        )
      : data?.projects;
  }, [data?.projects, searchValue]);

  const filteredByStatus = useMemo(() => {
    return currentTab === 'Все проекты'
      ? filteredBySearch
      : filteredBySearch?.filter(
          (project) => project.status === projectStatusByTabLabel[currentTab]
        );
  }, [filteredBySearch, currentTab]);

  const tabsData: TabData<ProjectTabLabel>[] = useMemo(() => {
    return projectTabLabels.map((tabName) => ({
      value: tabName,
      label: tabName
    }));
  }, [filteredBySearch]);

  const emptyPageData: EmptyPageData = getEmptyPageData(
    <>
      Здравствуйте, {firstName}.<br /> У Вас еще нет проектов для отображения.
      <br />
      {profile.role === 'admin'
        ? 'Давайте создадим ваш первый проект.'
        : ''}{' '}
    </>,
    profile.role === 'admin'
      ? [
          {
            text: 'Добавить проект',
            icon: AddIcon,
            onClick: () => onAddProjectClick()
          }
        ]
      : undefined
  );

  const emptyFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют проекты, соответствующие результатам запроса.</>
  );

  return (
    <Stack height="100%" flexGrow={1}>
      {isLoading || isFetching ? (
        <Progress />
      ) : data?.projects?.length ? (
        <>
          <AdminLegend<ProjectTabLabel>
            currentTab={currentTab}
            tabsData={tabsData}
            onTabChange={onTabChange}
            onAddClick={onAddProjectClick}
          />
          {filteredByStatus?.length ? (
            <CardsFullWrapper>
              <Grid spacing={2.5} container>
                {filteredByStatus.map((project) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    xl={xxl ? 2 : 3}
                    container
                    justifyContent="center"
                    key={project.id}>
                    <Link
                      to={getProjectLink(project.id)}
                      style={{ display: 'flex', width: '100%' }}>
                      <ProjectCard data={project} />
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </CardsFullWrapper>
          ) : (
            <EmptyPage data={emptyFilteredData} />
          )}
        </>
      ) : (
        <EmptyPage data={emptyPageData} />
      )}
    </Stack>
  );
};
