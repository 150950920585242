import { AlertColor } from '@mui/material/Alert/Alert';
import React, { MutableRefObject, useRef } from 'react';
import { SnackbarCloseReason } from '../../utils/constants';

export type BreadcrumbsItemType = 'project' | 'calculation';

// export type BreadcrumbsItemType = 'calculation'

export interface BreadcrumbsItem {
  title: any;
  url?: string;
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void;
  MenuOpen?: boolean;
  query?: string;
  type?: BreadcrumbsItemType;
}

export class UIContextState {
  snackbarMessage: string | null = null;
  snackbarType: AlertColor | null = null;
  breadcrumbsItems: BreadcrumbsItem[] = [];
  showSearch = false;
  searchValue = '';
  searchRef: MutableRefObject<HTMLDivElement> | null =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement> | null;
  searchIsDirty = false;
  searchIsDefault = false;
}

export interface UIContextValue extends UIContextState {
  showSnackbarMessage: (type: AlertColor, message: string) => any;
  setHideSnackbar: (reason: SnackbarCloseReason) => any;
  setBreadcrumbs: (...items: BreadcrumbsItem[]) => any;
  setSearchValue: (
    value: string,
    action: SearchChangeAction,
    immediately?: boolean
  ) => any;
  setShowSearch: (value: boolean) => any;
  clearSearchRefValue: () => any;
}

export type SearchChangeAction = 'userInput' | 'setDefaultValue' | 'clear';
