import {
  Checkbox,
  Chip,
  LinearProgress,
  linearProgressClasses,
  Stack
} from '@mui/material';
import styled from 'styled-components';

export const AddCalculationDrawerContentWrapper = styled(Stack)(
  ({ theme }) => ({
    width: '100%',
    minWidth: 0,
    border: `1px solid ${theme.palette.bg.gray}`,
    borderRadius: '16px'
  })
);

export const AddCalculationDrawerContentHeader = styled(Stack)`
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: 100%;
  min-height: 56px;
  padding: 13px 16px;

  & .MuiButton-text {
    text-transform: none;
    font-weight: 400;
  }
  & .MuiButton-root {
    height: 30px;
    padding: 6px 12px;
  }
`;

export const AddCalculationDrawerContent = styled(Stack)`
  flex: 1;
  padding: 12px;
  overflow: auto;
  gap: 8px;
`;

export const AddCalculationDrawerFooter = styled(Stack)`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  & .MuiButton-root {
    width: 200px;
    height: 40px;
    box-shadow: none;
  }
`;
export const EmptyWrapper = styled(Stack)`
  justify-content: center;
  height: 100%;
  & .MuiTypography-root {
    font-size: 14px;
  }
  & svg {
    width: 349px;
    height: 221px;
  }
  & .MuiBox-root {
    margin-top: 20px;
  }
`;

export const AddCalculationListItem = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  padding: '8px 12px',
  border: `1px solid ${theme.palette.bg.gray}`,
  borderRadius: '8px',

  '& .MuiIconButton-root': {
    marginLeft: 'auto',
    marginRight: '-4px'
  }
}));

export const StyledListItemChip = styled(Chip)(({ theme }) => ({
  color: 'white',
  lineHeight: '24px',
  fontSize: '12px',
  fontWeight: 500,
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.spacing(0.5),
  textTransform: 'uppercase',
  cursor: 'unset'
}));

export const StyledListItemCounter = styled(Chip)(({ theme }) => ({
  width: '24px',
  color: theme.palette.primary.main,
  lineHeight: '24px',
  fontSize: '12px',
  fontWeight: 500,
  backgroundColor: '#1976D214',
  borderRadius: '50%',
  textAlign: 'center',
  cursor: 'unset'
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  color: '#2B36481F'
}));

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: '265px',
  height: '11px',
  borderRadius: '4px',
  boxShadow:
    'inset 0px -1px 1px rgba(52, 89, 254, 0.15), inset 0px 1px 1px rgba(52, 89, 254, 0.15)',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#6D9ADC' : '#8CBBE8'
  }
}));
