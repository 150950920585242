import React, { useContext, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { GetCoefficientsResponse } from '../../../../../../../../../api/params/params.types';
import { ActListContext } from '../../../../../../../../Calculations/components/CalculationСomplicated';
import { ActCol, ActRow, ActTable } from './act.styles';
import { Typography } from '@mui/material';
import { ActRow as Row } from './act.row';
import { useFormikContext } from 'formik';
import { DialogForm } from '../../../parameters-dialog.execution.types';
import { ActList } from 'types';

export const ActTab: React.FC<{
  setCurrentAct: (act: ActList | null) => void;
}> = ({ setCurrentAct }) => {
  // const { control } = useFormContext<GetCoefficientsResponse>();
  const { values } = useFormikContext<DialogForm>();
  const [fields, setFields] = useState<typeof values.acts>([]);

  const { calculation } = useContext(ActListContext);

  // const fieldsForm = useWatch({
  //   name: 'acts',
  //   control
  // });

  console.log(values);

  useEffect(() => {
    setFields(values.acts || []);
  }, [values.acts]);

  return (
    <ActTable>
      <ActRow $isIntegrate={!!calculation?.integrationInfo} $border $paddingTop>
        <ActCol $head>Сформирован</ActCol>
        <ActCol $head $align={'center'}>
          Начало периода
        </ActCol>
        <span />
        <ActCol $head $align={'center'}>
          Окончание периода
        </ActCol>
        <span />
        {calculation?.integrationInfo && (
          <ActCol $head $align={'flex-start'}>
            Статус
          </ActCol>
        )}
      </ActRow>
      {fields.map((estimate: any, estimateIndex: any) => {
        return (
          <React.Fragment key={'estimate' + estimateIndex + estimate.label}>
            {!!estimate.fields.length && (
              <Typography
                style={{ cursor: 'default' }}
                whiteSpace={'nowrap'}
                textOverflow={'ellipsis'}
                width={'100%'}
                overflow={'hidden'}
                pt={2}
                fontSize={14}
                color={'#7890b2'}>
                {estimate.label || 'Общие'}
              </Typography>
            )}
            {estimate.fields.map((row: any, rowIndex: any) => (
              <Row
                setCurrentAct={setCurrentAct}
                key={'parent' + estimateIndex + estimate.label + rowIndex}
                parentIndex={estimateIndex}
                rowIndex={rowIndex}
                row={row}
              />
            ))}
          </React.Fragment>
        );
      })}
    </ActTable>
  );
};
