import { StyledBody } from './Body.styles';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Estimate } from '../Estimete';
import { GetCoefficientsResponse } from '../../../../../../../../../../../../api/params/params.types';
import { VirtualizedList } from '../../../../../../../../../../../../components/VirtualizedList';
import { useMemo } from 'react';

export const Body: React.FC = () => {
  const { control } = useFormContext<GetCoefficientsResponse>();

  const { fields } = useFieldArray({
    name: 'nrsp',
    control
  });

  const listData = useMemo(
    () => fields.map((_, index) => ({ parentIndex: index })),
    [fields]
  );

  return (
    <StyledBody>
      <VirtualizedList
        items={listData}
        Component={Estimate}
      />
    </StyledBody>
  );
};
