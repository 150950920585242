import { ColDef } from 'ag-grid-community';
import { PartExecutionRim, TActStats } from 'types';
import { OrNull } from 'api/references/estimates/estimates.types';
import { Stats } from './index-method-execution.types';

export const defaultDef: ColDef = {
  cellRendererParams: {
    float: 'right'
  },
  editable: false,
  cellEditor: 'ExecutedTabCellEditable',
  resizable: true,

  suppressMovable: true
};

export const statusesAct: Record<TActStats, OrNull<Stats>> = {
  NEW: null,
  PREPARED: {
    title: 'Период изменен',
    color: '#FF9800'
  },
  CLOSED: {
    title: 'Период закрыт',
    color: '#2E7D32'
  },
  COLLECTING: {
    title: 'Получение факта',
    color: '#A14DE7'
  },
  REOPENED: {
    title: 'Период изменен',
    color: '#FF9800'
  }
};

export const LIMIT = 10000;
