import React from 'react';
import { StyledPopper } from './style';

export const PopperComponent: React.FC<{
  message: string | undefined;
  refItem: any;
  offset?: number[];
}> = ({ message, refItem, offset }) => {
  const style: any = { zIndex: 1301 };
  return (
    <StyledPopper
      style={style}
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: offset ?? [0, 4]
            }
          }
        ]
      }}
      open={!!message}
      anchorEl={refItem}
      placement={'bottom-end'}>
      {message}
    </StyledPopper>
  );
};
