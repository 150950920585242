import { Box } from '@mui/material';
import React from 'react';
import { ColDef, ColGroupDef, EditableCallbackParams } from 'ag-grid-community';
import { WrapperAgGrid } from 'pages/Calculations/components/Accomplishment/Accomplishment.styles';
import { AgGridReact } from 'ag-grid-react';
import { CellRenderer } from './table.cell-renderer';
import { useFormikContext } from 'formik';
import { TableCellEditor } from './table.cell-editor';
import { EstimateInfo, LimitedLocal } from 'types';
import { DialogForm } from '../../../parameters-dialog.execution.types';
import {
  useGetDynamicLimitsQuery,
  useGetLimitedListQuery
} from '../../../../../../../../../api/params';
import { useCalcId } from '../../../../../../../../../hooks/useCalcId';
import Progress from '../../../../../../../../../components/Progress';
import { defaultColumns, defaultPropsColumns } from './table.constants';

export const Table = () => {
  const formik = useFormikContext<any>();
  const ref = React.useRef<AgGridReact>(null);

  const calcID = useCalcId();

  const { data: limitedList, isFetching } = useGetDynamicLimitsQuery(
    { calcID },
    {
      skip: !calcID
    }
  );

  const getOptions = React.useCallback(
    (chapter: number) => {
      return limitedList?.filter((el) => el.chapter === chapter) ?? [];
    },
    [limitedList]
  );

  const rowData = React.useMemo(() => {
    return formik.values.rows;
  }, [formik.values.rows]);

  React.useEffect(() => {
    ref.current?.api?.refreshCells({ force: true });
  }, [formik.values.rows]);

  return (
    <Box width="100%" height="100%" p="16px 30px">
      <WrapperAgGrid
        style={{ border: '1px solid #D1D8FA', borderRadius: '16px' }}
        className="ag-theme-material reference-prices index-method-parameters-dialog">
        <AgGridReact
          ref={ref}
          singleClickEdit
          components={{
            CellRenderer,
            TableCellEditor
          }}
          context={{
            estimates:
              formik.values?.estimates?.map((el: any) => ({
                id: el.estimateID,
                label: el.estimateTitle,
                lsr: el.lsrCode
              })) || [],
            getOptions,
            limitedList
            // estimates: estimates?.map((el) => ({
            //   id: el.estimateID,
            //   label: el.estimateTitle,
            //   lsr: el.lsrCode
            // }))
          }}
          onCellEditingStopped={(event) => {
            event.api.refreshCells({ force: true });
          }}
          defaultColDef={defaultPropsColumns}
          suppressCellFocus
          rowData={isFetching ? undefined : rowData}
          getRowId={(params) => {
            if (params.data.position === 'group') {
              return params.data?.id?.toString() || '';
            }
            return params.data.uuid?.toString() || '';
          }}
          columnDefs={defaultColumns}
          getRowClass={(params) => {
            const commonClass = getOptions(params.data?.chapter)?.length
              ? ''
              : ' mute';
            return params.data?.position === `group${commonClass}`
              ? `group${commonClass}`
              : commonClass;
          }}
          loadingOverlayComponent={Progress}
        />
      </WrapperAgGrid>
    </Box>
  );
};
