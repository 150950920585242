import * as yup from 'yup';
import { replaceNum } from './table.helper';
import { DialogForm } from '../../../parameters-dialog.execution.types';

const testNumberingField: yup.TestFunction = function (value, ctx) {
  const filterValues = (
    (ctx?.options?.context as DialogForm) || []
  )?.rows?.filter((el: any) => el.position === 'position');
  const typePath = this.path.replace(/.+(\[\d+\]).+$/, '$1?.type');
  const type = eval(`filterValues?.` + typePath);
  const val = replaceNum(
    ((value as string) || '')
      .replace(/\s/g, '')
      .replace(/-/g, '')
      .replace(/%/g, '')
  );

  let subText = 'для типа ';

  switch (type) {
    case 'sum':
      subText += '"Сумма"';
      break;
    case 'coeff':
      subText += '"К-т"';
      break;
    case 'percent':
      subText += '"%"';
      break;
    default:
      subText += '';
  }

  const dotIndex = val.indexOf(',');
  const [MAX_LENGTH_LEFT, MAX_LENGTH_RIGHT] =
    type !== 'sum' ? [6, 15] : [12, 2];
  const message =
    `Максимальное значение ${subText} +/- ` +
    Number(Array(MAX_LENGTH_LEFT).fill(9).join('')).toLocaleString() +
    ',' +
    Array(MAX_LENGTH_RIGHT).fill(9).join('');

  if (dotIndex >= 0) {
    if (
      val.substring(0, dotIndex).length > MAX_LENGTH_LEFT ||
      val.substring(dotIndex + 1).length > MAX_LENGTH_RIGHT
    ) {
      return this.createError({
        message
      });
    } else {
      return true;
    }
  } else {
    if (val.length > MAX_LENGTH_LEFT) {
      return this.createError({
        message
      });
    } else {
      return true;
    }
  }
};

export const tableValidationSchema = (values: DialogForm) =>
  yup.object().shape({
    acts: yup.array().optional(),
    removedActs: yup.array().optional(),
    removed: yup.array().compact((el) => el.isDeleted),
    rows: yup
      .array()
      .compact((el) => {
        return el.position === 'group';
      })
      .of(
        yup.object().shape({
          id: yup.mixed().optional(),
          uuid: yup.string().optional(),
          type: yup.string().oneOf(['coeff', 'percent', 'sum']),
          position: yup.string().oneOf(['position', 'group']).optional(),
          values: yup.object().shape({
            building: yup
              .string()
              .test('required-field', 'Обязательное поле', (value, ctx) => {
                return (
                  ctx.parent?.total ||
                  ctx.parent?.mounting ||
                  ctx.parent?.equipment ||
                  ctx.parent?.other ||
                  value
                );
              })
              .test('limit', 'Максимальное значение', testNumberingField),
            mounting: yup
              .mixed()
              .test('required-field', 'Обязательное поле', (value, ctx) => {
                return (
                  ctx.parent?.total ||
                  ctx.parent?.building ||
                  ctx.parent?.equipment ||
                  ctx.parent?.other ||
                  value
                );
              })
              .test('limit', 'Максимальное значение', testNumberingField),
            equipment: yup
              .mixed()
              .test('required-field', 'Обязательное поле', (value, ctx) => {
                return (
                  ctx.parent?.total ||
                  ctx.parent?.building ||
                  ctx.parent?.other ||
                  ctx.parent?.mounting ||
                  value
                );
              })
              .test('limit', 'Максимальное значение', testNumberingField),
            other: yup
              .mixed()
              .test('required-field', 'Обязательное поле', (value, ctx) => {
                return (
                  ctx.parent?.total ||
                  ctx.parent?.building ||
                  ctx.parent?.mounting ||
                  ctx.parent?.equipment ||
                  value
                );
              })
              .test('limit', 'Максимальное значение', testNumberingField),
            total: yup
              .mixed()
              .test('required-field', 'Обязательное поле', (value, ctx) => {
                return (
                  ctx.parent?.building ||
                  ctx.parent?.mounting ||
                  ctx.parent?.equipment ||
                  ctx.parent?.other ||
                  value
                );
              })
              .test('limit', 'Максимальное значение', testNumberingField)
          }),
          title: yup.string().required('Обязательное поле'),
          estimates: yup
            .array()
            .of(
              yup.object({
                id: yup
                  .mixed()
                  .test(
                    'is-string-or-number',
                    'ID должен быть строкой или числом',
                    (value) =>
                      typeof value === 'string' || typeof value === 'number'
                  )
                  .required('ID обязателен')
              })
            )
            .optional(),
          dependencies: yup.array().of(
            yup.object({
              id: yup
                .mixed()
                .test(
                  'is-string-or-number',
                  'ID должен быть строкой или числом',
                  (value) =>
                    typeof value === 'string' || typeof value === 'number'
                )
                .required('ID обязателен')
            })
          ),
          chapter: yup.number().optional()
        })
      )
  });
