import { Body, Head } from './components';
import { StledWrapper } from './Table.styles';

export const Table: React.FC = () => {
  return (
    <StledWrapper>
      <Head />
      <Body />
    </StledWrapper>
  );
};
