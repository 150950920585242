/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import { Box, Button, Divider, Stack } from '@mui/material';
import { Form, FormikProvider } from 'formik';
import React, { useMemo, useRef, useState } from 'react';
import { Drawer } from 'components/Drawer';
import { useForm } from 'hooks/useForm';
import { CalcData } from 'types';
import { CalculationFormData } from '../../../CalculationDrawerForm/CalculationDrawerForm.types';
import { validationCalculation } from '../../../CalculationDrawerForm/CalculationDrawerForm.validation';
import SearchIcon from '@mui/icons-material/Search';
import { useGetShortProjectsQuery } from 'api/projects';
import { useProjectId } from 'hooks/useProjectId';
import { useDebounce } from 'hooks/useDebounce';
import { useWS, useWSHCopy, WSRequestBodyHandbookCopy } from 'api/web-socket';
import { enqueueSnackbar } from 'notistack';
import { useCalcId } from 'hooks/useCalcId';
import { api } from 'api/api';
import { CircularProgressPercent } from '../../../../../../components/CircularProgressPercent/CircularProgressPercent';
import { useAppDispatch } from '../../../../../../store/store';
import TextFieldForm from './compponents/TextFieldForm';
import UploadInfo from './compponents/UploadInfo';
import InfoBlock from './compponents/InfoBlock';
import { Search } from './CopyDrawer.styles';
import CheckItemWrapper from './compponents/CheckItem/CheckItemWrapper';
import { ConfirmDialog } from 'pages/Calculations/components/AddCalculationDrawer/components/ConfirmationDialog';

type TProps = {
  onClose: () => void;
  open: boolean;
  calculation?: CalcData;
};

export type TInitValues = Omit<
  CalculationFormData,
  'lsrIds' | 'formationMethod' | 'rimIds' | 'bimIds'
> & {
  projectIDs: number[];
};

const CopyDrawer: React.FC<TProps> = ({ open, onClose, calculation }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useAppDispatch();

  const { connectionStatus } = useWS();

  const {
    copyCalcProgress,
    copyCalcClick,
    copyCalcStatus,
    copyCalc,
    clearCalcProgress,
    clearCalcStatus,
    clearAll
  } = useWSHCopy();

  const projectID = useProjectId();
  const calcID = useCalcId();

  const { data, isFetching } = useGetShortProjectsQuery();

  const [search, setSearch] = React.useState('');

  const debouncedValue = useDebounce(search, 300);
  const [progressPercent, setProgressPercent] = useState<number>(0);
  const [progressTime, setProgressTime] = useState<string>('');
  const [errorData, setErrorData] = useState<number[]>([]);

  const [selectedProjects, setSelectedProjects] = useState<number[]>([]);

  const setSelected = React.useCallback(
    (values: number[]) => setSelectedProjects(values),
    []
  );

  const isUploaded = useMemo(() => {
    return !!copyCalcProgress || copyCalcClick;
  }, [copyCalcProgress, copyCalcClick]);

  const isLoading = useMemo(() => {
    return isUploaded && !copyCalcStatus;
  }, [isUploaded, copyCalcStatus]);

  const projectsList = React.useMemo(() => {
    return data?.projects
      ?.filter((project) => {
        return project.shortName
          ?.toLowerCase()
          ?.includes(debouncedValue.toLowerCase());
      })
      ?.sort((a, b) => {
        if (a.id === +projectID) return -1;
        if (b.id === +projectID) return 1;
        return a.shortName!.localeCompare(b.shortName!);
      });
  }, [data, debouncedValue]);

  const { description, title } = calculation || {};
  const onSubmit = React.useCallback(
    (values: TInitValues) => {
      console.log('connection', connectionStatus);
      if (connectionStatus !== 'Open') {
        enqueueSnackbar(
          'Установка соединения. Повторите попытку через несколько секунд.',
          {
            variant: 'error',
            autoHideDuration: 15000
          }
        );
      } else {
        console.log();
        if (selectedProjects) {
          const projectIDs = selectedProjects.filter(
            (id) => !errorData.includes(id)
          );
          const body: WSRequestBodyHandbookCopy = {
            calcID,
            description: values.description ?? '',
            title: values.title,
            projectIDs
          };
          copyCalc(body);
        }
      }
    },
    [calcID, connectionStatus, copyCalc, errorData, selectedProjects]
  );
  const initialValues: TInitValues = useMemo(
    () => ({
      title: 'Копия ' + (title || ''),
      description: description || undefined,
      projectIDs: []
    }),
    [description, title]
  );
  const { formik } = useForm({
    validationSchema: validationCalculation,
    enableReinitialize: true,
    initialValues,
    onSubmit
  });

  const resetAndCloseDrawer = React.useCallback(() => {
    onClose();

    setTimeout(() => {
      setSearch('');
      setErrorData([]);
      setSelectedProjects([]);
      formik.resetForm();
    }, 300);
  }, [formik]);

  const handleRefresh = React.useCallback(() => {
    if (!copyCalcStatus?.copied) {
      if (formik.dirty || selectedProjects.length) {
        setOpenConfirmDialog(true);
        return;
      }
    }
    dispatch(api.util.invalidateTags(['Projects', 'Calculations']));
    clearAll();
    setErrorData([]);
    resetAndCloseDrawer();
  }, [dispatch, clearAll, resetAndCloseDrawer, formik, copyCalcStatus]);

  React.useEffect(() => {
    if (copyCalcProgress) {
      setProgressTime(copyCalcProgress.remaining);
      setProgressPercent(Math.floor(copyCalcProgress.percent * 100));
    }
  }, [copyCalcProgress]);

  React.useEffect(() => {
    if (copyCalcStatus) {
      setProgressPercent(0);
      setProgressTime('');
      if (copyCalcStatus.copied && !copyCalcStatus.failed.length) {
        enqueueSnackbar({
          variant: 'success',
          message: 'Копии успешно созданы',
          autoHideDuration: 1500,
          preventDuplicate: true
        });
      }
      // clearCalcProgress();
      // clearAll();
    }
  }, [clearAll, handleRefresh, copyCalcStatus]);

  React.useEffect(() => {
    if (copyCalcStatus?.failed) {
      setErrorData((prevState) =>
        prevState.concat(copyCalcStatus.failed.map((_) => _.projectID))
      );
    }
  }, [copyCalcStatus]);
  const disabled = React.useMemo(() => {
    const filteredProjects = selectedProjects.filter(
      (id) => !errorData.includes(id)
    );
    return !formik.values.title || !filteredProjects.length;
  }, [selectedProjects, errorData]);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleConfirm = (confirm: boolean) => {
    setOpenConfirmDialog(false);
    if (confirm) {
      dispatch(api.util.invalidateTags(['Projects', 'Calculations']));
      clearAll();
      setErrorData([]);
      resetAndCloseDrawer();
    }
  };

  return (
    <Drawer title={'Создать копию'} open={open} onClose={handleRefresh}>
      <FormikProvider value={formik}>
        <Stack
          component={Form}
          flex={1}
          overflow="auto"
          onSubmit={formik.handleSubmit}>
          <UploadInfo
            close={handleRefresh}
            repeat={() => {
              clearCalcStatus();
              clearCalcProgress();
            }}
            errorData={errorData}
            allProjects={data?.projects}
            response={copyCalcStatus}
          />

          {isLoading ? (
            <Box
              height={'100%'}
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}>
              <CircularProgressPercent
                thickness={3.6}
                value={progressPercent}
                time={progressTime}
              />
            </Box>
          ) : (
            <Stack
              ref={containerRef}
              flex={1}
              p={2.5}
              pb={0}
              overflow="inherit">
              <Box flex="1 1 auto">
                <TextFieldForm />

                <InfoBlock count={selectedProjects.length} />

                <Divider sx={{ background: '#D1D8FA' }} />

                <Search
                  fullWidth
                  placeholder={'Введите название проекта'}
                  InputProps={{
                    endAdornment: <SearchIcon style={{ marginRight: '10px' }} />
                  }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />

                <CheckItemWrapper
                  selectedProjects={selectedProjects}
                  setSelected={setSelected}
                  errorData={errorData}
                  projectsList={projectsList ?? []}
                  isFetching={isFetching}
                />
              </Box>
              <Stack
                sx={{ background: 'white' }}
                position={'sticky'}
                bottom={0}
                p={2.5}
                direction="row"
                spacing={2}
                pt={2.5}
                mt="auto">
                <Button
                  type="submit"
                  color="success"
                  fullWidth
                  disabled={disabled}>
                  Обработать
                </Button>
                <Button fullWidth onClick={handleRefresh}>
                  Закрыть
                </Button>
              </Stack>
            </Stack>
          )}
        </Stack>
      </FormikProvider>

      <ConfirmDialog open={openConfirmDialog} onClose={handleConfirm} />
    </Drawer>
  );
};

export default CopyDrawer;
