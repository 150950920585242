/**
 * @author Mr_FazbiozZz[mr.fabiozzz@gmail.com]
 */
import styled from 'styled-components';
import EventIcon from '@mui/icons-material/Event';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DesktopDatePicker } from '@mui/x-date-pickers';

export const WrapperDialog = styled.form`
  width: 400px;
  border-radius: 8px;
  background: #fff;
  padding: 30px;

  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const WarningText = styled.p`
  color: ${({ theme: { palette } }) => palette.secondary.dark};
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;

  font-family: 'Roboto';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin: 0;
`;

export const HeadAct = styled.p`
  color: ${({ theme }) => theme.palette.primary.main};
  font-feature-settings:
    'clig' off,
    'liga' off;

  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  text-align: center;

  grid-area: 1 / 1 / 2 / span 4;

  position: relative;

  margin: 0;
`;

export const Close = styled(HighlightOffIcon)`
  position: absolute;
  right: -15px;
  top: -15px;

  cursor: pointer;

  path {
    fill: ${({ theme: { palette } }) => palette.secondary.gray};
  }
`;

export const IconDate = styled(EventIcon)`
  path {
    fill: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled.p`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;

  color: ${({ theme: { palette } }) => palette.secondary.main};
  font-feature-settings:
    'clig' off,
    'liga' off;

  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  svg path {
    fill: ${({ theme: { palette } }) => palette.secondary.main};
  }
`;

export const DateWrapper = styled(DesktopDatePicker)`
  &.MuiFormControl-root {
    width: 120px;
  }
`;
export const BtnWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding-top: 10px;
`;
