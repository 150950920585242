export enum ENUMLocalStorage {
  levelsIndexMethod = 'levelsIndexMethod',
  levelsIndexMethodExecute = 'levelsIndexMethodExecute',
  levelsIndexMethodPreview = 'levelsIndexMethodPreview',
  levelsHandbookMethodExecute = 'levelsHandbookMethodExecute',
  levelsHandbookMethodCalc = 'levelsHandbookMethodCalc',
  levelsBaseMethodExecute = 'levelsBaseMethodExecute',
  levelsBaseMethodCalc = 'levelsBaseMethodCalc'
}

export type LSlevelsIndexMethodProps =
  | { id: number; levels: number[] }[]
  | undefined;

export interface ILocalStorageValue {
  [ENUMLocalStorage.levelsIndexMethod]: LSlevelsIndexMethodProps;
  [ENUMLocalStorage.levelsIndexMethodExecute]: LSlevelsIndexMethodProps;
  [ENUMLocalStorage.levelsIndexMethodPreview]: LSlevelsIndexMethodProps;
  [ENUMLocalStorage.levelsBaseMethodExecute]: LSlevelsIndexMethodProps;
  [ENUMLocalStorage.levelsBaseMethodCalc]: LSlevelsIndexMethodProps;
  [ENUMLocalStorage.levelsHandbookMethodCalc]: LSlevelsIndexMethodProps;
  [ENUMLocalStorage.levelsHandbookMethodExecute]: LSlevelsIndexMethodProps;

  [key: string]: unknown;
}
