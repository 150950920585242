import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SyncIcon from '@mui/icons-material/Sync';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import {
  Box,
  Button,
  Link,
  List,
  ListItem,
  Stack,
  Typography
} from '@mui/material';
import { WSFile, WSMessageLSRUploadResult } from 'api/web-socket';
import { CircularProgressPercent } from 'components/CircularProgressPercent/CircularProgressPercent';
import Progress from 'components/Progress';
import { FieldHookConfig, useField } from 'formik';
import { useSnackbar } from 'notistack';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useUploadExcelMutation } from '../../api/calculations';
import { svgDownloadSecond } from '../../assets/SVG/SVG';
import { theme } from '../../styles/theme';
import { Drawer } from '../Drawer';
import { ListFiles } from './components/ListFiles';
import style from './EmptyProjectStart.module.scss';
import {
  DragWrapper,
  StyledErrorBox,
  StyledReloadButton,
  StyledUploadName,
  UploadText,
  UploadTitle,
  UploadWrapper
} from './style';

export type ListNameType = {
  name: string;
  idx: number;
};

export interface UploadFormProps {
  setFormData?: (formData: FormData | null) => void;
  setError: (value: boolean) => void;
  error: boolean;
  fieldProps?: Partial<FieldHookConfig<File[] | null>>;
  format?: Accept;
  mode?: 'xml' | 'xls';
  isLoading: boolean;
  isWS: boolean;
  setFilesData?: (filesData: WSFile[] | null) => void;
  wsProgress?: number;
  wsTime?: string;
  errorsMSG: WSMessageLSRUploadResult | null;
  filesData?: WSFile[] | null;
  isListFiles?: boolean;
  setIsListFiles?: (value: boolean) => void;
  handleOnReload?: () => void;
}

const UploadForm: React.FC<UploadFormProps> = ({
  errorsMSG,
  setFormData,
  setError,
  error,
  fieldProps,
  format,
  mode,
  isLoading,
  isWS,
  setFilesData,
  wsProgress,
  wsTime,
  filesData,
  isListFiles,
  setIsListFiles,
  handleOnReload
}) => {
  const { t } = useTranslation('files');
  const { enqueueSnackbar } = useSnackbar();

  const [uploadExcel, uploadExcelResponse] = useUploadExcelMutation();

  const [uploadExRes, setUploadExRes] = useState<any>({});

  const [showErrors, setShowErrors] = useState(false);

  const [{ value: formValue }, { touched }, { setValue, setTouched }] =
    useField({
      name: 'files',
      ...fieldProps
    });

  const nameFiles = useMemo(() => {
    return formValue?.map((item) => {
      if (formValue.length === 1) {
        return item.name;
      } else {
        return item.name + ', ';
      }
    });
  }, [formValue]);

  const onDropFile = useCallback(
    (files: File[]) => {
      setTouched(true);
      // console.log('files>>>', files);
      // console.log(
      //   'files size>>>',
      //   Math.round(
      //     files.reduce((acc, el) => (acc += el.size), 0) *
      //       1e-6 *
      //       Math.pow(10, 15)
      //   ) / Math.pow(10, 15)
      // );
      if (!isWS) {
        const formData = new FormData();
        if (mode === 'xls') {
          formData.append('file', files[0]);
        }
        if (mode === 'xml') {
          files.forEach((file, index) => {
            formData.append(`file${index}`, file); //
          });
        }
        if (files?.length) {
          setValue(files);
          setFormData?.(formData);
        }
      } else {
        const fileList: WSFile[] = [];
        //WARNING incompatible with xls mode
        if (mode === 'xml') {
          files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = ({ target }) => {
              const base64String = (target!.result as string)
                .replace('data:', '')
                .replace(/^.+,/, '');
              fileList.push({ b64Content: base64String, name: file.name });
            };
            reader.readAsDataURL(file);
          });
        }
        console.log('fileList', fileList);
        if (files?.length) {
          setValue(files);
          setFilesData?.(fileList);
        }
      }
    },
    [isWS, mode, setFilesData, setFormData, setTouched, setValue]
  );

  const [errorMsg, setErrorMsg] = useState<string>('');

  function onDropRejected(fileRejections: FileRejection[]) {
    setError(true);
    const errorMsg = fileRejections[0].errors[0].code;
    setErrorMsg(
      t(`common:errors.${errorMsg}`, { maxFiles: mode === 'xls' ? 1 : 500 })
    );
  }

  useEffect(() => {
    console.log('errorMsg: ', String(errorMsg));
  }, [errorMsg]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections
  } = useDropzone({
    onDrop: onDropFile,
    onDropRejected,
    accept: format!,
    maxFiles: mode === 'xls' ? 1 : 500,
    disabled: isLoading
  });

  const nameAcceptedFiles: ListNameType[] = useMemo(
    () =>
      (formValue || []).map((item, idx) => {
        return { name: item.name, idx: idx };
      }),
    [formValue]
  );

  const onDeleteFile = useCallback(
    (delIndex: number) => {
      if (!isWS) {
        const files = formValue!.filter((item, index) => {
          return index !== delIndex;
        });
        if (files.length === 0) setIsListFiles?.(false);
        setValue(files);
      } else {
        const files = formValue!.filter((item, index) => {
          return index !== delIndex;
        });
        if (files.length === 0) setIsListFiles?.(false);
        const filesD = filesData!.filter((i, idx) => {
          return idx !== delIndex;
        });
        console.log(files);
        console.log(filesD);
        setValue(files);
        setFilesData?.(filesD);
      }
    },
    [isWS, formValue, setIsListFiles, setValue, filesData, setFilesData]
  );

  useEffect(() => {
    setUploadExRes(uploadExcelResponse);
    setValue(null);
  }, [setValue, uploadExcelResponse]);

  const onReloadXMLFile = () => {
    setTouched(false);
    setIsListFiles?.(true);
    setError(false);
    handleOnReload?.();
  };

  const onReloadXLSFile = () => {
    setFormData?.(null);
    setTouched(false);
    setError(false);
    setValue(null);
    handleOnReload?.();
  };

  const onClickShowErrors = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setShowErrors(true);
  };

  return (
    <main className={style.main_awd}>
      <Fragment>
        <div className={style.awd_container}>
          <div className={style.awd_description}>
            {/* <Typography variant="body2">
              Вы можете добавить расчет с помощью exсel документа с уже подготовленными данными
            </Typography> */}
          </div>
          <div className={style.awd_loader}>
            {error ? (
              mode === 'xml' ? (
                isWS ? (
                  <StyledErrorBox>
                    <CloudDoneIcon
                      sx={{
                        maxHeight: '48px',
                        fontSize: '48px',
                        color: '#0288D1',
                        mb: '30px'
                      }}
                    />
                    <>
                      <Box sx={{ mt: 3 }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: '#ED6C02', mb: '6px' }}>
                          {errorsMSG ? (
                            <>
                              {errorsMSG.totalUploaded && (
                                <>
                                  Успешно загружено: {errorsMSG.totalUploaded}{' '}
                                  <br />
                                  <br />
                                </>
                              )}
                              Проверьте корректность не загруженных{' '}
                              {errorsMSG && errorsMSG.withErrors.length ? (
                                <a href={'#'} onClick={onClickShowErrors}>
                                  файлов
                                </a>
                              ) : (
                                'файлов'
                              )}
                            </>
                          ) : (
                            <>
                              {errorMsg
                                ? errorMsg
                                : 'Произошла непредвиденная ошибка, проверьте корректность файлов'}
                            </>
                          )}
                        </Typography>
                      </Box>
                      <Stack
                        direction="row"
                        spacing={1.5}
                        sx={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mt: '22px'
                        }}>
                        <StyledReloadButton
                          fullWidth
                          startIcon={<SyncIcon />}
                          onClick={onReloadXMLFile}
                          disableRipple>
                          {' '}
                          загрузить еще раз
                        </StyledReloadButton>
                      </Stack>
                    </>
                  </StyledErrorBox>
                ) : (
                  <StyledErrorBox>
                    <CloudDoneIcon
                      sx={{
                        maxHeight: '48px',
                        fontSize: '48px',
                        color: '#0288D1',
                        mb: '30px'
                      }}
                    />
                    <>
                      <Box>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: '#0288D1', mb: '6px' }}>
                          Успешно загруженно файлов: {formValue?.length || 0}
                        </Typography>
                        <StyledUploadName variant="body2">
                          {acceptedFiles?.map((item) => item.name + ', ')}
                        </StyledUploadName>
                      </Box>
                      <Box sx={{ mt: 3 }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: '#ED6C02', mb: '6px' }}>
                          Ошибка, эти файлы не соответствуют формату xml и не
                          будут добавлены:
                        </Typography>
                        <StyledUploadName variant="body2">
                          {fileRejections?.map((item) => item.file.name + ', ')}
                        </StyledUploadName>
                      </Box>
                      <Stack
                        direction="row"
                        spacing={1.5}
                        sx={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mt: '22px'
                        }}>
                        <StyledReloadButton
                          fullWidth
                          startIcon={<SyncIcon />}
                          onClick={onReloadXMLFile}
                          disableRipple>
                          {' '}
                          загрузить еще раз
                        </StyledReloadButton>
                      </Stack>
                    </>
                  </StyledErrorBox>
                )
              ) : (
                <StyledErrorBox>
                  <WarningAmberOutlinedIcon
                    sx={{
                      width: '51px',
                      height: '48px',
                      mt: '30px',
                      color: '#F46B6B'
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ mt: '30px', color: '#F46B6B' }}>
                    Произошла ошибка при загрузке файла!
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ mt: '6px', color: '#F46B6B' }}>
                    {errorMsg}
                  </Typography>
                  <>
                    <StyledReloadButton
                      sx={{ mt: '14px' }}
                      startIcon={<SyncIcon />}
                      onClick={onReloadXLSFile}
                      disableRipple>
                      загрузить еще раз
                    </StyledReloadButton>
                  </>
                </StyledErrorBox>
              )
            ) : isListFiles ? (
              <ListFiles
                listName={[...nameAcceptedFiles]}
                onDeleteFile={onDeleteFile}
                setIsListFiles={setIsListFiles}
              />
            ) : (
              <DragWrapper drag={isDragActive} {...getRootProps()}>
                <input {...getInputProps()} />
                <UploadWrapper
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  isPointer={true}>
                  {isLoading ? (
                    isWS ? (
                      <Box>
                        <CircularProgressPercent
                          thickness={3.6}
                          value={wsProgress!}
                          time={wsTime!}
                        />
                      </Box>
                    ) : (
                      <Progress />
                    )
                  ) : (
                    <>
                      {mode === 'xml' ? (
                        formValue &&
                        formValue?.length > 0 && (
                          <CloudDoneIcon
                            sx={{
                              fontSize: '48px',
                              color: '#0288D1',
                              mb: '30px'
                            }}
                          />
                        )
                      ) : (
                        <CloudUploadIcon
                          sx={{
                            fontSize: 48,
                            color: theme.palette.secondary.gray
                          }}
                        />
                      )}
                      {formValue?.length ? (
                        <>
                          {mode === 'xml' ? (
                            <>
                              <Typography
                                variant="subtitle2"
                                sx={{ color: '#0288D1' }}>
                                Успешно загруженно файлов: {formValue!.length}
                              </Typography>
                              <StyledUploadName variant="body2">
                                {nameFiles}
                              </StyledUploadName>
                            </>
                          ) : (
                            <>
                              <UploadTitle variant="body2">
                                Файл загружен
                              </UploadTitle>
                              <UploadText variant="body2">
                                {formValue[0].name}
                              </UploadText>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <UploadTitle variant="body2">
                            загрузить {mode}
                          </UploadTitle>
                          <UploadText variant="body2">
                            Чтобы загрузить документ, выберите его из папки на
                            компьютере или просто перетяните его в эту область.
                          </UploadText>
                        </>
                      )}
                    </>
                  )}
                </UploadWrapper>
              </DragWrapper>
            )}
          </div>

          <div className={style.awd_download}>
            {mode === 'xls' && (
              <>
                {svgDownloadSecond}
                <Link
                  style={{ marginLeft: '10px' }}
                  variant="buttonSmall"
                  href="https://dev.api.offer.pragma.info/examples/template.xlsx"
                  underline="none"
                  rel="noopener">
                  скачать форму отчета
                </Link>
              </>
            )}
          </div>
        </div>
      </Fragment>
      <Drawer
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        ModalProps={{
          keepMounted: true,
          disablePortal: true
        }}
        title={'Список файлов'}
        titleStartButton={
          <Button onClick={() => setShowErrors(false)} variant={'text'}>
            <ArrowBackIcon />
            Назад
          </Button>
        }
        open={showErrors}
        onClose={() => setShowErrors(false)}>
        <List>
          {errorsMSG?.withErrors?.map((item, key) => (
            <ListItem key={key}>
              <Box
                display={'grid'}
                gridTemplateColumns={'min-content 1fr'}
                maxWidth={'100%'}
                overflow={'hidden'}
                whiteSpace={'pre-wrap'}
                gap={'10px'}>
                <WarningAmberIcon color={'error'} />
                <Box
                  maxWidth={'100%'}
                  overflow={'hidden'}
                  display={'flex'}
                  flexDirection={'column'}>
                  <Typography typography={'subtitle2'}>{item.name}</Typography>
                  {item.msg && (
                    <Typography
                      maxWidth={'100%'}
                      overflow={'hidden'}
                      sx={{ wordBreak: 'break-word' }}
                      typography={'subtitle2'}
                      fontSize={'0.8em'}
                      color={'gray'}>
                      {item.msg}
                    </Typography>
                  )}
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </main>
  );
};
export default UploadForm;

export * from './UploadForm.constants';
