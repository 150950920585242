/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import styled from 'styled-components';

export const PinnedLevel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  height: 100%;
  width: 100%;
  color: #668fd2;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;

  svg path {
    fill: #668fd2;
  }
`;

export const Level = styled.div<{
  floatRight?: boolean;
  arrowClose: boolean;
  $haveClick?: boolean;
}>`
  cursor: ${({ $haveClick }) => ($haveClick ? 'pointer' : 'default')};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .arrow {
    margin: 7px;
    rotate: 0deg;
    transition: rotate linear 0.2s;
    ${({ arrowClose }) => arrowClose && '&:first-child {rotate: 180deg;}'}
  }
`;
