import {
  Checkbox,
  Divider,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography
} from '@mui/material';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  StyledEstimateRangeSelect,
  StyledEstimateRangeSelectItem,
  StyledEstimateRangeSelectPlaceholder
} from './EstimateRangeSelect.styles';
import { useGetPositionListQuery } from 'api/calculations';
import { useCalcId } from 'hooks/useCalcId';
import { BaseTextFieldProps } from '../../../../../../../../components/BaseTextField';
import { useFormContext, useWatch } from 'react-hook-form';
import { LoaderContext } from '../ParametersForm';
import { ComplicatedPositionTreeChapter } from '../../../../../../../../types';
import {
  GetCoefficientsResponse,
  Indexes,
  ToTotals
} from '../../../../../../../../api/params/params.types';

export const EstimateRangeSelect: FC<BaseTextFieldProps> = ({
  parentID,
  isUpdate = false,
  globalDisabled,
  index,
  parentIndex,
  closeCallback,
  currentValue,
  onChange,
  ...props
}): JSX.Element => {
  const calcID = useCalcId();
  const [value, setValue] = useState<string[]>(currentValue ?? []);
  const { data: dataFromServer = [] } = useGetPositionListQuery({ calcID });
  const { setValue: setFormValue, control } =
    useFormContext<GetCoefficientsResponse>();
  const {
    loading,
    check,
    addStr,
    disabledArray,
    disabledArrayServer,
    addStrServer,
    disabledGlobal
  } = useContext(LoaderContext);

  const isIndexesPage = useMemo(() => {
    return /(((created).+(indexes))|((indexes).+(chapters)))/.test(
      props.name || ''
    );
  }, [props.name]);

  /**
   * хранит список уже добавленных разделов
   * нужно для выключения пунктов в селекте выбора разделов
   */
  const disabledArr = useRef<string[]>([]);

  const chapterName = useMemo(() => {
    return isUpdate
      ? props.name?.replace(/^(\w+)\..+/, '$1')
      : props.name?.replace(/^\w+\.(\w+)\..+/, '$1') || '';
  }, [props.name]);

  /**
   * отвечает за выключение пункта "Для всех разделов"
   */
  const disabledCreatingGlobal = useRef<boolean>(false);

  const parentArr = useWatch({
    control,
    name: `created.${chapterName}.parent${parentIndex}` as
      | 'created.indexes.parent0'
      | 'created.toTotals.parent0'
  });
  const baseParent: Indexes[] | ToTotals[] = useWatch({
    name: `${chapterName}` as 'indexes' | 'toTotals',
    control
  });
  const currentObj = useWatch({
    control,
    name: isUpdate
      ? (`${chapterName}.${parentIndex}.chapters.${index}` as
          | 'indexes.0.chapters.0'
          | 'toTotals.0.chapters.0')
      : (`created.${chapterName}.parent${parentIndex}.${index}` as
          | 'created.indexes.parent0.0'
          | 'created.toTotals.parent0.0')
  });

  useEffect(() => {
    if (isIndexesPage && baseParent) {
      const parent = (baseParent as any).find(
        (item: Indexes | ToTotals) => item.fileID === parentID
      );

      if (parent && 'indexes' in parent) {
        disabledCreatingGlobal.current = !!parent.indexes.length;
      }
    }
  }, [
    chapterName,
    loading,
    value,
    parentArr,
    baseParent,
    currentObj,
    isIndexesPage
  ]);

  useEffect(() => {
    if (isIndexesPage && baseParent) {
      disabledArr.current =
        (baseParent as any)
          ?.find((item: Indexes | ToTotals) => item.fileID === parentID)
          ?.chapters.filter((_: any, i: number) => !isUpdate && i !== index)
          .flatMap((_: any) => _.chapterTitle) || [];
    }
  }, [disabledArray, baseParent, isIndexesPage]);

  const data: ComplicatedPositionTreeChapter[] = useMemo(() => {
    return dataFromServer.find((_) => _.id === parentID)?.children || [];
  }, [parentID, dataFromServer]);

  useEffect(() => {
    if (loading) {
      setValue([]);
    }
  }, [loading]);

  const changeSelectHandler = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const targetValue = e.target.value! as Array<string>;
      if (targetValue.includes('null')) {
        ['null', 'all'].forEach((t) => {
          const index = targetValue.findIndex((v) => v === t);
          if (index >= 0) targetValue.splice(index, 1);
        });
        if (targetValue.length) {
          const update = data
            .filter((item) => !targetValue.includes(item.title))
            .map((item) => item.title);
          setValue(update);
          // onChange?.({ target: { name: props.name!, value: update as any } } as any);
          return;
        } else {
          setValue([]);
          // onChange?.({ target: { name: props.name!, value: [] as any } } as any);
          return;
        }
      }
      if (targetValue.includes('all')) {
        const update = data.map((item) => item.title);
        setValue(update);
        // onChange?.({ target: { name: props.name!, value: update as any } } as any);
        if (data.length === value.length) {
          setValue([]);
          // onChange?.({ target: { name: props.name!, value: [] as any } } as any);
        } else {
          setValue(update);
          // onChange?.({ target: { name: props.name!, value: update as any } } as any);
        }
      } else {
        setValue(e.target.value as string[]);
        // onChange?.({ target: { name: props.name!, value: e.target.value as any } } as any);
      }
    },
    [value, data, disabledGlobal, parentIndex, currentValue]
  );

  const checkDisableAll = useCallback(
    (onNull: boolean = true, parentCheck: boolean = true) => {
      let flag = false;
      const parent = (baseParent as any)?.find(
        (item: Indexes | ToTotals) => item.fileID === parentID
      );
      if (
        (parent && parent.indexes && parent.indexes.length) ||
        (parent && parentCheck && parent.chapters && parent.chapters.length)
      ) {
        return true;
      }

      for (const key in disabledArray.current[`parent${parentIndex!}`]) {
        if (onNull) {
          if (
            disabledArray.current[`parent${parentIndex!}`][key][0] === 'null'
          ) {
            flag = true;
            break;
          }
        } else {
          if (disabledArray.current[`parent${parentIndex!}`][key].length) {
            flag = true;
            break;
          }
        }
      }
      return flag;
    },
    [disabledArray.current, parentIndex, baseParent]
  );

  /**
   * Эффект следит за массивом values и при изменении
   * расскладывает массив в строку вкладывая в форму через setFormValue
   */
  useEffect(() => {
    if (props.name && data?.length) {
      const update = data.length === value.length ? ['null'] : value;
      if (update.length > 1 && update[0] === 'null') update.splice(0, 1);
      if (isUpdate) {
        setFormValue(props.name as any, update, { shouldDirty: true });
        setFormValue(
          props.name.replace('chapterTitle', 'isEdit') as any,
          true,
          { shouldDirty: true }
        );
      } else {
        setFormValue(props.name as any, update);
      }
      if (isUpdate) {
        if (
          chapterName ===
          'indexes' /*&& (disabledArrayServer[`parent${parentIndex!}`] && disabledArrayServer[`parent${parentIndex!}`][index!])*/
        ) {
          addStrServer && addStrServer(parentIndex!, index!, update);
        }
      } else {
        if (
          chapterName ===
          'indexes' /*&& (disabledArray[`parent${parentIndex!}`] && disabledArray[`parent${parentIndex!}`][index!])*/
        ) {
          addStr && addStr(parentIndex!, index!, update);
        }
      }

      if (
        chapterName ===
        'indexes' /*&& /!*update[0] === "null"*!/ update.length*/
      ) {
        // console.log('update >>>', update);
        // console.log('update >>>', checkDisableAll(false));
        if (update.length) {
          disabledGlobal[`parent${parentIndex}`] = true;
        } else {
          disabledGlobal[`parent${parentIndex}`] = checkDisableAll(false);
        }
      }
    }
  }, [
    value,
    data,
    chapterName,
    isUpdate,
    props.name,
    disabledArrayServer,
    disabledArray
  ]);

  const isGlobal = useMemo(() => {
    return currentValue[0] === 'null';
  }, [currentValue]);

  useEffect(() => {
    if (isGlobal) {
      setValue(data.map((e) => e.title));
    }
  }, [isGlobal]);

  const selectLabels = useMemo(
    () =>
      data
        .filter((e) => currentObj?.chapterTitle?.includes(e.title))
        .map((e) => e.title)
        .join(' '),
    [data, currentObj?.chapterTitle]
  );

  const placeholder = useMemo(() => {
    return value.length === data.length || currentValue[0] === 'null'
      ? 'Глобальный коэффициент'
      : null;
  }, [value, data, currentValue]);

  const disabledMenuItem = useCallback(
    (str: string) => {
      return isIndexesPage
        ? checkDisableAll(true, false) ||
            // disabledArr.current.includes(str) ||
            (check && check(parentIndex!, index!, str, isUpdate))
        : false;
    },
    [
      isIndexesPage,
      checkDisableAll,
      disabledArr.current,
      check,
      parentIndex,
      index,
      isUpdate,
      disabledArray.current
    ]
  );

  return (
    <StyledEstimateRangeSelect
      {...props}
      SelectProps={{
        renderValue: () =>
          currentValue.length > 0 ? (
            placeholder || selectLabels
          ) : (
            <StyledEstimateRangeSelectPlaceholder>
              Выберите раздел или диапазон смет
            </StyledEstimateRangeSelectPlaceholder>
          ),
        MenuProps: {
          ...(closeCallback && {
            onClose: () => {
              closeCallback && closeCallback();
            }
          }),
          sx: {
            maxHeight: 500
          }
        },
        displayEmpty: true,
        multiple: true,
        value: currentValue[0] === 'null' ? value : currentValue,
        onChange: (e) => {
          changeSelectHandler(e);
        }
      }}
      select
      fullWidth
      value={currentValue.length}
      onClear={() => {
        if (isIndexesPage && data?.length === currentValue.length) {
          disabledGlobal[`parent${parentIndex}`] = false;
        }
        setValue([]);
      }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={2}
        py={1}
        spacing={4}
        sx={{ pointerEvents: 'none' }}>
        <Typography variant="body1" color="secondary.gray">
          Выберите раздел сметы
        </Typography>
        <Typography variant="body2" color="info.main" lineHeight="none">
          Выбрано: {isGlobal ? data.length : currentValue.length}
        </Typography>
      </Stack>
      <Divider />
      <MenuItem
        style={isUpdate ? { padding: '10px 17px' } : {}}
        disabled={
          isIndexesPage
            ? globalDisabled
              ? currentValue[0] !== 'null'
              : globalDisabled
            : false
        }
        value={'all'}>
        <Checkbox
          checked={
            (currentValue[0] === 'null' ? value : currentValue).length ===
            data.length
          }
        />
        Для всех разделов сметы
      </MenuItem>

      {data?.map((es: any, i) => {
        return (
          <StyledEstimateRangeSelectItem
            disabled={disabledMenuItem(es.title)}
            key={i}
            value={es.title}>
            <Checkbox
              disabled={disabledMenuItem(es.title)}
              checked={
                (currentValue[0] === 'null' ? value : currentValue).findIndex(
                  (e: string) => e === es.title || e === 'all'
                ) > -1
              }
            />
            <Stack
              direction="row"
              spacing={0.5}
              sx={isUpdate ? { padding: '8px 0' } : {}}>
              <Typography>Раздел:</Typography>
              <Typography>{es.title}</Typography>
            </Stack>
          </StyledEstimateRangeSelectItem>
        );
      })}
    </StyledEstimateRangeSelect>
  );
};
