import { LinearProgress, linearProgressClasses, Box } from '@mui/material';
import styled from 'styled-components';

export const StyledPopper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 30px;
  background: #fff;
  border-radius: 8px;
  box-shadow:
    0px 24px 48px 0px #10182824,
    0px -8px 24px 0px #10182814;
`;
export const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  flexGrow: 1,
  height: '11px',
  borderRadius: '4px',
  border: '0.5px solid #8CBBE8',
  backgroundColor: theme.palette.bg.gray,

  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: '#8CBBE8'
  }
}));
