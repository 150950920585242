import React from 'react';
import { IconButton, Radio } from '@mui/material';
import { StyledLevel, StyledLevelTd, StyledTypography } from './styles';
import { PositionVOR, UnitType } from '../../../api/calculations/types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveIcon from '@mui/icons-material/Remove';
import { ReactComponent as SvgNotifIcon } from '../../../assets/icons/notifIcon.svg';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import Tooltip from '../../../components/Tooltip';
import { IVorPosition } from '../../../api/calculationDictionary';

export interface RowTableProps {
  level: PositionVOR | IVorPosition;
  mode?: 'notCheckbox';
  keyWorkId: number | null;
  handleChangeKeyWork: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDelRow: (delId: PositionVOR | IVorPosition, isEdit?: boolean) => void;
  isHandbook: boolean;
  isEdit: boolean;
}

const isKilogramOrTonn = (
  selectUnit: UnitType | undefined,
  unitType: UnitType | null
) => {
  if (selectUnit === 'kilogram' || selectUnit === 'tonn') {
    if (unitType === 'kilogram') {
      return false;
    }
    if (unitType === 'tonn') {
      return false;
    }
  }

  return unitType !== selectUnit;
};

export const RowTable: React.FC<RowTableProps> = ({
  level,
  isHandbook,
  isEdit,
  mode,
  handleChangeKeyWork,
  handleDelRow
}) => {
  const { changeableFields: fieldState } = useSelector(
    (state: RootState) => state.vor.table
  );
  const {
    // number,
    // lsr,
    // chapter,
    // header,
    code,
    title,
    unit,
    // unitType,
    quantity
    // constructionEffort,
    // mechanicsEffort,
    // totalWorkCost,
    // numbering,
  } = level;

  const { target, variant } = useSelector(
    (state: RootState) => state.vor.table
  );

  const outQuantity = quantity
    ? quantity.toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 8
      })
    : null;

  const outConstructionEffort =
    'constructionEffort' in level && !!level?.constructionEffort
      ? level.constructionEffort?.toLocaleString('ru-RU', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : 'tz' in level && !!level.tz
        ? level.tz?.toLocaleString('ru-RU', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        : null;

  const outMechanicsEffort =
    'mechanicsEffort' in level && !!level.mechanicsEffort
      ? level.mechanicsEffort.toLocaleString('ru-RU', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : 'tzm' in level && !!level.tzm
        ? level.tzm.toLocaleString('ru-RU', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        : null;

  const outTotalWorkCost =
    'totalWorkCost' in level && !!level.totalWorkCost
      ? level.totalWorkCost.toLocaleString('ru-RU', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : 'total' in level && !!level.total
        ? level.total.toLocaleString('ru-RU', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        : null;

  return (
    <StyledLevel
      $isHandbook={isHandbook}
      direction="row"
      className="Level-grid">
      <StyledLevelTd
        style={{ paddingLeft: '9px', justifyContent: 'flex-start' }}>
        {!mode && (
          <Radio
            checked={target?.id === level.id}
            onChange={handleChangeKeyWork}
            value={level.id}
            checkedIcon={<CheckCircleIcon />}
          />
        )}
      </StyledLevelTd>

      <StyledLevelTd>
        <StyledTypography variant="body2">
          {variant === 'grouped'
            ? 'numbering' in level
              ? level.numbering
              : level.num
            : 'numbering' in level
              ? level.numbering
              : level.num}
          {/* № п/п */}
        </StyledTypography>
      </StyledLevelTd>

      <StyledLevelTd>
        <Tooltip
          title={
            'dynamicItems' in level
              ? (level as IVorPosition)?.dynamicItems?.[0]?.title || ''
              : (level as PositionVOR)?.lsr || ''
          }>
          <StyledTypography
            variant="body2"
            style={{ textAlign: 'start', width: '100%' }}>
            {'dynamicItems' in level
              ? (level as IVorPosition)?.dynamicItems?.[0]?.title || ''
              : (level as PositionVOR)?.lsr || ''}
          </StyledTypography>
        </Tooltip>
      </StyledLevelTd>

      <StyledLevelTd sx={{ fontSize: 14 }}>
        {/*{number === 'null' ? '' : number}*/}
        <Tooltip
          title={
            'dynamicItems' in level
              ? (level as IVorPosition)?.dynamicItems?.[1]?.title || ''
              : (level as PositionVOR)?.number === 'null'
                ? ''
                : (level as PositionVOR)?.number || ''
          }>
          <StyledTypography
            variant="body2"
            style={{
              textAlign: 'dynamicItems' in level ? 'left' : 'center',
              width: '100%'
            }}>
            {'dynamicItems' in level
              ? (level as IVorPosition)?.dynamicItems?.[1]?.title || ''
              : (level as PositionVOR)?.number === 'null'
                ? ''
                : (level as PositionVOR)?.number || ''}
          </StyledTypography>
          {/* № по ЛСР */}
        </Tooltip>
      </StyledLevelTd>

      <StyledLevelTd>
        <Tooltip
          title={
            'dynamicItems' in level
              ? (level as IVorPosition)?.dynamicItems?.[2]?.title || ''
              : (level as PositionVOR)?.chapter || ''
          }>
          <StyledTypography
            variant="body2"
            style={{
              textAlign: 'start',
              width: '100%'
            }}>
            {'dynamicItems' in level
              ? (level as IVorPosition)?.dynamicItems?.[2]?.title || ''
              : (level as PositionVOR)?.chapter || ''}
            {/*{chapter} /!* Раздел *!/*/}
          </StyledTypography>
        </Tooltip>
      </StyledLevelTd>
      {!isHandbook && (
        <StyledLevelTd>
          <Tooltip title={(level as PositionVOR)?.header ?? ''}>
            <StyledTypography
              variant="body2"
              style={{ textAlign: 'start', width: '100%' }}>
              {(level as PositionVOR)?.header}
            </StyledTypography>
          </Tooltip>
        </StyledLevelTd>
      )}

      <StyledLevelTd>
        <Tooltip title={code ?? ''}>
          <StyledTypography variant="body2">
            {code} {/* Шифр расценки */}
          </StyledTypography>
        </Tooltip>
      </StyledLevelTd>

      <StyledLevelTd>
        <Tooltip title={title ?? ''}>
          <StyledTypography
            variant="body2"
            style={{
              wordWrap: 'break-word',
              textAlign: 'start',
              width: '100%'
            }}>
            {title}
          </StyledTypography>
        </Tooltip>
      </StyledLevelTd>

      <StyledLevelTd style={{ position: 'relative' }}>
        <Tooltip title={unit ?? ''}>
          <StyledTypography variant="body2">{unit}</StyledTypography>
        </Tooltip>
        {!mode &&
          fieldState.selectUnit.value &&
          isKilogramOrTonn(
            fieldState.selectUnit.value as UnitType,
            'unitType' in level
              ? (level.unitType as any)
              : ((level as IVorPosition).unit as any)
          ) && (
            <SvgNotifIcon
              width={13}
              height={13}
              style={{ position: 'absolute', right: '0px' }}
            />
          )}
      </StyledLevelTd>

      <StyledLevelTd>
        <StyledTypography variant="body2">{outQuantity}</StyledTypography>
      </StyledLevelTd>

      <StyledLevelTd>
        <Tooltip title={outConstructionEffort ?? ''}>
          <StyledTypography
            variant="body2"
            style={{ textAlign: 'end', width: '100%' }}>
            {outConstructionEffort}{' '}
            {/*ТЗ ОР, чел-ч Трудозатраты на строительство */}
          </StyledTypography>
        </Tooltip>
      </StyledLevelTd>
      <StyledLevelTd>
        <Tooltip title={outMechanicsEffort ?? ''}>
          <StyledTypography
            variant="body2"
            style={{ textAlign: 'end', width: '100%' }}>
            {outMechanicsEffort}{' '}
            {/*ТЗМ, чел-ч Трудозатраты механизаторов, человеко-часы */}
          </StyledTypography>
        </Tooltip>
      </StyledLevelTd>

      <StyledLevelTd>
        <Tooltip title={outTotalWorkCost ?? ''}>
          <StyledTypography
            variant="body2"
            style={{ textAlign: 'end', width: '100%' }}>
            {outTotalWorkCost} {/* {'Всего ст-ть'} */}
          </StyledTypography>
        </Tooltip>
      </StyledLevelTd>

      <StyledLevelTd>
        <IconButton
          sx={{
            '&.MuiButtonBase-root': {
              background: 'rgba(255, 177, 177, 0.15)',
              borderRadius: '20px',
              width: '20px',
              height: '20px',
              '&:hover': {
                width: '30px',
                height: '30px'
              }
            }
          }}
          color="error"
          onClick={() => handleDelRow(level, isEdit)}>
          <RemoveIcon
            sx={{
              width: '20px',
              height: '20px'
            }}
          />
        </IconButton>
      </StyledLevelTd>
    </StyledLevel>
  );
};
