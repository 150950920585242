import { Stack, Tab, Tabs } from '@mui/material';
import { PARAMETERS_TABS } from './parameters-dialog.execution.constants';
import React from 'react';
import { TParametersTabs } from './parameters-dialog.execution.types';

type Props = {
  onChange: (
    _: React.SyntheticEvent<Element, Event>,
    t: TParametersTabs
  ) => void;
  tab: TParametersTabs;
  disabled: boolean;
};

export const ParametersDialogTabs: React.FC<Props> = ({
  onChange,
  tab,
  disabled
}) => {
  return (
    <Stack direction="row" p="10px 30px" flexGrow={1}>
      <Stack
        spacing={5}
        flexGrow={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <Tabs value={tab} onChange={onChange}>
          {PARAMETERS_TABS.map((value, index) => (
            <Tab
              disabled={disabled}
              key={index}
              value={value.value}
              label={value.label}
            />
          ))}
        </Tabs>
      </Stack>
    </Stack>
  );
};
