import {
  StyledBoxHeader,
  StyledBoxWrapper,
  StyledTitle
} from 'pages/Dashboard/Dashboard.styles';
import { getFormatDate } from 'utils/formatDate';
import { Person, formatName } from 'utils/formatName';
import { StyledContent, StyledImg, StyledNotImage } from './ProjectCard.styles';
import Info from './components/Info';
import { FC } from 'react';
import { ProjectCardProps } from './ProjectCard.types';

export const ProjectCard: FC<ProjectCardProps> = ({ project }) => {
  const customer = project.responsible.find(
    (person) => person.type === 'customer'
  );
  const contractor = project.responsible.find(
    (person) => person.type === 'contractor'
  );
  const humanCustomer = customer?.userBind
    ? formatName(customer.userBind as Person, 'ФИО')
    : (customer?.userName ?? '');
  const humanContractor = contractor?.userBind
    ? formatName(contractor.userBind as Person, 'ФИО')
    : (customer?.userName ?? '');

  return (
    <StyledBoxWrapper sx={{ display: 'flex' }} height="470px">
      <StyledImg>
        {project.images.length ? (
          <img src={project.images[0].path} alt="image" />
        ) : (
          <StyledNotImage>Изображение отсутствует</StyledNotImage>
        )}
      </StyledImg>
      <StyledContent>
        <StyledBoxHeader>
          <StyledTitle>{project.project.shortName}</StyledTitle>
        </StyledBoxHeader>
        <Info title="Полное наименование:" text={project.project.fullName} />
        <Info title="Адрес:" text={project.addressData?.address} />
        <Info title="Вид проекта" text={project.project.finType} />
        <Info title="Статус проекта:" text={project.project.status} />
        {project.project.startDate ? (
          <Info
            title="Сроки реализации"
            text={`${getFormatDate({ date: project.project.startDate })} - ${getFormatDate(
              {
                date: project.project.endDate
              }
            )}`}
          />
        ) : null}
        <Info title="Руководитель" text={humanCustomer} />
        <Info title="Подрядчик" text={humanContractor} />
      </StyledContent>
    </StyledBoxWrapper>
  );
};
