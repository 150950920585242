import { Box, Checkbox, Stack, Typography } from '@mui/material';
import { FileTypeChip } from 'pages/Calculations/components/FileTypeChip';
import { useField, useFormikContext } from 'formik';
import { CalculationFormData } from 'pages/Calculations/components/CalculationDrawerForm/CalculationDrawerForm.types';
import { FC } from 'react';
import { getFormatDate } from 'utils/formatDate';
import { getPersonInitials } from 'utils/getPersonInitials';
import {
  StyledFileItem,
  StyledFileName,
  StyledFileItemWrapper
} from './FileItem.styles';
import { FileItemProps } from './FileItem.types';

export const FileItem: FC<FileItemProps> = ({
  className,
  file,
  field: formikKey
}): JSX.Element => {
  const { firstName, lastName, middleName } = file.uploader;
  const { values } = useFormikContext<CalculationFormData>();
  const [field] = useField(formikKey);

  return (
    <StyledFileItemWrapper>
      <StyledFileItem className={className}>
        <Stack direction="row" alignItems="center">
          {/* это из-за кринж formika  */}
          <Checkbox
            {...field}
            value={file.id}
            //@ts-ignore
            checked={values?.[formikKey]?.includes(file.id.toString())}
          />
          <FileTypeChip type={file.type} />
          <Box px={1} overflow="hidden">
            <StyledFileName variant="body1">{file.name}</StyledFileName>
            <Typography variant="body2">
              {`${getFormatDate({ date: file.uploadedAt, withTime: true })}, ${getPersonInitials(
                {
                  firstName: firstName ?? '',
                  lastName: lastName ?? '',
                  middleName
                }
              )}`}
            </Typography>
          </Box>
        </Stack>
      </StyledFileItem>
    </StyledFileItemWrapper>
  );
};
