import { AgGridReact } from 'ag-grid-react';
import { ColumnResizedEvent } from 'ag-grid-community';

export function detectLastRowInView(agGrid: AgGridReact) {
  const renderedNodes = agGrid.api.getRenderedNodes();
  if (!renderedNodes) return false;

  // Detect last row in view
  const lastVisibleRow = renderedNodes[renderedNodes.length - 1];
  if (!lastVisibleRow) return false;

  const gridBody = document.querySelector('.ag-body-viewport') as HTMLElement;
  if (!gridBody) return false;

  const lastRowElement = gridBody.querySelector(
    `[row-index="${lastVisibleRow.rowIndex}"]`
  );
  if (!lastRowElement) return false;

  const rect = lastRowElement.getBoundingClientRect();
  const gridRect = gridBody.getBoundingClientRect();
  return rect.bottom <= gridRect.bottom;
}

export function agGridColumnResized(event: ColumnResizedEvent) {
  if (event.finished && event.column && event.source === 'uiColumnResized') {
    const columnId = event.column.getColId();
    const width = event.column.getActualWidth();

    return { columnId, width };
  }

  return null;
}
