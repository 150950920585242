import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import React, { memo, ReactElement, ReactNode, SyntheticEvent } from 'react';
import { theme } from 'styles/theme';

export interface IConfirmProps {
  title?: string | ReactNode;
  body?: string | ReactElement<{ children: string }>;
  confirmButtonText?: string;
  denyButtonText?: string;
  isRedButton?: boolean;
  abortHandler?: () => void;
  open: boolean;
  onClose: (confirm: boolean) => void;
}

export const ConfirmDialog: React.FC<IConfirmProps> = memo((props) => {
  const { abortHandler, onClose, open } = props;

  return (
    <Dialog
      open={open}
      onClose={(e: SyntheticEvent, reason) => {
        if (reason === 'backdropClick') {
          e.stopPropagation();
        }
        onClose(false);
        abortHandler?.();
      }}
      PaperProps={{
        style: {
          width: 400,
          textAlign: 'center'
        }
      }}>
      <DialogTitle
        variant="subtitle1"
        justifyContent="center"
        sx={{ pt: 3.75, pb: 1.5 }}>
        Подтвердить закрытие формы?
      </DialogTitle>
      <DialogContent sx={{ wordBreak: 'break-word' }}>
        <Typography variant="body2" color={theme.palette.text.dark}>
          Форма будет закрыта, а все введенные данные безвозвратно утеряны.
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', paddingBottom: 30 }}>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onClose(true);
          }}
          autoFocus
          color={'success'}
          fullWidth
          style={{
            maxWidth: 165
          }}>
          Да
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onClose(false);
            abortHandler?.();
          }}
          fullWidth
          style={{ maxWidth: 165 }}>
          отменить
        </Button>
      </DialogActions>
    </Dialog>
  );
});
ConfirmDialog.displayName = 'ConfirmDialog';
