import { useCalcId } from 'hooks/useCalcId';
import { useProjectId } from 'hooks/useProjectId';
import useSearchParam from 'hooks/useSearchParams';
import { createContext, Dispatch, SetStateAction, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  INDEX_METHOD_PARAM_FILEID,
  INDEX_METHOD_PARAM_MODE,
  INDEX_METHOD_PARAM_TAB,
  INDEX_METHOD_PARAM_TYPE,
  IndexMethodSearchParamsFileID,
  IndexMethodSearchParamsMode,
  IndexMethodSearchParamsTab,
  IndexMethodSearchParamsType
} from '.';
import { useGetCalculationByIdQuery } from 'api/calculations';

export const useIndexMethodRest = () => {
  const calcID = useCalcId();
  const projectID = useProjectId();
  const [mode] = useSearchParam<IndexMethodSearchParamsMode>(
    INDEX_METHOD_PARAM_MODE
  );
  const [fileID] = useSearchParam<IndexMethodSearchParamsFileID>(
    INDEX_METHOD_PARAM_FILEID
  );
  const [type] = useSearchParam<IndexMethodSearchParamsType>(
    INDEX_METHOD_PARAM_TYPE
  );
  const [tab, setTab] = useSearchParam<IndexMethodSearchParamsTab>(
    INDEX_METHOD_PARAM_TAB
  );
  const { data: calculation } = useGetCalculationByIdQuery(
    {
      projectID,
      calcID
    },
    {
      skip: mode === 'viewer'
    }
  );

  return { calcID, projectID, mode, type, tab, setTab, calculation, fileID };
};

export const useNavigateIndexMethod = () => {
  const navigate = useNavigate();

  const handleNavigate = useCallback(
    (props: {
      type: IndexMethodSearchParamsType;
      mode: IndexMethodSearchParamsMode;
      tab: IndexMethodSearchParamsTab;
      projectID: number | string;
      calcID: number | string;
      fileID?: number | string;
    }) =>
      navigate(
        `/projects/${props.projectID}/calculation/${props.calcID}/index-method?mode=${props.mode}&type=${props.type}&tab=${props.tab}${props.fileID ? `&fileID=${props.fileID}` : ''}`
      ),
    [navigate]
  );

  return { handleNavigate };
};

interface ITableRowsContext {
  selectedRowsIds: number[];
  setSelectedRowsIds: Dispatch<SetStateAction<number[]>>;
}

export const TableRowsContext = createContext<ITableRowsContext>({
  selectedRowsIds: [],
  setSelectedRowsIds: () => false
});
