import { Body, Head } from './components';
import { StyledWrapper } from './Table.styled';

const Table: React.FC = () => {
  return (
    <StyledWrapper>
      <Head />
      <Body />
    </StyledWrapper>
  );
};

export default Table;
