import {
  useDeleteEstimateFromCalculationMutation,
  useDeleteRowsFromCalculationMutation
} from 'api/calculations';
import { Dispatch, SetStateAction } from 'react';
import { useCalcId } from './useCalcId';
import { useProjectId } from './useProjectId';
import useConfirmDialog from './useConfirmDialog';
import { useMutationHandlers } from './useMutationHandlers';
import { useSnackbar } from 'notistack';

export interface IUseDeleteEstimateInCalculationProps {
  setDeletedIds?: Dispatch<SetStateAction<number[]>>;
  deletedIds: number[];
  deletedDataType: 'rows' | 'estimate';
  onDelete: () => void;
}

export const useDeleteEstimateInCalculation = ({
  setDeletedIds,
  deletedIds,
  deletedDataType,
  onDelete
}: IUseDeleteEstimateInCalculationProps) => {
  const calcID = useCalcId();
  const projectID = useProjectId();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteRows, deleteRowsResponse] =
    useDeleteRowsFromCalculationMutation();
  const [deleteEstimate, deleteEstimateResponse] =
    useDeleteEstimateFromCalculationMutation();

  const handleDelete = (force = false) => {
    console.log(force);
    if (deletedDataType === 'estimate') {
      deleteEstimate({
        projectID,
        calcID,
        body: { ids: deletedIds },
        params: { force }
      });
    } else {
      deleteRows({
        projectID,
        calcID,
        body: { ids: deletedIds },
        params: { force }
      });
    }
  };

  const handleConfirmDelete = (confirm: boolean) => {
    if (confirm) {
      handleDelete();
    }
  };

  const { ConfirmDialog: ConfirmDialogDelete, openConfirm: openConfirmDelete } =
    useConfirmDialog({
      title:
        deletedDataType === 'estimate'
          ? 'Подтвердите удаление cметы'
          : 'Подтвердите удаление расценок',
      body:
        deletedDataType === 'estimate' ? (
          'Выбранная Вами смета удалится безвозвратно.'
        ) : (
          <>
            Выбранные Вами расценки удалятся
            <br /> безвозвратно.
          </>
        ),
      handleConfirm: handleConfirmDelete,
      confirmButtonText: 'Удалить',
      denyButtonText: 'Отменить',
      isRedButton: true
    });

  const handleConfirmDeleteRowsWithExecution = (confirm: boolean) => {
    if (confirm) {
      handleDelete(true);
    }
  };

  const {
    ConfirmDialog: ConfirmDialogDeleteRowsWithExecution,
    openConfirm: openConfirmDeleteRowsWithExecution
  } = useConfirmDialog({
    title: 'Подтвердите удаление расценок',
    body: 'Расценки, содержащиеся в акте выполненных работ, будут исключены из расчета, но не удалены полностью.',
    confirmButtonText: 'Да',
    denyButtonText: 'Отменить',
    handleConfirm: handleConfirmDeleteRowsWithExecution
  });

  useMutationHandlers(
    deleteRowsResponse,
    () => {
      setDeletedIds && setDeletedIds([]);
      onDelete();
    },
    (error) => {
      if ('data' in error) {
        if (
          error.data === 'cmpl.delete.cannot_delete_row_used_as_target_in_vor'
        ) {
          enqueueSnackbar({
            variant: 'error',
            message:
              'Расценка, выбранная ключевой работой не может быть удалена.',
            autoHideDuration: 5000
          });
        } else if (error.data === 'cmpl.delete.has_execution') {
          openConfirmDeleteRowsWithExecution();
        } else {
          enqueueSnackbar({
            variant: 'error',
            message: 'Ошибка в обработке запроса',
            autoHideDuration: 5000
          });
        }
      }
    }
  );

  useMutationHandlers(
    deleteEstimateResponse,
    () => {
      setDeletedIds && setDeletedIds([]);
      onDelete();
    },
    (error) => {
      if ('data' in error) {
        if (
          error.data === 'cmpl.delete.cannot_delete_row_used_as_target_in_vor'
        ) {
          enqueueSnackbar({
            variant: 'error',
            message:
              'Расценка, выбранная ключевой работой не может быть удалена.',
            autoHideDuration: 5000
          });
        } else if (error.data === 'cmpl.delete.has_execution') {
          openConfirmDeleteRowsWithExecution();
        }
      }
    }
  );

  return {
    ConfirmDialogDelete,
    openConfirmDelete,
    ConfirmDialogDeleteRowsWithExecution,
    deleteResponse:
      deletedDataType === 'estimate'
        ? deleteEstimateResponse
        : deleteRowsResponse
  };
};
