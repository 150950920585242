import {
  BasePricesItem,
  BasicLevel,
  BasicLevelType,
  BasicRowType
} from './types';
import {
  ExecutionCalculationData,
  ExecutionCalculationRimData,
  GetExecutionCalculationData,
  GetExecutionCalculationRimData,
  PartExecution
} from '../../types';

export function changedCreateBasicLevel(
  state: BasicLevelType[],
  current: BasicLevel
) {
  if (current.parent === null) {
    return state.push({ ...current, children: [] });
  }
  state.forEach((elem) => {
    if (elem.type === 'level') {
      if (elem.id === current.parent) {
        return elem.children.push({ ...current, children: [] });
      }
      return changedCreateBasicLevel(elem.children, current);
    }
  });
}

export function changedCreateComplexLevel(
  state: BasePricesItem[],
  current: BasePricesItem
) {
  if (current.parent === null) {
    return state.push({ ...current, children: [] });
  }
  state.forEach((elem) => {
    if (elem.type === 'level') {
      if (elem.id === current.parent) {
        return elem.children.push({ ...current, children: [] });
      }
      return changedCreateComplexLevel(elem.children, current);
    }
  });
}

export function changedCreateBasicRow(
  state: BasicLevelType[],
  current: BasicRowType
) {
  state.forEach((elem, index) => {
    if (elem.type === 'row') return elem;

    if (elem.id === current.parent) {
      elem.children.push({ ...current });
    }
    return changedCreateBasicRow(elem.children, current);
  });
}

export function changedCreateComplexRow(
  state: BasePricesItem[],
  current: BasePricesItem
) {
  state.forEach((elem, index) => {
    if (elem.type === 'row') return elem;

    if (elem.id === current.parent) {
      elem.children.push({ ...current, children: [] });
    }
    return changedCreateComplexRow(elem.children, current);
  });
}

export function changedCopyBasicRow(
  state: BasicLevelType[],
  current: BasicRowType
) {
  if (current.parent === null) {
    return state.push(current);
  }
  state.forEach((elem) => {
    if (elem.type === 'level') {
      if (elem.id === current.parent) {
        return elem.children.push({
          ...current
        });
      }
      return changedCopyBasicRow(elem.children, current);
    }
  });
}

export function changedCopyComplexRow(
  state: BasePricesItem[],
  current: BasePricesItem
) {
  if (current.parent === null) {
    return state.push(current);
  }
  state.forEach((elem) => {
    if (elem.type === 'level') {
      if (elem.id === current.parent) {
        return elem.children.push({
          ...current
        });
      }
      return changedCopyComplexRow(elem.children, current);
    }
  });
}

export function changedEditRow(arr: BasicLevelType[], current: BasicRowType) {
  arr.forEach((elem, index) => {
    if (elem.id === current.id) {
      arr[index] = Object.assign({ ...elem }, { ...current });
    }
    if (elem.type === 'level') return changedEditRow(elem.children, current);
  });
}

export function changedComplexEditRow(
  arr: BasePricesItem[],
  current: BasePricesItem
) {
  arr.forEach((elem, index) => {
    if (elem.id === current.id) {
      arr[index] = Object.assign({ ...elem }, { ...current });
    }
    if (elem.type === 'level')
      return changedComplexEditRow(elem.children, current);
  });
}

export function changedDeleteRow(
  state: BasicLevelType[],
  current: BasicRowType
) {
  state.forEach((elem) => {
    if (elem.type === 'level') {
      if (elem.id === current.parent) {
        const deleteLevelindex = elem.children.findIndex((elem) => {
          return elem.id === current.id;
        });
        elem.children.splice(deleteLevelindex, 1);
      }
    }
    if (elem.id === current.id) {
      const deleteLevelindex = state.findIndex((elem) => {
        return elem.id === current.id;
      });
      state.splice(deleteLevelindex, 1);
    }

    if (elem.type === 'level') return changedDeleteRow(elem.children, current);
  });
}

export function changedSumBasicRow(
  state: BasicLevelType[],
  changed: BasicRowType[]
) {
  state.forEach((elem) => {
    if (elem.type === 'row') return elem;

    const findedRow = changed.find((row) => row.id === elem.id);
    if (findedRow) {
      elem.price = findedRow.price;
    }
    return changedSumBasicRow(elem.children, changed);
  });
}

export function changedComplexSumRow(
  state: BasePricesItem[],
  changed: BasePricesItem[]
) {
  state.forEach((elem, index) => {
    if (elem.type === 'row') return elem;
    const findedRow = changed.find((row) => row.id === elem.id);
    if (findedRow) {
      Object.assign(elem, { ...findedRow });
    }
    return changedComplexSumRow(elem.children, changed);
  });
}

export function transformGetExecutionBimRes(resp: GetExecutionCalculationData) {
  const base: ExecutionCalculationData = {
    id: -1,
    type: 'sum',
    number: 1,
    lsr: '',
    chapter: null,
    header: null,
    children: [],
    code: null,
    subtype: null,
    title: '',
    unit: '',
    parts: [
      {
        actID: null,
        type: 'total',
        quantity: null,
        startDate: null,
        endDate: null,
        base: {
          materialsPrice: null,
          equipmentPrice: null,
          constructionEffort: null,
          workersSalary: null,
          mechanicsEffort: null,
          mimExploitation: null,
          mechanicsSalary: null,
          hp: null,
          overheads: null,
          sp: null,
          estimatedProfit: null,
          totalWorkCost: null,
          workCostInRubles: null,
          dynamicRows: []
        },
        curr: {
          materialsPrice: null,
          equipmentPrice: null,
          constructionEffort: null,
          workersSalary: null,
          mechanicsEffort: null,
          mimExploitation: null,
          mechanicsSalary: null,
          hp: null,
          overheads: null,
          sp: null,
          estimatedProfit: null,
          totalWorkCost: null,
          workCostInRubles: null,
          dynamicRows: []
        }
      },
      {
        actID: null,
        type: 'executed',
        quantity: null,
        startDate: null,
        endDate: null,
        base: {
          materialsPrice: null,
          equipmentPrice: null,
          constructionEffort: null,
          workersSalary: null,
          mechanicsEffort: null,
          mimExploitation: null,
          mechanicsSalary: null,
          hp: null,
          overheads: null,
          sp: null,
          estimatedProfit: null,
          totalWorkCost: null,
          workCostInRubles: null,
          dynamicRows: []
        },
        curr: {
          materialsPrice: null,
          equipmentPrice: null,
          constructionEffort: null,
          workersSalary: null,
          mechanicsEffort: null,
          mimExploitation: null,
          mechanicsSalary: null,
          hp: null,
          overheads: null,
          sp: null,
          estimatedProfit: null,
          totalWorkCost: null,
          workCostInRubles: null,
          dynamicRows: []
        }
      },
      {
        actID: null,
        type: 'rest',
        quantity: null,
        startDate: null,
        endDate: null,
        base: {
          materialsPrice: null,
          equipmentPrice: null,
          constructionEffort: null,
          workersSalary: null,
          mechanicsEffort: null,
          mimExploitation: null,
          mechanicsSalary: null,
          hp: null,
          overheads: null,
          sp: null,
          estimatedProfit: null,
          totalWorkCost: null,
          workCostInRubles: null,
          dynamicRows: []
        },
        curr: {
          materialsPrice: null,
          equipmentPrice: null,
          constructionEffort: null,
          workersSalary: null,
          mechanicsEffort: null,
          mimExploitation: null,
          mechanicsSalary: null,
          hp: null,
          overheads: null,
          sp: null,
          estimatedProfit: null,
          totalWorkCost: null,
          workCostInRubles: null,
          dynamicRows: []
        }
      }
    ]
  };

  function flattenChildren(data: ExecutionCalculationData) {
    const result: ExecutionCalculationData[] = [{ ...data, parent_id: null }]; // Добавляем корневой объект в итоговый массив
    // data.parts = splitAndSort(data.parts)
    // console.log(acts,other);
    function flatten(children: ExecutionCalculationData[], parent: number) {
      for (const child of children) {
        result.push({ ...child, parent_id: parent });
        if (child.children && child.children.length > 0) {
          flatten(child.children, child.id);
        }
      }
    }

    if (data.children && data.children.length > 0) {
      flatten(data.children, data.id);
    }

    return result;
  }

  const filteredTotal = (resp.total as never[]).map((group: PartExecution) => {
    const { hp: hpCurr, sp: spCurr, ...restCurr } = group.curr;
    const { hp: hpBase, sp: spBase, ...restBase } = group.base;

    const curr = { ...restCurr, hp: null, sp: null, quantity: null };
    const base = { ...restBase, hp: null, sp: null, quantity: null };

    group.base = base;

    group.curr = curr;

    return group;
  });

  if (resp.total.length) {
    resp.total = [{ ...base, parts: filteredTotal }];
  } else {
    resp.total = [{ ...base }];
  }
  const finalTree: ExecutionCalculationData[] = [];
  for (const elem of resp.tree) {
    finalTree.push(...flattenChildren(elem));
  }
  resp.tree = finalTree.flat();
  console.log('<<<resp execution>>>', resp);
  return resp;
}

export function transformGetExecutionRimRes(
  resp: Omit<GetExecutionCalculationRimData, 'total'> & {
    total: ExecutionCalculationRimData;
  }
) {
  function sortItems(
    items: ExecutionCalculationRimData[]
  ): ExecutionCalculationRimData[] {
    const childrenMap: Map<
      number | null | undefined,
      ExecutionCalculationRimData[]
    > = new Map();

    items.forEach((item) => {
      if (!childrenMap.has(item.parentID)) {
        childrenMap.set(item.parentID, []);
      }
      childrenMap.get(item.parentID)!.push(item);
    });

    const sortedItems: ExecutionCalculationRimData[] = [];

    function addChildren(parentID: number | null) {
      const children = childrenMap.get(parentID) || [];
      children.sort((a, b) => (a.id as number) - (b.id as number));

      for (const child of children) {
        sortedItems.push(child);
        addChildren(child.id as number);
      }
    }

    addChildren(null);

    return sortedItems;
  }

  return {
    data: sortItems(resp.data),
    dynamicRows: resp?.dynamicRows || [],
    total: [resp.total]
  };
}
