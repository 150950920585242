/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import styled from '@emotion/styled';
import FunctionsIcon from '@mui/icons-material/Functions';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, ClickAwayListener, IconButton } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import React, { useCallback, useMemo, useState } from 'react';
import { useAppDispatch } from 'store/store';
import { IComplicatedIndexMethod } from '../../../../../../../../api/calculations/types';
import {
  SvgExecutedRate,
  SvgExecutedRatePosition
} from '../../../../../../../../assets/SVG/SVG';
import Cell from '../../../../../Accomplishment/components/Cell';
import { ExecutedTabCellLevelFolder } from '../../../../../CalculationDirectory/tabs/ExecutedTab';
import { Level, PinnedLevel } from './LevelExecution.style';
import {
  StyledButtonGroup,
  StyledPopper
} from 'pages/Calculations/components/CalculationBasic/components/styles';
import Tooltip from 'components/Tooltip';
import { useLocation } from 'react-router-dom';

interface ILevelExecution
  extends ICellRendererParams<IComplicatedIndexMethod, any, any> {
  collapse: (id: number, index: number) => void;
  hiddenArr: Set<number>;
  onDelete?: (id: number) => void;
}

const EmptySpan = styled.span`
  width: 24px;
  height: 24px;
  margin: 7px;
`;
const LevelExecution: React.FC<ILevelExecution> = ({
  data,
  node,
  collapse,
  hiddenArr,
  onDelete,
  api,
  context
}) => {
  const params = new URLSearchParams(useLocation().search);
  const isPreview = params.get('state') === 'preview';
  const close = useMemo(() => {
    return hiddenArr.has(data?.id!);
  }, [hiddenArr]);

  const onClick = (id: number) => {
    // dispatch(filterByID(node.rowIndex!));
    data?.hasChildren && collapse(id, node.rowIndex!);
    // collapse(id, node.rowIndex!);
    // (async () => {
    //   // const ids: Set<number> = new Set(context?.hiddenArr ?? []);
    //   api.getRowNode((n) => {
    //     if (!hiddenArr.has(n.data?.parentID!)) {
    //       // if () {
    //       // collapse(n.data?.id!);
    //       // ids.add(n.data?.id!);
    //       // ids.push();
    //       // context?.setHiddenRowsIds?.((draft: any) => {
    //       //   draft.push(n.data?.id!);
    //       // });
    //       // }
    //     }
    //   });
    //   // context?.setHiddenRowsIds?.(ids);
    // })();
  };

  const [anchorElPoper, setAnchorElPoper] = useState<null | HTMLElement>(null);
  const [openPoper, setOpenPoper] = useState(false);

  const handleClickAway = () => {
    setOpenPoper(false);
  };

  const handleShowButton = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElPoper(event.currentTarget);
      setOpenPoper(true);
    },
    []
  );

  if (node.rowPinned === 'top') {
    return (
      <PinnedLevel>
        <FunctionsIcon />
        <Cell showTooltip={false}>Суммы</Cell>
      </PinnedLevel>
    );
  }
  const colors = {
    inactive: '#B8B8B8',
    deleted: '#B8B8B8',
    dont_belong: '#FF0101',
    split: '#B78BAC'
  };

  switch (data?.type!) {
    case 'file':
      return (
        <>
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}>
            <Box
              width={'68px'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Level arrowClose={close}>
                {data?.hasChildren ? (
                  <IconButton
                    tabIndex={-1}
                    sx={{
                      'svg path': {
                        fill: 'rgba(0, 0, 0, 0.87)'
                      },
                      width: 34,
                      height: 34
                    }}
                    style={{
                      outline: 'none'
                    }}
                    onClick={() => onClick(data?.id!)}
                    size="small">
                    <KeyboardArrowUpIcon
                      className={'arrow'}
                      tabIndex={-1}
                      sx={{
                        outline: 'none',
                        transition: 'rotate .2s linear forwards'
                      }}
                    />
                  </IconButton>
                ) : (
                  <Box
                    onClick={() => onClick(data?.id!)}
                    sx={{
                      padding: '0 17px'
                    }}
                  />
                )}
                <ExecutedTabCellLevelFolder
                  onClick={(e) => {
                    onClick(data?.id!);
                    // if (isPreview) {
                    //   onClick(data?.id!);
                    // } else {
                    //   handleShowButton(e);
                    // }
                  }}
                  style={{ cursor: 'pointer' }}>
                  1
                </ExecutedTabCellLevelFolder>

                <StyledPopper
                  placement={'right'}
                  popperOptions={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, 10] // Здесь можно настроить отступ, например, 10 пикселей вниз
                        }
                      }
                    ]
                  }}
                  open={openPoper}
                  anchorEl={anchorElPoper}>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <StyledButtonGroup
                      variant="text"
                      sx={{
                        '& .MuiButtonGroup-root': {
                          width: '100%'
                        },
                        '& button': {
                          border: 'none !important',
                          minWidth: '36px !important'
                        }
                      }}>
                      <IconButton
                        sx={{
                          cursor: 'pointer',
                          height: '100%',
                          width: '36px',
                          borderRadius: '6px',
                          svg: {
                            path: {
                              fill: '#5C6E8C',
                              transition: 'fill linear 0.2s'
                            }
                          },
                          transition: 'background linear 0.2s',
                          '&:hover': {
                            path: {
                              fill: 'white'
                            },
                            background: '#F46B6B'
                          }
                        }}
                        onClick={() => {
                          if (data?.id && onDelete) {
                            onDelete(data.id);
                          }
                        }}>
                        <Tooltip title="Удалить" placement="top">
                          <DeleteOutlineIcon
                            width={'11.6px'}
                            height={'13.3px'}
                            fontSize={'small'}
                          />
                        </Tooltip>
                      </IconButton>
                    </StyledButtonGroup>
                  </ClickAwayListener>
                </StyledPopper>
              </Level>
            </Box>
          </Box>
        </>
      );
    case 'level':
      return (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}>
          <Box
            width={'68px'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Level
              $haveClick={!!data?.hasChildren}
              onClick={() => onClick(data?.id!)}
              arrowClose={close}>
              {data?.hasChildren ? (
                <IconButton
                  tabIndex={-1}
                  sx={{
                    'svg path': {
                      fill: 'rgba(0, 0, 0, 0.87)'
                    },
                    width: 34,
                    height: 34
                  }}
                  style={{
                    outline: 'none'
                  }}
                  size="small">
                  <KeyboardArrowUpIcon
                    className={'arrow'}
                    tabIndex={-1}
                    sx={{
                      outline: 'none',
                      transition: 'rotate .2s linear forwards'
                    }}
                  />
                </IconButton>
              ) : (
                <Box
                  sx={{
                    padding: '0 17px'
                  }}
                />
              )}
              <div>
                <SvgExecutedRate
                  style={{
                    rotate: 0,
                    fill: '#5291DA'
                  }}
                />
              </div>
            </Level>
          </Box>
        </Box>
      );
    case 'row':
      return (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}>
          <Box
            width={'68px'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Level
              $haveClick={!!data?.hasChildren}
              onClick={() => onClick(data?.id!)}
              arrowClose={close}
              floatRight>
              <Box
                sx={{
                  padding: '0 17px'
                }}
              />
              <div>
                <SvgExecutedRatePosition
                  style={{
                    width: 17,
                    height: 18,
                    fill: '#B78BAC'
                  }}
                />
              </div>
            </Level>
          </Box>
        </Box>
      );
    default:
      return (
        <Cell>
          {!!data?.hasChildren && (
            <button onClick={() => collapse(data?.id!, node.rowIndex!)}>
              collabse
            </button>
          )}
        </Cell>
      );
  }
};

export default LevelExecution;
