import React, { useCallback, useMemo } from 'react';
import { ActList } from 'types';
import { isAfter, isBefore, isDate, lastDayOfMonth, sub } from 'date-fns';
import { ActCol, ActRow as StyledActRow } from './act.styles';
import { formatDateToString, formatToDate } from 'utils/formatDate';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { IconDate } from '../../../../../../../../Calculations/components/Accomplishment/components/ActDialog/ActDialog.style';
import { DatePickerRenderInput } from '../../../../../../../../../components/FieldForm/styles';
import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { statusesAct } from '../../../../../../../index-method-execution';
import { useField, useFormikContext } from 'formik';
import { DialogForm } from '../../../parameters-dialog.execution.types';

export const ActRow: React.FC<{
  row: ActList;
  rowIndex: number;
  parentIndex: number;
  isActs?: boolean;
  setCurrentAct: (act: ActList | null) => void;
}> = ({ row, rowIndex, parentIndex, isActs, setCurrentAct }) => {
  const {
    values: { currentAct, calculation, acts, removedActs },
    setFieldValue,
    validateField
  } = useFormikContext<DialogForm>();
  // const { control, getValues, setValue } =
  //   useFormContext<GetCoefficientsResponse>();

  const [startDate] = useField({
    name: `acts.${parentIndex}.fields.${rowIndex}.startDate` as any
  });
  const [endDate] = useField({
    name: `acts.${parentIndex}.fields.${rowIndex}.endDate` as any
  });
  // const startDate = useWatch({
  //   name: `acts.${parentIndex}.fields.${rowIndex}.startDate` as 'acts.0.fields.0.startDate',
  //   control
  // });

  // const endDate = useWatch({
  //   name: `acts.${parentIndex}.fields.${rowIndex}.endDate` as 'acts.0.fields.0.endDate',
  //   control
  // });
  // const startDate = useWatch({
  //   name: `acts.${estimateIndex}.groups.${parentIndex}.fields.${rowIndex}.startDate` as any,
  //   control,
  // });
  //
  // const endDate = useWatch({
  //   name: `acts.${estimateIndex}.groups.${parentIndex}.fields.${rowIndex}.endDate` as any,
  //   control,
  // });

  /**
   * являются ли даты одинаковыми
   * @param dateA
   * @param dateB
   */
  const isSameDate = (dateA: Date, dateB: Date) =>
    dateA.getDate() === dateB.getDate() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getFullYear() === dateB.getFullYear();

  /**
   * разбирается список дат для вывода на страницу, делается плоская структура
   */
  const unFlatRanges = useMemo(() => {
    const fields = acts || [];
    return fields
      .reduce(
        (acc: { start: string | Date; end: string | Date }[], item: any) => {
          acc.push(
            ...item.fields.flatMap((dates: any) => ({
              start: dates.startDate || '',
              end: dates.endDate || ''
            }))
          );
          return acc;
        },
        []
      )
      .filter(
        (d: any) =>
          !isSameDate(d.start as Date, new Date(row.startDate || '')) &&
          !isSameDate(d.end as Date, new Date(row.endDate || ''))
      );
  }, [row]);

  /**
   * массив недоступных дат
   */
  const disabledDates: { start: Date; end: Date }[] = useMemo(() => {
    const fields = acts || [];
    if (!fields) return [];
    return unFlatRanges.reduce(
      (disabled: any, dateRange: any) => {
        const { start, end } = dateRange;
        disabled.push({ start: new Date(start), end: new Date(end) });
        return disabled;
      },
      [] as { start: Date; end: Date }[]
    );
  }, [startDate, endDate]);

  /**
   * Здесь идет расчет
   * вычисляется какое это поле и устанавливает максимальную и минимальную даты
   */
  const disabledRange = useCallback(
    (type: 'startDate' | 'endDate' | 'onDate', date?: Date | null) => {
      let startD = startDate.value || null;
      let endD = endDate.value || null;

      if (type === 'startDate' && date) {
        startD = date;
      }
      if (type === 'endDate' && date) {
        endD = date;
      }

      const sortedDates = [...disabledDates, {} as any]
        .sort((a, b) => a?.start?.getTime() - b?.start?.getTime())
        .map((d, i, array) => {
          if (i == 0) {
            return { start: null, end: d.start ?? null };
          } else
            return {
              start: array[i - 1].end,
              end: d?.start ?? null
            };
        });
      const dates: { minDate: null | Date; maxDate: null | Date } = {
        minDate: null,
        maxDate: null
      };

      if ((!startD && !endD) || type === 'onDate') return dates;
      if (sortedDates.length === 1) {
        if (type === 'startDate') {
          if (endD) {
            const date = endD.getDate();
            const year = endD.getFullYear();
            const month = endD.getMonth();
            dates.maxDate = new Date(year, month, date - 1);
            return dates;
          }
        }
        if (type === 'endDate') {
          if (startD) {
            const date = startD.getDate();
            const year = startD.getFullYear();
            const month = startD.getMonth();
            dates.minDate = new Date(year, month, date + 1);
            return dates;
          }
        }
      }

      for (const { start, end } of sortedDates) {
        if (type === 'startDate') {
          let d: Date | null = null;
          if (endD) {
            const date = endD.getDate();
            const year = endD.getFullYear();
            const month = endD.getMonth();
            d = new Date(year, month, date - 1);
          }
          if (start === null && endD && isBefore(endD, end)) {
            dates.minDate = start;
            dates.maxDate = d ?? end;
            return dates;
          } else if (end === null && isAfter(endD as Date, start)) {
            dates.minDate = start;
            dates.maxDate = d ?? end;
            return dates;
          } else {
            if (isAfter(endD as Date, start) && isBefore(endD as Date, end)) {
              dates.minDate = start;
              dates.maxDate = d ?? end;
              return dates;
            }
          }
        }
        if (type === 'endDate') {
          let d: Date | null = null;
          if (startD) {
            const date = startD.getDate();
            const year = startD.getFullYear();
            const month = startD.getMonth();
            d = new Date(year, month, date + 1);
          }
          if (start === null && startD && isBefore(startD, end)) {
            dates.minDate = d ?? start;
            dates.maxDate = end;
            return dates;
          } else if (end === null && isAfter(startD as Date, start)) {
            dates.minDate = d ?? start;
            dates.maxDate = end;
            return dates;
          } else {
            if (
              isAfter(startD as Date, start) &&
              isBefore(startD as Date, end)
            ) {
              dates.minDate = d ?? start;
              dates.maxDate = end;
              return dates;
            }
          }
        }
      }
      return dates;
    },
    [disabledDates]
  );

  const handleRemove = useCallback(
    (updateItem: ActList) => {
      const copyFields = [...acts];
      /**
       * функция фильтрации по recordID
       */
      const filter = (i: ActList) => {
        return i.id !== updateItem.id;
      };

      const currentParent = copyFields[parentIndex];
      console.log(currentParent);

      /**
       * новый список для отрисовки, фильтрация
       */
      currentParent.fields = currentParent.fields.filter(filter);

      /* Получение полного списка к удалению */
      const removedArr: ActList[] = removedActs;
      /* пушится новый объект и перезаписывается значение */
      removedArr.push({
        ...updateItem
      });
      localStorage.setItem('removedActs', JSON.stringify(removedArr));
      if (updateItem.id === currentAct?.id) {
        const condidate = acts?.map((_: any) =>
          _.fields.find((a: any) => a.id)
        );
        if (condidate[0]) {
          setFieldValue(`currentAct`, condidate[0], true).then(() =>
            setCurrentAct(condidate[0])
          );
        } else {
          setFieldValue(`currentAct`, null, true)
        }
      }

      setFieldValue(`acts.${parentIndex}`, currentParent);
      setFieldValue('removedActs', removedArr);

      // setValue(`removed.acts`, removedArr, { shouldDirty: true });

      /* Обновляется родитель, а затем обновляются значения */
      // setValue(`acts.${parentIndex}` as 'acts.0', currentParent, {
      //   shouldDirty: true
      // });
    },
    [rowIndex, parentIndex, currentAct, setCurrentAct]
  );
  const checkDate = (
    inputDate: Date | null,
    start: Date | null,
    end: Date | null
  ) => {
    if (!inputDate || !start || !end) return false;
    return (
      isSameDate(inputDate, start) ||
      (inputDate > start && inputDate < end) ||
      isSameDate(inputDate, end)
    );
  };
  const shouldDisabled = useCallback(
    (dayOrMonth: unknown, render: 'startDate' | 'endDate') => {
      if (dayOrMonth) {
        if (disabledDates.length) {
          if (render === 'startDate') {
            return disabledDates.some(({ start, end }) =>
              checkDate(dayOrMonth as Date, start, end)
            );
          }
          if (render === 'endDate') {
            return disabledDates.some(({ start, end }) =>
              checkDate(dayOrMonth as Date, start, end)
            );
          }
        }
      }
      return false;
    },
    [disabledDates]
  );
  console.log('log: ', calculation?.integrationInfo);
  return (
    <StyledActRow
      $isIntegrate={!!calculation?.integrationInfo}
      $active={row.id === currentAct?.id}>
      <ActCol>
        {formatDateToString(new Date(row.onDate || ''), 'dd.MM.yyyy')}
      </ActCol>
      <ActCol>
        <DesktopDatePicker
          // ref={ref}
          //...props
          value={startDate.value}
          maxDate={disabledRange('startDate').maxDate}
          minDate={disabledRange('startDate').minDate}
          openTo="year"
          views={['year', 'month', 'day']}
          shouldDisableMonth={(d) => shouldDisabled(d, 'startDate')}
          shouldDisableDate={(d) => shouldDisabled(d, 'startDate')}
          onChange={(e, keyboardInputValue) => {
            const userValue = keyboardInputValue
              ? keyboardInputValue
              : e &&
                  e instanceof Date &&
                  !isNaN(e.getTime()) &&
                  !keyboardInputValue
                ? e.toLocaleDateString('ru-RU')
                : '';

            if (/^\d{2}\.\d{2}\.\d{4}/.test(userValue)) {
              setFieldValue(
                `acts.${parentIndex}.fields.${rowIndex}.startDate` as 'acts.0.fields.0.startDate',
                e as Date,
                true
              );
              // setValue(
              //   `acts.${parentIndex}.fields.${rowIndex}.startDate` as 'acts.0.fields.0.startDate',
              //   e as Date,
              //   {
              //     shouldDirty: true
              //   }
              // );
              const lastDay = lastDayOfMonth(e as Date);
              const endDate =
                disabledRange('endDate', e).maxDate &&
                sub(disabledRange('endDate', e).maxDate as Date, {
                  days: 1
                });
              if (
                endDate &&
                isDate(endDate) &&
                e instanceof Date &&
                endDate.getMonth() === e.getMonth()
              ) {
                setFieldValue(
                  `acts.${parentIndex}.fields.${rowIndex}.endDate` as 'acts.0.fields.0.endDate',
                  endDate,
                  true
                );
                // setValue(
                //   `acts.${parentIndex}.fields.${rowIndex}.endDate` as 'acts.0.fields.0.endDate',
                //   endDate,
                //   {
                //     shouldDirty: true
                //   }
                // );
              } else {
                if (!isNaN(lastDay.getTime())) {
                  setFieldValue(
                    `acts.${parentIndex}.fields.${rowIndex}.endDate` as 'acts.0.fields.0.endDate',
                    lastDay,
                    true
                  );
                  // setValue(
                  //   `acts.${parentIndex}.fields.${rowIndex}.endDate` as 'acts.0.fields.0.endDate',
                  //   lastDay,
                  //   {
                  //     shouldDirty: true
                  //   }
                  // );
                } else {
                  setFieldValue(
                    `acts.${parentIndex}.fields.${rowIndex}.endDate` as 'acts.0.fields.0.endDate',
                    null,
                    true
                  );
                  // setValue(
                  //   `acts.${parentIndex}.fields.${rowIndex}.endDate` as 'acts.0.fields.0.endDate',
                  //   null,
                  //   {
                  //     shouldDirty: true
                  //   }
                  // );
                }
              }
            }
          }}
          components={{
            OpenPickerIcon: IconDate
          }}
          renderInput={(params) => (
            <DatePickerRenderInput
              // ref={ref}
              {...params}
              //...props
              value={startDate.value}
              onChange={(e) => {
                e.stopPropagation();
                if (!e.target.value) {
                  setFieldValue(
                    `acts.${parentIndex}.fields.${rowIndex}.startDate` as 'acts.0.fields.0.startDate',
                    null,
                    true
                  );
                  // setValue(
                  //   `acts.${parentIndex}.fields.${rowIndex}.startDate` as 'acts.0.fields.0.startDate',
                  //   null,
                  //   {
                  //     shouldDirty: true
                  //   }
                  // );
                  return;
                }
                if (/\^(\d{2})\.(\d{2})\.(\d{4})$/.test(e.target.value)) {
                  const date = new Date(
                    formatToDate(e!.target!.value! as string, 'yyyy-MM-dd')!
                  );
                  setFieldValue(
                    `acts.${parentIndex}.fields.${rowIndex}.startDate` as any,
                    !isNaN(date.getTime()) ? date : e.target.value,
                    true
                  );
                  // setValue(
                  //   `acts.${parentIndex}.fields.${rowIndex}.startDate` as any,
                  //   !isNaN(date.getTime()) ? date : e.target.value,
                  //   { shouldDirty: true }
                  // );
                }
              }}
              inputProps={{
                ...params.inputProps,
                style: { padding: '6px 6px 6px 8px' },
                placeholder: 'дд.мм.гггг',
                autoComplete: 'off'
              }}
            />
          )}
        />
      </ActCol>
      <ActCol $align={'center'}>&mdash;</ActCol>
      <ActCol>
        <DesktopDatePicker
          // ref={ref}
          //...props
          value={endDate.value}
          components={{
            OpenPickerIcon: IconDate
          }}
          maxDate={disabledRange('endDate').maxDate}
          minDate={disabledRange('endDate').minDate}
          openTo="year"
          views={['year', 'month', 'day']}
          shouldDisableMonth={(d) => shouldDisabled(d, 'endDate')}
          shouldDisableDate={(d) => shouldDisabled(d, 'endDate')}
          onChange={(e: any, keyboardInputValue) => {
            console.log(keyboardInputValue);
            const userValue = keyboardInputValue
              ? keyboardInputValue
              : e && !isNaN(e.getTime()) && !keyboardInputValue
                ? e.toLocaleDateString('ru-RU')
                : '';

            if (/^\d{2}\.\d{2}\.\d{4}/.test(userValue)) {
              setFieldValue(
                `acts.${parentIndex}.fields.${rowIndex}.endDate` as any,
                e,
                true
              );
              // setValue(
              //   `acts.${parentIndex}.fields.${rowIndex}.endDate` as any,
              //   e,
              //   {
              //     shouldDirty: true
              //   }
              // );
            }
          }}
          renderInput={(params) => (
            <DatePickerRenderInput
              // ref={ref}
              // helperText={isError ? t(error as string, { maxLength }) : undefined}
              {...params}
              //...props
              value={endDate.value}
              onChange={(e) => {
                e.stopPropagation();
                if (!e.target.value) {
                  setFieldValue(
                    `acts.${parentIndex}.fields.${rowIndex}.endDate` as any,
                    null,
                    true
                  );
                  // setValue(
                  //   `acts.${parentIndex}.fields.${rowIndex}.endDate` as any,
                  //   null,
                  //   {
                  //     shouldDirty: true
                  //   }
                  // );
                  return;
                }

                if (/\^(\d{2})\.(\d{2})\.(\d{4})$/.test(e.target.value)) {
                  const date = new Date(
                    formatToDate(e!.target!.value! as string, 'yyyy-MM-dd')!
                  );
                  setFieldValue(
                    `acts.${parentIndex}.fields.${rowIndex}.endDate` as any,
                    !isNaN(date.getTime()) ? date : e.target.value,
                    true
                  );
                  // setValue(
                  //   `acts.${parentIndex}.fields.${rowIndex}.endDate` as any,
                  //   !isNaN(date.getTime()) ? date : e.target.value,
                  //   { shouldDirty: true }
                  // );
                }
              }}
              inputProps={{
                ...params.inputProps,
                style: { padding: '6px 6px 6px 8px' },
                placeholder: 'дд.мм.гггг',
                // maxLength,
                // minLength,
                autoComplete: 'off'
              }}
            />
          )}
        />
      </ActCol>
      <ActCol $align={'center'}>
        <IconButton
          onClick={() => {
            if (acts?.[parentIndex]?.fields?.[rowIndex]) {
              handleRemove(acts[parentIndex].fields[rowIndex]);
            }
          }}
          color="warning">
          <Delete />
        </IconButton>
      </ActCol>
      {calculation?.integrationInfo && (
        <ActCol $color={statusesAct[row.status!]?.color} $align={'center'}>
          {statusesAct[row.status!]?.title}
        </ActCol>
      )}
    </StyledActRow>
  );
};
