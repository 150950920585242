import React from 'react';
import { Button, Typography } from '@mui/material';

import { Divider } from 'components/Divider';
import { getEmptyPageData } from 'pages/Administration/AdminProjects';
import { EmptyPage, EmptyPageData } from 'components/EmptyPage';
import { formatDateToString } from 'utils/formatDate';
import { AvailableCalculationListProps } from './AvailableCalculationList.types';
import {
  AddCalculationDrawerContent,
  AddCalculationDrawerContentHeader,
  AddCalculationDrawerContentWrapper,
  EmptyWrapper
} from '../../AddCalculationDrawer.styles';
import { CalculationListItem } from '../CalculationListItem';

export const AvailableCalculationList: React.FC<
  AvailableCalculationListProps
> = ({
  onSelectCalculation,
  onClearSelection,
  onAddSelection,
  onSelectAll,
  list,
  selectedIds,
  addedIds,
  calculationType,
  loading
}) => {
  const emptyData: EmptyPageData = getEmptyPageData(
    <>Данные в реестре отсутствуют.</>
  );

  return (
    <AddCalculationDrawerContentWrapper width={'50%'} maxWidth={'565px'}>
      <AddCalculationDrawerContentHeader>
        <Typography fontWeight={600} mr={'auto'}>
          Реестр ЛСР
        </Typography>
        <Button variant="text" onClick={onSelectAll}>
          Выбрать все
        </Button>
        <Button
          variant="text"
          onClick={onClearSelection}
          disabled={selectedIds.length === 0}>
          Сбросить
        </Button>
        <Button
          color="success"
          sx={{
            width: '125px',
            paddingLeft: '2px!important',
            paddingRight: '2px!important',
            fontSize: '13px',
            boxShadow: 'none'
          }}
          onClick={onAddSelection}
          disabled={selectedIds.length === 0 || loading}>
          {`добавить (${selectedIds.length})`}
        </Button>
      </AddCalculationDrawerContentHeader>
      <Divider color="#edeff3" />
      <AddCalculationDrawerContent>
        {list.length ? (
          list.map((item, i) => (
            <CalculationListItem
              key={item.id}
              checkbox={true}
              label={item.title}
              text={`${formatDateToString(new Date(item.date), 'dd.MM.yyyy')}, ${item.responsible}`}
              type={calculationType}
              id={item.id}
              index={i}
              checked={selectedIds.includes(item.id)}
              onCheck={onSelectCalculation}
              count={
                addedIds.filter((addedId) => addedId === item.id).length ||
                undefined
              }
            />
          ))
        ) : (
          <EmptyWrapper>
            <EmptyPage data={emptyData} />
          </EmptyWrapper>
        )}
      </AddCalculationDrawerContent>
    </AddCalculationDrawerContentWrapper>
  );
};
