import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Checkbox, Divider, MenuItem, Typography } from '@mui/material';
import { ICellEditorParams, ICellRendererParams } from 'ag-grid-community';
import { useField, useFormikContext } from 'formik';
import { cloneDeep } from 'lodash';
import React from 'react';
import {
  LimitedLocal,
  RimCoefficientsDataResponse,
  RimCoefficientsUpdateRequest
} from 'types';
import { stateDefaultRow } from './table.constants';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export type Option = {
  id: number | string;
  label: string;
  lsr?: string;
};
type Props = {
  optionProps: any;
  option: Option;
  state: any;
  options: Option[];
  ownerState: any;
  props: ICellEditorParams<LimitedLocal, any, any>;
  toggle: (option: { id: number | string; label: string }) => void;
};
export const RenderOptions = React.memo((pr: Props) => {
  const { ownerState, props, optionProps, option, options, toggle, state } = pr;
  const { value } = ownerState;
  const { setFieldValue } = useFormikContext<LimitedLocal[]>();

  const [inputProps] = useField(
    `rows.${props.node.rowIndex || 0}.${props.colDef.field}`
  );
  const [inputPropsForTitle] = useField(`rows.${props.node.rowIndex || 0}`);
  const { selected } = state;
  // console.log(optionProps);
  if (props.colDef?.field === 'type' || props.colDef?.field === 'title') {
    return (
      <MenuItem
        key={optionProps.id + option.label}
        {...optionProps}
        selected={selected}
        value={option.id}
        onChange={() => {}}
        onClick={() => {
          if (props.colDef?.field === 'title') {
            // console.log(option);
            // setFieldValue(
            //   `rows.${props.node.rowIndex || 0}.title`,
            //   option.label
            // );
            // // setFieldValue(
            // //   `rows.${props.node.rowIndex || 0}.estimates`,
            // //   (option as any).estimates,
            // //   true
            // // );
            // setFieldValue(
            //   `rows.${props.node.rowIndex || 0}.type`,
            //   (option as any).type
            // );

            setFieldValue(
              `rows.${props.node.rowIndex || 0}`,
              {
                ...inputPropsForTitle.value,
                estimates:
                  (option as any)?.estimates?.map((el: any) => ({
                    ...el,
                    id: el.estimateID,
                    label: el.estimateTitle
                  })) || [],
                title: (option as any).title,
                type: (option as any).type,
                values: {
                  building: (option as any)?.values?.building
                    ? String((option as any)?.values?.building)
                    : '',
                  equipment: (option as any)?.values?.equipment
                    ? String((option as any)?.values?.equipment)
                    : '',
                  mounting: (option as any)?.values?.mounting
                    ? String((option as any)?.values?.mounting)
                    : '',
                  other: (option as any)?.values?.other
                    ? String((option as any)?.values?.other)
                    : '',
                  total: (option as any)?.values?.total
                    ? String((option as any)?.values?.total)
                    : ''
                }
              },
              true
            );
          } else {
            setFieldValue(inputProps.name, option.id);
          }

          props.stopEditing(true);
        }}>
        {option.label}
      </MenuItem>
    );
  } else {
    if (option.id === -100 && options?.length) {
      return (
        <Box
          key={option.id + option.label}
          sx={{
            backgroundColor: 'white'
          }}
          role={'option'}
          position={'sticky'}
          top="0"
          left="0"
          right="0"
          zIndex="999">
          <Box
            p="8px 16px"
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            <Typography>
              Выберите{' '}
              {props.colDef?.field === 'estimates' ? 'смету' : 'лимитированную'}
            </Typography>
            <Typography color="#0288D1" variant="subtitle2">
              Выбрано: {value.length}
            </Typography>
          </Box>
          <Divider sx={{ background: '#D2DCFF' }} />
          <Box
            component={'li'}
            {...optionProps}
            role={'item'}
            onClick={() => toggle(option)}>
            <Checkbox
              size="small"
              sx={{
                width: '15px',
                height: '15px'
              }}
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              indeterminate={
                option.id === -100
                  ? value.length
                    ? (options?.length || 0) - 1 !== value.length
                    : undefined
                  : undefined
              }
              checked={
                option.id === -100
                  ? (options?.length || 0) - 1 === value.length
                  : selected
              }
            />
            <Box display={'flex'} flexDirection={'column'}>
              {option?.lsr} {option.label}
              {/*{option?.lsr && (*/}
              {/*  <Typography color={'gray'} variant={'subtitle2'}>*/}
              {/*    {option.lsr}*/}
              {/*  </Typography>*/}
              {/*)}*/}
            </Box>
          </Box>
          <Divider sx={{ background: '#0000001A' }} />
        </Box>
      );
    } else {
      return (
        <Box
          key={option.id + option.label}
          component={'li'}
          {...optionProps}
          onClick={(event: any) => {
            toggle(option);
          }}>
          <Checkbox
            size="small"
            sx={{
              width: '15px',
              height: '15px'
            }}
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            indeterminate={
              option.id === -100
                ? value.length
                  ? (options?.length || 0) - 1 !== value.length
                  : undefined
                : undefined
            }
            checked={
              option.id === -100
                ? (options?.length || 0) - 1 === value.length
                : selected
            }
          />
          <Box display={'flex'} flexDirection={'column'}>
            {option?.lsr} {option.label}
            {/*{option?.lsr && (*/}
            {/*  <Typography color={'gray'} variant={'subtitle2'}>*/}
            {/*    {option.lsr}*/}
            {/*  </Typography>*/}
            {/*)}*/}
          </Box>
        </Box>
      );
    }
  }
});
RenderOptions.displayName = 'RenderOptions';
export function modifyResponse(
  response: RimCoefficientsDataResponse | undefined
): Array<LimitedLocal> {
  const copy = cloneDeep(stateDefaultRow);
  if (response) {
    for (const key in copy) {
      const mapingRespponse: LimitedLocal[] = (
        response.limitedCosts?.[key] || []
      ).map((el): LimitedLocal => {
        const { estimates, dependencies, ...restEl } = el;
        return {
          ...restEl,
          values: {
            building: el?.values?.building?.toString().replace('.', ',') || '',
            mounting: el?.values?.mounting?.toString().replace('.', ',') || '',
            other: el?.values?.other?.toString().replace('.', ',') || '',
            total: el?.values?.total?.toString().replace('.', ',') || '',
            equipment: el?.values?.equipment?.toString().replace('.', ',') || ''
          },
          estimates: el.estimates.map((item) => ({
            id: item.id,
            label: item.title
          })),
          dependencies: el.dependencies.map((item) => ({
            id: item.uuid,
            label: item.title
          })),
          position: 'position' as LimitedLocal['position']
        };
      });
      copy[key].push(...mapingRespponse);
    }
  }
  return Object.values(copy).flat(Infinity) as Array<LimitedLocal>;
}

export function modifyRequest(values: LimitedLocal[]) {
  return values
    .filter((el) => el.position === 'position')
    .map((restEl) => {
      return {
        ...(restEl.id && { id: restEl.id }),
        ...(restEl.isDeleted !== undefined && { isDeleted: restEl.isDeleted }),
        title: restEl?.title || '',
        asRow: restEl.asRow,
        chapter: restEl.chapter!,
        uuid: restEl.uuid!,
        order: restEl.order!,
        type: restEl.type!,
        dependencies:
          restEl.dependencies?.map((item) => item.id! as string) || [],
        estimates: restEl.estimates?.map((item) => item.id! as number) || [],
        values: {
          building: (restEl.values?.building?.toString() || '')
            .replace(/\s/g, '')
            .replace(/,/, '.'),
          mounting: (restEl.values?.mounting?.toString() || '')
            .replace(/\s/g, '')
            .replace(/,/, '.'),
          other: (restEl.values?.other?.toString() || '')
            .replace(/\s/g, '')
            .replace(/,/, '.'),
          total: (restEl.values?.total?.toString() || '')
            .replace(/\s/g, '')
            .replace(/,/, '.'),
          equipment: (restEl.values?.equipment?.toString() || '')
            .replace(/\s/g, '')
            .replace(/,/, '.')
        }
      };
    }) as RimCoefficientsUpdateRequest['limitedCosts'];
}

export const Placeholder = () => {
  return (
    <div
      style={{
        color: '#F46B6B',
        fontSize: '14px',
        fontWeight: 400,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        lineHeight: '16.41px'
      }}>
      Обязательное поле
    </div>
  );
};

export const ArrowIcon: React.FC<ICellRendererParams<LimitedLocal>> = (
  params
) => {
  switch (params.colDef?.field) {
    case 'type':
    case 'dependencies':
      return (
        <Box
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)'
          }}>
          <ArrowDropDownIcon color={'secondary'} fontSize={'small'} />
        </Box>
      );
    default:
      return null;
  }
};
export const replaceNum = (str: string) => {
  str = str.replace(/^0+(?=\d|,)/, '');
  str = str.replace(/[.,]$/, '');
  if (str.startsWith(',')) {
    str = '0' + str;
  }
  if (str.includes(',')) {
    str = str.replace(/,?0+$/, '');
  }
  return str || '';
};
