import { setCredentials } from '../store/slices/auth';
import { LoginResponse } from '../api/auth/types';

import { AsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../store/store';
import axios, { AxiosResponse } from 'axios';
import { config } from '../core/config';

export async function getRefreshToken(
  dispatch: ThunkDispatch<any, any, any>,
  rootState: RootState,
  requestFn: AsyncThunk<any, any, any>,
  paramsRequest: any
): Promise<void> {
  const refreshToken = rootState.auth.refreshToken;
  const refreshResult: AxiosResponse<LoginResponse> = await axios.post(
    config.apiHost + '/auth/refresh',
    { token: refreshToken },
    { method: 'POST' }
  );
  if (refreshResult.data) {
    dispatch(setCredentials(refreshResult.data as LoginResponse));
    dispatch(requestFn(paramsRequest));
  } else {
    dispatch(setCredentials({}));
  }
}
