import React from 'react';
import { Typography } from '@mui/material';

import { Divider } from 'components/Divider';
import { formatDateToString } from 'utils/formatDate';
import { AppendedCalculationListProps } from './AppendedCalculationList.types';
import {
  AddCalculationDrawerContent,
  AddCalculationDrawerContentHeader,
  AddCalculationDrawerContentWrapper
} from '../../AddCalculationDrawer.styles';
import { CalculationListItem } from '../CalculationListItem';

export const AppendedCalculationList: React.FC<
  AppendedCalculationListProps
> = ({ onDeleteCalculation, list, newList, calculationType, loading }) => {
  return (
    <AddCalculationDrawerContentWrapper flex={1}>
      <AddCalculationDrawerContentHeader>
        <Typography fontWeight={600} mr={'auto'}>
          Структура расчета
        </Typography>
      </AddCalculationDrawerContentHeader>
      <Divider color="#edeff3" />
      <AddCalculationDrawerContent>
        {list.map((item, i) => (
          <CalculationListItem
            key={`${i}_${item.title}`}
            checkbox={false}
            label={item.title}
            text={`${formatDateToString(new Date(item.date), 'dd.MM.yyyy')}, ${item.responsible}`}
            type={calculationType}
            id={item.id}
            index={i}
            isExisted
          />
        ))}
        {newList.map((item, i) => (
          <CalculationListItem
            key={`${i}_${item.id}`}
            checkbox={false}
            label={item.title}
            text={`${formatDateToString(new Date(item.date), 'dd.MM.yyyy')}, ${item.responsible}`}
            type={calculationType}
            id={item.id}
            index={i}
            onDelete={onDeleteCalculation}
            isDisabled={loading}
          />
        ))}
      </AddCalculationDrawerContent>
    </AddCalculationDrawerContentWrapper>
  );
};
