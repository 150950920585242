import React, { useState } from 'react';
import { ListItem, ListWrapper, Text, Title, Wrapper } from '.';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { BaseTextField } from 'components/BaseTextField';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Checkbox, CircularProgress, Dialog, Stack } from '@mui/material';
import Button from 'components/Button';
import { TChips } from './index-method-calculation.types';
import useSearchParam from 'hooks/useSearchParams';
import { useCalcId } from 'hooks/useCalcId';
import { useGetFilterRimCalcQuery } from 'api/calculations';
import { stringify, parse } from 'qs';
import { Form, useFormik } from 'formik';

type TProps = {
  open: keyof TChips | null;
  onClose: () => void;
  changeFilter: (key: keyof TChips, target: string | string[]) => void;
  activeFilters: Required<TChips>;
};

export const title: Record<keyof TChips, string> = {
  title: 'Наименование',
  chapter: 'Раздел',
  lsr: 'Шифр ЛСР',
  code: 'Код расценки',
  header: 'Заголовок',
  unit: 'Ед. изм.'
};

export const IndexMethodCalculationDialogFilter: React.FC<TProps> = ({
  onClose,
  open,
  changeFilter,
  activeFilters
}) => {
  if (!open) return null;
  const [search, setSearch] = useState('');

  const calcID = useCalcId();

  const { data, isFetching } = useGetFilterRimCalcQuery(
    { calcID },
    { skip: !calcID }
  );

  const formik = useFormik({
    initialValues: {
      [open!]: activeFilters[open!] || []
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      changeFilter(open!, values[open!]);
    }
  });

  const onChangeParams = React.useCallback(
    (target: string | string[]) => {
      if (typeof target === 'string') {
        // newState = newState?newState:
        formik.setFieldValue(
          open!,
          formik.values[open!].includes(target)
            ? formik.values[open!].filter((el) => el !== target)
            : formik.values[open!].concat(target)
        );
      } else if (Array.isArray(target)) {
        formik.setFieldValue(open!, target);
        // newState = target
      }
    },
    [formik.values]
  );
  const currentStateInitial = React.useMemo(() => {
    if (!data || isFetching || !open) return [];
    return data[open] || [];
  }, [open, data, isFetching]);

  const currentState = React.useMemo(() => {
    return currentStateInitial.filter((el) =>
      el.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    );
  }, [currentStateInitial, search]);

  React.useEffect(() => {
    setSearch('');
  }, [open]);

  return (
    <Dialog
      style={{ overflow: 'hidden' }}
      open={!!open}
      maxWidth={false}
      onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <Wrapper>
          <Title>
            <FilterAltIcon fontSize={'small'} />
            {open && title[open]}
          </Title>
          <BaseTextField
            disabled={isFetching}
            placeholder={'Поиск'}
            InputProps={{
              startAdornment: <SearchIcon style={{ marginRight: '10px' }} />
            }}
            sx={{
              '.MuiInputBase-root': {
                background: 'none !important'
              }
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Box
            display={'flex'}
            alignItems={'stretch'}
            justifyContent={'flex-start'}
            gap={'10px'}>
            <Button
              disabled={isFetching }
              variant={'text'}
              onClick={() => {
                onChangeParams(currentState);
                // setQs(stringify());
                // open && checkFilter(type, filterView[open], );
              }}>
              Выбрать все
            </Button>
            <Button
              disabled={isFetching || !formik.values[open!].length}
              variant={'text'}
              onClick={() => {
                // setQs('');
                onChangeParams([]);
                // open && checkFilter(type, filterView[open], null);
              }}>
              Сбросить {/*{open && qs && parse(qs)?.[open!]?.length}*/}
              {open &&
                !!formik.values[open!].length &&
                formik.values[open!].length}
            </Button>
          </Box>

          {isFetching ? (
            <Stack
              width={'100%'}
              height={'100%'}
              alignItems={'center'}
              justifyContent={'center'}>
              <CircularProgress />
            </Stack>
          ) : (
            <ListWrapper>
              {currentState.map((item, idx) => {
                return (
                  <ListItem
                    key={item + idx}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (open) {
                        onChangeParams(item);
                        // setQs(parse(qs)?.[open!]!.push(item));
                        // checkFilter(type, filterView[open], item);
                      }
                      //   variant &&
                      //   (!calcID
                      //     ? dispatch(
                      //       setFilter({
                      //         type: variant,
                      //         id: item.id ?? item.rowID ?? item.groupID,
                      //         name: 'name' in item ? item?.name || '' : item?.title || '',
                      //       }),
                      //     )
                      //     : dispatch(
                      //       setFilterCalc({
                      //         type: variant,
                      //         id: item.id ?? item.rowID ?? item.groupID,
                      //         name: 'name' in item ? item?.name || '' : item?.title || '',
                      //       }),
                      //     ));
                    }}>
                    <Checkbox
                      checked={
                        open
                          ? formik.values[open!].includes(item) || false
                          : false
                      }
                      // checked={
                      //   activeFilters?.[variant as keyof typeof activeFilters]?.findIndex((_) => _.id === item?.id||_.id === item?.groupID) >= 0
                      // }
                    />
                    <Text>
                      <span>{item}</span>
                    </Text>
                  </ListItem>
                );
              })}
            </ListWrapper>
          )}

          <Box display={'flex'} gap={'10px'}>
            <Button disabled={!formik.dirty || isFetching} type="submit">
              применить
            </Button>
            <Button
              color={'error'}
              sx={{ color: 'white' }}
              disabled={isFetching}
              onClick={onClose}>
              Закрыть
            </Button>
          </Box>
        </Wrapper>
      </form>
    </Dialog>
  );
};
